// 3rd party and framework
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { Button, Box, Flex } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'
import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { useForm, Controller } from 'react-hook-form';
import useDataSource from '../../hooks/useDataSource';

export default function WindfactorForm(parentprops) {
    const [windfactorValues, setWindfactorValues] = useDataSource(process.env.REACT_APP_ENDPOINTWINDFACTORS, null, true) // The object that holds the data in the forecast boxes
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [resultText, setResultText] = useState(null);

    useEffect(() => {

        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('mousedown', mouseClicked);

        return () => {
            document.removeEventListener('mousedown', mouseClicked);
        }

    }, []);

    const mouseClicked = (event) => {
        setResultText("");
    }    

    const onSubmit = (data) => {

        console.log('[WINDFACTORFORM] windfactorValues=', windfactorValues);
        
        // Add in the id foir the API
        const id = windfactorValues[0].id;
        const dataOut = { ...data, ...{ id: id } }
        console.log('[WINDFACTORFORM] dataOut=', dataOut);
                    

        upLoadData(dataOut, id);

        // Extract the values from the data as the filternames that will be sent to the API
        // add a flag to indicate that this call is being passed for the 

    }

    const upLoadData = async (uploadValues, id) => {
        try {
            // Post the tabledata to the API
            await axios
                .put(process.env.REACT_APP_ENDPOINTWINDFACTORS + id + '/', uploadValues)
                .then((response) => { setResultText(<Box color="green" fontWeight="bold">Saved settings</Box>) })
                .catch(error => { console.error(error); })
        } catch (error) {
            console.error(error);
            setResultText(<Box color="red" fontWeight="bold">Error saving settings</Box>);
        }
    }

    return (
    
        windfactorValues ?
                
            <form onSubmit={handleSubmit(onSubmit)} data-cy="windfactor_form">
                
                <Flex flexWrap="wrap" justifyContent="flex-start" mb={"1px"} p={"2px"}>
                
                    <Box maxW="250px" px="4px" my="5px">
                        <FormLabel htmlFor="CampaignMaxAcceleration" fontSize="sm" >Campaign Max Acceleration<br />(0 to 100):</FormLabel >
                        <FormControl isInvalid={errors.CampaignMaxAcceleration}>
                            <Controller
                                name="CampaignMaxAcceleration"
                                control={control}
                                defaultValue={windfactorValues && windfactorValues[0].CampaignMaxAcceleration}
                                rules={{
                                    required: 'Number is required',
                                }}
                                render={({ field }) => (
                                    <NumberInput size="sm" {...field} max={100} min={0} maxW="80px">
                                        <NumberInputField placeholder={windfactorValues && windfactorValues[0].CampaignMaxAcceleration} textAlign="right" data-cy="windfactors_campaign_max_acc" />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper data-cy="form_windfactors_stepper_campmaxacc_increment" />
                                            <NumberDecrementStepper data-cy="form_windfactors_stepper_campmaxacc_decrement" />
                                        </NumberInputStepper>
                                    </NumberInput>
                                )}
                            />
                            <FormErrorMessage fontWeight="bold">
                                {errors.CampaignMaxAcceleration && errors.CampaignMaxAcceleration.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box maxW="250px" px="4px" my="5px">
                        <FormLabel htmlFor="DiscountMaxAcceleration" fontSize="sm" > Discount Max Acceleration<br />(-100 to 100):</FormLabel >
                        <FormControl isInvalid={errors.DiscountMaxAcceleration}>
                            <Controller
                                name="DiscountMaxAcceleration"
                                control={control}
                                defaultValue={windfactorValues && windfactorValues[0].DiscountMaxAcceleration}
                                rules={{
                                    required: 'Number is required',
                                }}
                                render={({ field }) => (
                                    <NumberInput size="sm" {...field} min={-100} max={100} maxW="80px">
                                        <NumberInputField placeholder={windfactorValues && windfactorValues[0].DiscountMaxAcceleration} textAlign="right" data-cy="windfactors_discount_max_acc" />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper data-cy="form_windfactors_stepper_discmaxacc_increment" />
                                            <NumberDecrementStepper data-cy="form_windfactors_stepper_discmaxacc_decrement" />
                                        </NumberInputStepper>
                                    </NumberInput>
                                )}
                            />
                            <FormErrorMessage fontWeight="bold">
                                {errors.DiscountMaxAcceleration && errors.DiscountMaxAcceleration.message}
                            </FormErrorMessage>
                        </FormControl>
                    </Box>
                    <Box pt="50px" my="5px" display="flex" flexDirection="column" h="150px">

                        <Button data-cy="form_windfactors_button_save" colorScheme="blue" variant="outline" type="submit">
                            Save
                        </Button>
                        <Box px="4px" mt="5px" display="flex" flexDirection="column">
                            <Box flex="1">
                                {resultText}
                            </Box>
                        </Box>
                    </Box>

                </Flex>
            </form >
            
            : 'loading...'

    );
}

