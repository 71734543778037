import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import useDataSource from './useDataSource';

// This hook is essentially a wrapper around the useDataSource hook,
// and adds a header row plus an additional 'false' column. The additional
// column is used in a table to check/uncheck items.
const useStyleColorCollection = (filters, triggerUpdate = null) => {
    const [dataParent, setDataParent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataChild, setDataChild] = useDataSource(process.env.REACT_APP_ENDPOINTSTYLECOLORS, filters, true, triggerUpdate);

    // This ref is used to make sure the data being returned matches that of the stylecode passed in  
    // the filter value being passed in is identical to the one     
    //const previousStylecode = useRef(null);

    const fetchData = async () => {

        try {

            // The below assumes the data is structred into an array of dicts:
            /* [
                    {
                        "display_stylename": "Addiction 14",
                        "display_stylecode": "110317",
                        "display_introseason": "S2021",
                        "display_stylecolor": "110317028",
                        "colorname": "Blackened Pearl/Blue/Black",
                        "colorcode": "028"
                    },
                    ...
            */

            let tableVals = [];
            // Always return the first row, as this is needed to start populating 
            // styles with no colors defined yet
            tableVals[0] = ["Stylecode", "Stylename", "Colorcode", "Colorname"];

            if (dataChild) {
                // If the stylecode has been passed in, test that the stylecode retrieved matches the one passed in the filter
                if ((filters['stylecode'] === undefined) || (dataChild && dataChild[0] && (filters['stylecode'] === dataChild[0]['display_stylecode']))) {
                    //previousStylecode.current = filters['stylecode'];
                
                    dataChild.forEach((item) => {
                        // Last value  addds column with a checkbox, to select the item
                        tableVals.push([item.display_stylecode, item.display_stylename, item.colorcode, item.colorname, false]);
                    });
                }
            }
            // Populate the grid with data 
            setDataParent(tableVals);
            setLoading(false);            

        } catch (error) {
            console.error(error);
        }
    };
    
    // uwc-debug
    useEffect(() => {
        fetchData();
    }, [dataChild, triggerUpdate]);

    return [dataParent, setDataParent, loading, setLoading];
};

export default useStyleColorCollection;