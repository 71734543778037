import React from "react";
import Navbar from "../../components/nav/Navbar";
import TitleBar from "../../components/pageblocks/Titlebar";

export default function ToDo() {

    return (

        <main>
            <Navbar />
            <TitleBar title="ToDo" />

                <div className="row m-1">
                    <div className="col-md-12 col-sm-12 p-1">
                        <div className="card p-2">
                            <ul>
                                <li><s>WAIT for the postback on saving stylecolorshare data. Takes long!!!!</s></li>
                                <li>Season filter on forecast page</li>
                                <li>Have Forecast page pull values from MOAT_VIEW rather than relying on front-end calculation for unit forecast and bulkpositions. This will also create consistemcy between this page and MOAT table.</li>
                                <li><s>Put weekselector into Chakra, remove CSS</s></li>
                                <li>Query caching -- React-query</li>
                                <li><s>Testing -- Cypress</s></li>
                                <li><s>Markets page</s></li>
                                <li>Marketshares page</li>
                                <li><s>Option to delete style</s></li>
                                <li><s>Option to filter by season</s></li>
                                <li><s>Styles page: delete styles via checkbox</s></li>
                                <li>Error handling and input validation around style (color) updating</li>
                                <li>Settings page with settings e.g. 'years visible' to set start and endyear</li>                                
                                <li>Merge Stylecode and StyleColorShares pages, to
                                    <ul><li>(a) add/edit colors at the style level and</li>
                                        <li>(b) quick-add colors from the stylecolorsshares page</li>
                                        Retain the stylecolors page as a bulk-upload page, where data can be copy-pasted
                                    </ul>
                                </li>
                                <li>Finish Salesplan: colour shares, markets</li>
                                <li><s>Distinguish between Apparel and Footwear when adding a style</s></li>

                                <li>Add (popup?) page to add colours to styles</li>
                                <li><s>Don't allow inout of stylecolor shares for weeks outside the salesplan: take startweek and endweek either from</s>
                                    <ul>
                                        <li><s>(a) Style introweek and outroweek (salesparams)</s></li>
                                        <li><s>(b) Season startweek and endweek, if style has not (yet) been setup with params</s></li>
                                    </ul>
                                </li>
                                <li><s>Put the ConfirmDeleteItemsModal into a custom hook</s></li>
                                <li><s>Off by one error in startweek when saving baseplan</s></li>
                                <li><s>Clientside validators on table entry fields</s></li>                                
                                <li><s>Build additional weekfilter page that allows fow selection between a startdate and an enddate</s></li>
                                <li><s>Refactor salesplanner page</s>, put sections (e.g. sales params) into components </li>
                                <li><s>Saving baseplan not always working</s></li>
                                <li><s>Field validation on Sales Params fields.</s></li>

                                <li><s>Build Global Settings page to hold values such as default_discount_max_acceleration, default_campaign_max_acceleration, default initial season for views (current season?),   </s></li>


                                <li><s>Add Style setup page</s></li>
                                <li><s>Pull in sales (actual) data, calculate forecast from actuals vs plan</s></li>
                                <li><s>Pull in inventory levels. Calculate excess positions from current depletion levels.</s></li>
                                <li><s>Get chart on salesplan page to update when data is refreshed</s></li>
                                <li><s>Pages to add style-families, styles and style instances.</s></li>

                                <li><s>Click into/out of style selector on stylecolor page, to expand/collapse the dropdown</s></li>
                                <li><s>Manually navigate (key-up, key-down) the style selectior on the stylecolor page</s></li>

                                <li><s>Delete empty CP/DP/DM/CM fields for Salesplan and multiplier fields for basemodel if set empty in UI</s> </li>
                            </ul>
                        </div>
                    </div>
                </div>


        </main>

    );
}
