import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';

const Protectedroute = ({ children, next, ...rest }) => {

    const isAuthenticated = useAuth();

    if (isAuthenticated === null || isAuthenticated === undefined) {
        // Authentication check is in progress, you can render a loading indicator or placeholder
        return; //<div>Authenticating...</div>;
    }

    if (!isAuthenticated) {
        // User is not authenticated, redirect to the login page
        //window.location.href = "/login?next=" + next;
        return <Navigate to={"/login?&next=" + next} />;
    }

    // User is authenticated, render the protected content
    return children;
};

export default Protectedroute;
