import { useEffect, useRef } from "react";
import { Box } from '@chakra-ui/react';
import lottie from 'lottie-web';

const LottieAnimation = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: containerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/images/woman-running-on-treadmill.json', // Replace with the actual path to your Lottie JSON file
        });

        return () => {
            animation.destroy();
        };
    }, []);

    return <Box ref={containerRef} w={props.width} h={props.height} />;
};
export default LottieAnimation;