'use client';
// 3rd party and framework
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSWRConfig } from 'swr';
import { useParams, useLocation } from "react-router-dom";
import { Box, Image, Button, Flex, Spacer } from '@chakra-ui/react';

// Custom components
import Navbar from '../../components/nav/Navbar';
import TitleBar from '../../components/pageblocks/Titlebar';
import FilterBar from '../../components/filters/FilterBar';
import MoatTable from "../../components/datatables/MoatTable";
import LottieAnimation from '../../components/controls/LottieAnimation';
import Counter from "../../components/controls/Counter";

// Custom hooks
import useAuthSWR from '../../hooks/useAuthSWR';

// Helpers
import { loadSearchParamsCRA } from '../../utils/domainSpecific';
import { getCurrentSeason } from '../../utils/utils';
import { cache } from 'swr/_internal';

export default function MoatPage(params) {
    // Extract search parameters from URL structure and querystring
    const paramVals = loadSearchParamsCRA(useParams(), new URLSearchParams(useLocation().search));
    const [dataLoading, setDataLoading] = useState(true);
    const [timeoutHit, setTimeoutHit] = useState(false);
    const [cacheFilled, setCacheFilled] = useState(false);
    const counterValueRef = useRef(0);
    const [counterValue, setCounterValue] = useState('');
    const [filters, setFilters] = useState({ stylecode: paramVals.stylecode, lookbackavgweeks: '4', businessline: 'F', season: 'S2023' }); // season: getCurrentSeason()
    const url = process.env.REACT_APP_TOTALSMOAT + '?checkcachefirst=true&' + new URLSearchParams(filters);
    const { data, error } = useAuthSWR(url);


    // mutate is called on SWR with a new url when the filters change
    // The mutate function from the useSWRConfig hook in the Parent component is a global SWR function. 
    // It can trigger a refetch of the data regardless of the component in which it is called.
    // When the data is refetched, all components that use the useSWR hook with the corresponding URL will receive the updated data.
    const { mutate } = useSWRConfig();

    useEffect(() => {
        const interval = setInterval(() => {
            setCounterValue(counterValueRef.current);
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Extract the authentication token from the session and add it as a header, that is included with data retrieval calls
    const auth_token = sessionStorage.getItem('token');

    const filterCallback = (newFilterValues) => {
        setCacheFilled(false);
        setTimeoutHit(false);
        setCounterValue(0);
        const styleTriggeredCallback = newFilterValues.hasOwnProperty('stylecode');

        // Merge the incoming filters into the previous filters
        // values, either adding or overwriting.
        // In the process this creates a new dict, which triggers the
        // useEffect call in the hook that has these filters 
        // in the dependency array

        let tempFilters = { ...filters, ...newFilterValues };

        // If a style triggered the callback, remove the season and businessline filters
        if (styleTriggeredCallback)
            tempFilters = { ...tempFilters, ...{ season: undefined, businessline: undefined } };

        // Additional manipulation needed for stylecodes / colorcodes as these are interdependent
        if (tempFilters.stylecode === null || tempFilters['stylecode'] === undefined) {
            window.history.pushState(null, '', '/moat/');
        }
        else if (tempFilters['stylecode']) {
            let url = '/moat/' + tempFilters['stylecode'];
            window.history.pushState(null, '', url);
        }
        // The two are now merged and 'tempFilters' contains 
        // the up to date filter values; update the filters state
        // (this triggers useEffects to retrieve data)
        setFilters(tempFilters);

        // Call the SWR mutate function to refresh the data in the table 
        mutate(process.env.REACT_APP_TOTALSMOAT + '?' + new URLSearchParams(tempFilters));

    }

    const clearFiltersCallback = () => {
        setCacheFilled(false);
        setTimeoutHit(false);
        //console.log('[FILTERS] clearFiltersCallback triggered');
        window.history.pushState(null, '', '/moat/');
        const tempFilters = { lookbackavgweeks: '4', season: getCurrentSeason(), businessline: 'F' };
        setFilters(tempFilters);

        // Call the SWR mutate function to refresh the data in the table 
        mutate(process.env.REACT_APP_TOTALSMOAT + '?' + new URLSearchParams(tempFilters));

    }

    const checkJobStatus = async (jobUrl, jobId, timeout) => {

        const url = jobUrl + jobId;
        console.log(url);
        const response = await axios.get(url);
        console.log(response.data);
        console.log(response.data.progress);
        //console.log('timeout=', timeout);

        if (response.data.hasOwnProperty('status')) {
            const status = response.data.status;
            console.log('got status:', status);

            if (status === 'processing' || status === 'queued') {
                // Recurse if the polling timeout has not been reached 
                if (timeout > 0) {
                    setTimeout(() => {
                        checkJobStatus(jobUrl, jobId, timeout - 500);
                    }, 500);

                } else {
                    setTimeoutHit(true);
                    console.log('Max polling timeout reached');
                }
            }
            else {
                if (status === 'finished') {
                    // When data processing is done, set the flag 
                    // to trigger the Moattable component to be rendered
                    setCacheFilled(true);
                }
                if (status === 'failed') {
                    console.log('timeout hit');
                    setTimeoutHit(true);
                }
            }
        } else {
            console.log('[STATUS] Could not retrieve status for job ' + jobId);
        }
    };

    const handleData = () => {
        if (data && !error) {
            console.log('data:', data);

            // If this is moat data being returned (from the cache)
            // trigger table rendering, by setting cacheFilled to 'true'
            if (data.length > 0 && data[0].hasOwnProperty('style_code')) {
                setCacheFilled(true);
            }
            else {

                if (data.hasOwnProperty('job_id')) {
                    const jobUrl = process.env.REACT_APP_URL_JOB_GET_STATUS;
                    const jobId = data.job_id;
                    // Max polling time is 60s --? Store in env vars
                    const timeout = process.env.REACT_APP_MAX_POLLTIME_MOATDATA;
                    checkJobStatus(jobUrl, jobId, timeout);
                } else {
                    console.error('Data missing prop "job_id"');
                }
            }
        }
    };

    if (!cacheFilled) {
        handleData();
    }

    return (
        <>
            <Navbar />
            <TitleBar title="MOAT" />
            <Flex direction={{ base: "column", md: "row" }}>
                <Box flexGrow={1} mt="5px" mb="15px">
                    <FilterBar
                        filterChoices={{
                            showStyleFilter: true,
                            showColorFilter: false,
                            showLookbackweeksFilter: true,
                            showYearStartFilter: false,
                            showYearEndFilter: false,
                            showMarketFilter: false,
                            showSeasonFilter: true,
                            showBusinesslineFilter: true,
                        }}
                        stylecode={filters && filters.stylecode}
                        stylecodeFilterCallback={filterCallback}
                        season={filters.season}
                        seasonFilterCallback={filterCallback}
                        lookbackavgweeks={filters.lookbackavgweeks}
                        lookbackweeksFilterCallback={filterCallback}
                        lookbackweeksFieldTitle={"Lookback weeks: "}
                        businesslineFilterCallback={filterCallback}
                        businessline={filters.businessline}
                        clearFilters={clearFiltersCallback}
                    />
                </Box>

                <Spacer />
                <Box mt="5px" mb="15px" mx="15px">
                    <Button
                        data-cy="moat_button_download_csv"
                        isDisabled={cacheFilled === false}
                        as="a"
                        href={cacheFilled ?
                            process.env.REACT_APP_DOWNLOAD_CSV_LINK
                            + '?stylecode=' + filters.stylecode
                            + '&lookbackavgweeks='
                            + filters.lookbackavgweeks
                            + '&season=' + filters.season
                            + '&businessline=' + filters.businessline
                            + '&t=' + sessionStorage.getItem('token')
                            + '&u=' + sessionStorage.getItem('username')
                            : "#"}

                        textDecoration="none"
                        colorScheme="blue"
                        variant="outline">
                        Download as CSV
                    </Button>
                </Box>
            </Flex>


            {cacheFilled ?
                <>
                    <Box><MoatTable filters={filters} onLoadingChange={setDataLoading} /></Box>
                    <Box m="10px" fontFamily="mono" fontWeight="bold">Data retrieved. Load completed in {counterValueRef.current}s</Box>
                </>
                :

                timeoutHit ?
                    <Box w={"100%"} ml="20px" mt="50px" alignContent="center">
                        <Box fontWeight="bold" color="#D02255">Treadmill time out!</Box>
                        <Box mx="20px"><Image src="/images/Treadmilltimeout.gif" alt="Treadmill Timeout" boxsize="200px" m={10} /></Box>
                        <Box>
                            That was a bit more treadmill time than expected. Could not process results within 120s (or the processing failed). Please try again with a smaller dataset.
                        </Box>
                    </Box>
                    :
                    <Box w={"100%"} ml="20px" alignContent="center">
                        <LottieAnimation height="300px" width="500px" />
                        <Box mt="50px"><Box fontWeight="bold" color="#D02255">Treadmill time!</Box>An all-style (re)load takes ~60s(!). The longer the lookback window, the longer the load time. </Box>
                        <Counter label="Time on the treadmill:" ref={counterValueRef} />
                    </Box>
            }
        </>
    );
};


