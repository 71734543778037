// 3rd party and framework
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from 'axios';
import { Spinner, Box, Flex, Spacer, Button, Link } from '@chakra-ui/react'

// Custom components
import TitleBar from "../../components/pageblocks/Titlebar";
import FilterBar from "../../components/filters/FilterBar";
import Footerbar from "../../components/pageblocks/Footerbar";
import BasemodelTable from "../../components/datatables/BasemodelTable";
import WindfactorForm from "../../components/forms/WindfactorForm";
import Navbar from "../../components/nav/Navbar";

// Helpers
import { loadSearchParamsCRA } from "../../utils/domainSpecific";
import { weeksInYear } from "../../utils/utils";

// Custom hooks
import useBasemodelCollection from "../../hooks/useBasemodelCollection";
import useLocalStorage from "../../hooks/useLocalStorage";

export default function Basemodel() {
    // Extract search parameters from URL structure and querystring
    const paramVals = loadSearchParamsCRA(useParams(), new URLSearchParams(useLocation().search));
    
    // The filters are stored in a custom hook, useLocalStorage, that persists the values into local storage, 
    // to maintain the values across pages 
    const [weekfilterStart, setWeekfilterStart] = useLocalStorage('weekfilterStart', (new Date().getFullYear()) + '01');
    const [weekfilterEnd, setWeekfilterEnd] = useLocalStorage('weekfilterEnd', (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    // Filters: dict object in which the values from filterboxes are set
    // Changing these triggers state changes that make the custom hooks re-render
    const [filters, setFilters] = useState({ weekfilter_start: weekfilterStart, weekfilter_end: weekfilterEnd });
    
    // Handsontable plumbing
    const [gridData, setGridData, dataLoading, setDataLoading] = useBasemodelCollection(filters, triggerUpdate); // The grid holds the data displayed in the table
    const hotRefData = useRef(null);

    // This callback function expects a set of 1 or more key-value pairs
    // structured in a Dict. It will iterate over those new values
    // and add them into / copy them over the existing filter values,
    // then update the filters state to a fresh (cloned) value of the 
    // to trigger an update of the hooks with the filters in their dependancy arrray
    const filterCallback = (newFilterValues) => {

        try {
            setDataLoading(true);
            //console.log('[FILTERS] callback with new values:', newFilterValues);
            // Merge the incoming filters into the previous filters 
            // values, either adding or overwriting.
            // In the process this creates a new dict, which triggers the
            // useEffect call in the hook that has these filters 
            // in the dependency array
            let tempFilters = { ...filters, ...newFilterValues };

            // The two are now merged and 'tempFilters' contains 
            // the up to date filter values; update the filters state
            // (this triggers useEffects ton retrieve data
            setFilters(tempFilters);            
            
            // Additional manipulation needed for weekfilters to persist to local storage
            if (newFilterValues.hasOwnProperty('weekfilter_start')) {
                setWeekfilterStart(parseInt(newFilterValues['weekfilter_start']));
            }
            if (newFilterValues.hasOwnProperty('weekfilter_end')) {
                setWeekfilterEnd(parseInt(newFilterValues['weekfilter_end']));
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const clearFiltersCallback = () => {
        window.history.pushState(null, '', '/basemodel/');
        setFilters({ weekfilter_start: new Date().getFullYear() + '01', weekfilter_end: (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()) });
        // These are resest and with that also persisted to localstorage
        setWeekfilterStart(new Date().getFullYear() + '01');
        setWeekfilterEnd((new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString())); 
    }    

    return (
        <>
            <Navbar />
            <TitleBar title="Basemodel & Windfactors" />
            <FilterBar
                filterChoices={{
                    showStyleFilter: false,
                    showColorFilter: false,
                    showYearStartFilter: true,
                    showYearEndFilter: true,
                    showMarketFilter: false
                }}
                seedStartYear={2023}
                weekNumStart={filters["weekfilter_start"]}
                startWeekFilterCallback={filterCallback}
                seedEndYear={2025}
                weekNumEnd={filters["weekfilter_end"]}
                endWeekFilterCallback={filterCallback}
                clearFilters={clearFiltersCallback}
            />
            <Box p={"5px"} border="1px solid lightgrey" borderRadius={"md"} m="15px">
                <Box color="blue.500" fontWeight={"bold"} fontSize="lg" py="10px" >Basemodel</Box>
                <Box color="#D02255" py="10px" >Note: the basemodel multipliers are calculated from the Shares of a full year of entries. Make sure that 'Share' values are supplied for all weeks of a year: all weeks from YYYY01 to YYYY52 / YYYY53. These shares will be used to generate the multipliers.</Box>
            
                {dataLoading ?
                    <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                    :
                    <BasemodelTable data={gridData} ref={hotRefData} triggerUpdate={triggerUpdate} setTriggerUpdate={setTriggerUpdate} />
                }
            </Box>

            <Box p={"5px"} border="1px solid lightgrey" borderRadius={"md"} m="15px">
                <Box color="blue.500" fontWeight={"bold"} fontSize="lg" py="10px" >Windfactors</Box>
                <Box py="10px" maxW="700px" >The windfactors allow extra 'tailwind' or 'headwind' to be applied to the salesplan. They are applied during building of the plan, when the plan for a style is created on the <Link href="/salesplan/">salesplan page</Link>. </Box>
                <WindfactorForm />
            </Box>

        </>
    )
}




