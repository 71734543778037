import { useEffect, useState } from 'react';
import axios from 'axios';

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            // Check for token in local storage
            const token = sessionStorage.getItem('token');
            const username = sessionStorage.getItem('username');

            if (token && username) {
                try {
                    // Verify the token and username
                    const response = await axios.post(process.env.REACT_APP_URL_AUTH_VERIFY_TOKEN, {
                        token: token,
                        username: username
                    });

                    const { AUTH } = response.data;
                    setIsAuthenticated(AUTH);
                } catch (error) {
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    return isAuthenticated;
};

export default useAuth;
