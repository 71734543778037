import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import { Global } from '@emotion/react';
import theme from "./chakra-theme.tsx";
import filsonProRegular from './fonts/FilsonPro-Regular.woff2';
import filsonProBold from './fonts/FilsonPro-Bold.woff2';
import filsonProLight from './fonts/FilsonPro-Light.woff2';
import filsonProThin from './fonts/FilsonPro-Thin.woff2';
import filsonProMedium from './fonts/FilsonPro-Medium.woff2';
import filsonProHeavy from './fonts/FilsonPro-Heavy.woff2';
import filsonProBlack from './fonts/FilsonPro-Black.woff2';
import filsonProBook from './fonts/FilsonPro-Book.woff2';
//import { unstable_trace as trace } from 'react-dom';

// Set the base URL for all axios calls 
axios.defaults.baseURL = process.env.REACT_APP_APIBASEURL;

const setFavicon = () => {
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
  }
  const middlePart = "";  //"_" + process.env.REACT_APP_ENVNAME;
  link.href = ('/favicon' + middlePart + '.ico').toLowerCase() + '?a=' + new Date();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Global
        styles={`
        @font-face {
          font-family: 'Filson Pro';
          src: url('${filsonProRegular}') format('woff2'),
               url('${filsonProBold}') format('woff2');
               url('${filsonProMedium}') format('woff2');
               url('${filsonProThin}') format('woff2');
               url('${filsonProLight}') format('woff2');
               url('${filsonProHeavy}') format('woff2');
               url('${filsonProBlack}') format('woff2');               
               url('${filsonProBook}') format('woff2');  
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
      `}
      />      
        <App />
      </ChakraProvider>
  </React.StrictMode>
);

// Set favicon depending on the environment
setFavicon();
