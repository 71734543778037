// 3rd party and framework
import React, { useState, useRef, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
//import { useParams, useLocation } from "react-router-dom";
import { Box, Spinner, Button, Flex, Spacer } from '@chakra-ui/react';

// Helpers
import { getYearAndWeek } from '../../utils/utils';

// Custom components
import useAuthSWR from '../../hooks/useAuthSWR';


// createTheme creates a new theme named solarized that overrides the built in dark theme
//ToDo: Put this code in an include
createTheme('solarized', {
    text: {
        font: 'Filson Pro',
        fontSize: '15px',
        primary: '#FDB813',
        // Controls the pagination text colour
        secondary: '#f0e3c4',
    },
    background: {
        default: '#034481',
    },
    context: {
        background: '#cb4b16',
        text: 'hotpink',
    },
    divider: {
        default: '#5E88BA',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

createTheme('solarized_exp', {
    text: {
        font: 'Filson Pro',
        primary: '#c0c6cc',
        secondary: '#2aa198',
    },
    background: {
        default: '#005EB8',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#5E88BA',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

createTheme('solarized_exp_2', {
    text: {
        font: 'Filson Pro',
        primary: '#c0c6cc',
        secondary: '#2aa198',
    },
    background: {
        default: '#117adb',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#5E88BA',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

export default function MoatTable(props) {
    const thisWeek = getYearAndWeek(new Date());

    // Auth token and fetcher have been retrieved and set globally
    const url = process.env.REACT_APP_TOTALSMOAT + (props.filters && '?' + new URLSearchParams(props.filters));
    const { data: moatData, error } = useAuthSWR(url);

    //useEffect(() => {
    //    // Invoke the callback with the isLoading status
    //    props.onLoadingChange(!moatData && !error);
    //}, [moatData, error]);

    const ExpandedComponentSales = ({ data }) => {
        const salesData = data.sales;
        return (
            <>
                {salesData && salesData.length > 0 ? (
                    <DataTable
                        title={salesData.week}
                        columns={[
                            { name: 'Week', selector: (row) => row.week },
                            { name: 'Units Sold', selector: (row) => isNaN(row.units_sold_stylecolor) ? 0 : Math.round(row.units_sold_stylecolor) },
                            { name: 'Units Planned', selector: (row) => isNaN(row.units_planned_stylecolor) ? 0 : Math.round(row.units_planned_stylecolor) },
                            { name: 'Units Forecast', selector: (row) => isNaN(row.units_forecast_stylecolor) ? 0 : Math.round(row.units_forecast_stylecolor) },
                            { name: 'Planned color share', selector: (row) => row.color_share_planned + '%' },
                            { name: 'Actual color share', selector: (row) => row.color_share_actual + '%' },
                            { name: 'Units on bulk', selector: (row) => isNaN(row.units_bulk) ? 0 : Math.round(row.units_bulk) },
                            { name: 'Bulk drops', selector: (row) => isNaN(row.bulk_drops) ? 0 : Math.round(row.bulk_drops) },

                        ]}
                        conditionalRowStyles={[
                            {
                                when: (row) => row.week === thisWeek,
                                style: {
                                    backgroundColor: '#f0f0f0',
                                    color: '#EE3B6F',
                                    fontWeight: 'bold',
                                },
                            },
                        ]}
                        data={salesData}
                        highlightOnHover
                        dense
                        fixedHeader
                        fixedHeaderScrollHeight="500px"
                        theme="solarized_exp_2"
                        onSort={rowSort}
                    />
                ) : (
                        <Box mt={"5px"} ml={75} fontStyle={"italic"} fontSize={"13px"} width="100%">No salesplan created.</Box>
                )}
            </>
        );
    };

    const ExpandedComponentStyleColors = ({ data }) => {
        const stylecolorData = data.stylecolor;

        const redOrGreenRenderer = (row) => {
            const value = row.salesplanratio;
            const colorStyle = parseFloat(value) >= 1 ? 'green.400' : 'red';
            return <Box fontWeight="Medium" color={colorStyle}>{value.toFixed(2)}%</Box>;
        };

        return (<>
            {stylecolorData && stylecolorData.length > 0 ? (
                <Box ml="15px">
                    <DataTable
                        title={stylecolorData.stylecolorname}
                        columns={[
                            { name: 'Color name', selector: (row) => row.stylecolorname },
                            { name: 'Color code', selector: (row) => row.stylecolorcode },
                            {
                                name: `Sales to plan ratio (${data.lookbackweeks} week lookback)`,
                                selector: (row) => row.salesplanratio,
                                cell: redOrGreenRenderer,
                            },

                        ]}
                        data={stylecolorData}
                        expandableRows
                        expandOnRowClicked
                        expandableRowsComponent={ExpandedComponentSales}
                        dense
                        highlightOnHover
                        theme="solarized_exp"
                        onSort={rowSort}
                    />
                </Box>
            ) : (
                <Box color="blue.500" mt={"5px"} ml={15} fontSize={"14px"} width="100%">No colors defined.</Box>
            )}
        </>);
    };

    // This is needed to fix a bug in the datatable, that 'detaches' expanded child rows from their parent on sort
    const rowSort = (e) => {

        // Such a dirty hack this! Check what the trigger of the sort was, as the sort is also fired on a row-expand / collapse!
        // In the case that it WAS a column header that triggered the wvent, the event contains the data object that triggered the click. e.g. 
        // { "name": "Style code", "sortable": true, "id": 2)
        try {
            if (e.hasOwnProperty['name'] && (e.name === 'Style name' || e.name === 'Style code' || e.name === 'Introseason')) {

                // Retrieve the ids of the rows that have been expanded
                // This is very hacky, relying on the React-DataTable js usage of the data-testid attribute and 
                // the aria-label atttribute to identify the rows to be collapsed. It may break if the implementation
                // of React DataTable changes
                var buttons = document.querySelectorAll("[data-testid^='expander-button-'][aria-label='Collapse Row']");

                // Note buttons[i].onclick(); Does NOT WORK. Call the method, not the event handler.
                // This method is called to effectively collapse all expanded rows, relying on the HIDDEN expand button
                for (let i = 0; i < buttons.length; i++) buttons[i].click();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const linkRendererSalesplan = (row) => {
        const value = row.style_code;
        return <a href={"/salesplan/" + value}>edit salesplan</a>;
    };

    const linkRendererStylecolorShares = (row) => {
        const value = row.style;
        return <a href={"/stylecolorshares/" + value}>edit stylecolor shares</a>;
    };

    const columns = [
        { name: 'Style code', selector: (row) => row.style_code, sortable: true },
        { name: 'Style name', selector: (row) => row.style_name + ' (' + row.style_gender + ')', sortable: true },
        { name: 'Businessline', selector: (row) => row.style_businessline, sortable: true },
        { name: 'Introseason', selector: (row) => row.style_introseason, sortable: true },
        {
            name: '',
            selector: (row) => row.style_code,
            cell: linkRendererSalesplan
        },
        {
            name: '',
            selector: (row) => row.style_code,
            cell: linkRendererStylecolorShares
        }
    ];


    if (error) {
        if (error.message.substring(0, 5) === '[401]')
            return <Navigate to={"/login?hitunauthorizedurl=true&next=" + window.location.pathname} />;
        return (<Box ml="20px">Failed to load: {error.message}</Box>);
    }
    if (moatData) {
        //console.log('data=', moatData);
        return (
            <>
                <Box data-cy="moattable_table_container" mx="10px">
                    <DataTable
                        expandableRowKey={(row) => row.style_code}
                        onSort={rowSort}
                        columns={columns}
                        expandableRows
                        expandOnRowClicked
                        expandableRowsComponent={ExpandedComponentStyleColors}
                        data={moatData}
                        dense
                        pagination
                        theme="solarized"
                        highlightOnHover
                        fixedHeader
                        fixedHeaderScrollHeight="1200px"
                        paginationPerPage="40"
                        paginationRowsPerPageOptions={[20, 40, 60, 80, 100, 200, 1000]}
                    />
                </Box >
            </>);
    }
}