import React from "react";
import Navbar from "../../components/nav/Navbar.jsx";
import TitleBar from "../../components/pageblocks/Titlebar.jsx";
import { Box, Image } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react'

export default function Howto() {

    return (
        <>
            <Navbar />
        <TitleBar title="How to setup and maintain data" />

            <Box w="100%" my={3} px={4} pt={1} h="40px" bg="red.200" fontSize={"20px"} fontWeight={"bold"}>1. Setup</Box>    
        <Accordion>
                <AccordionItem>
                    <Box bg="#cbe2f1" mx="2px" px="6px" borderRadius="md">
                <h2>
                    <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight="bold" color="darkslategray" >
                    Style families
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2></Box>
                <AccordionPanel pb={4}>
                <Box>Style families are entered/updated on the <a href="/stylefamilies">stylefamilies</a> page. The list of families shown is the entire list of families that exist in the system. If you want to enter a new family, add it to the list either by:
                    <ul>
                        <li>(a) Hitting CTRL+I or CTRL+B to enter a new row and <strong>manually entering the family name</strong> in the new row. Then hit SAVE.</li>
                        <li>(b) Hitting CTRL+B on the bottom row, to insert a new empty row. Then in that empty row, <strong>paste a list of new names</strong> into the table. Then hit SAVE.</li>
                    </ul>
                </Box>
                <Box>Deleting and renaming families:
                    <ul>
                        <li>Deleting existing families is <strong>only possible for families that have no styles attached</strong>. The system will not allow deletion of families that are linked to a style.</li>
                        <li>Delete a family by deleting its name from the list. You will still see an ID value in that spot, this will disappear when you hit SAVE.</li>
                        <li>Renaming families is possible: enter the new name for the family and hit SAVE. However, <strong>use caution</strong> if this family is already associated with one or more styles. Make sure there is a logical connection between the style name and the family name.</li>
                    </ul>
                </Box>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                    <Box bg="#cbe2f1" mx="2px" px="6px" borderRadius="md">
                <h2>
                    <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight="bold" color="darkslategray" >
                    Styles
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2></Box>
                <AccordionPanel pb={4}>
                <Box>Styles are entered/updated on the <a href="/styles">styles</a> page. When entering or updating a style, you must provide:
                    <ul>
                        <li>The <strong>Stylecode</strong>: the 6-digit unique code by which the style is identified</li>
                        <li>The <strong>Name</strong>: the name by which the style is identified. It can be up to 100 characters.</li>
                        <li>The <strong>Gender</strong>: a single-letter indicator of the gender: 'M' (men),'W' (women),'U' (unisex)</li>
                        <li>The <strong>Introseason</strong>: the season in which the still is introduced. A 5-digit code consisting of the 'S' (spring) or 'F' (fall) indicator and the 4-digit calendar year. E.g. 'F2023' or 'S2024'</li>
                    </ul>
                    The system performes checks to <strong>prevent duplicate entries</strong> and <strong>maintain unique values</strong>:
                    <ul>
                        <li>The stylecode must be unique</li>
                        <li>The combination of stylename and gender must be unique</li>
                    </ul>
                    If you want to enter a new family, add it to the list either by:
                    <ul>
                        <li>(a) Hitting CTRL+I or CTRL+B to enter a new row and <strong>manually entering the style data</strong> in the new row. Then hit SAVE.</li>
                        <li>(b) Hitting CTRL+B on the bottom row, to insert a new empty row. Then in that empty row, <strong>paste a list with new data</strong> into the table. Note that this data will need to be structured identically to the table. Then hit SAVE.</li>
                    </ul>
                    in the  the name-gender combination and the code-name  of entry and <strong>will not allow you to enter duplicate name</strong>
                    <ul>
                        <li>(a) Hitting CTRL+I or CTRL+B to enter a new row and <strong>manually entering the family name</strong> in the new row. Then hit SAVE.</li>
                        <li>(b) Hitting CTRL+B on the bottom row, to insert a new empty row. Then in that empty row, <strong>paste a list of new names</strong> into the table. Then hit SAVE.</li>
                    </ul>
                </Box>
                </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem>
                <Box bg="#cbe2f1" mx="2px" px="6px" borderRadius="md">
                <h2>
                    <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight="bold" color="darkslategray" >
                    Style colors
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2></Box>
                <AccordionPanel pb={4}>
                <Box>Style-Colors are entered/updated on the <a href="/stylecolors">stylecolors</a> page. By default the page shows all style-colors in the system. The input box at the top of the page allows you to filter by style, showing only colors for a specific style. When entering or updating a style-color, you must provide:
                    <ul>
                        <li>The <strong>Stylecode</strong>: the 6-digit unique code by which the style is identified. Note that you can <strong>only enter the code of an existing style.</strong> If you want to enter colors for new styles, you should first set up these styles on the <a href="/styles">styles</a> page.</li>
                        <li>The <strong>Stylename</strong>: the name of the style can be entered, however it is not mandatory, as the name is connected to the stylecode. This field is purely shown next to the stylecode as a helper.</li>
                        <li>The <strong>colorcode</strong>: a three-digit code that represents the color. Notes:
                            <ul>
                                <li>The colorcode must be unique across colors, but can be shared between styles. No two colors can share a colorcode, but for example styles '123456' and '234567' could share the same colorcode '020'. This is set up by entering that color code for both those styles in the table.</li>
                                <li>A colorcode is <strong>always attached to a single style</strong>. The system does not contain 'loose colors'. This means when setting up a new color, a stylecode must always be entered.</li>
                                <li>A colorname is specific to a style-colorcode combination. It does not have to be unique, but it is strongly recommended it is, to avoid confusion. E.g. while technically possible to setup style A with colorcode '020' and colorname 'cherry blossom' and style B with colorcode '020' and colorname 'green apples', re-using the colorcode '020' with different names can create confusion.
                                </li>
                                <li>The  colorname can be changed, by entering the new name in the table. </li>
                            </ul>
                        </li>
                    </ul>
                    If you want to enter a new style-color, add it to the list either by:
                    <ul>
                        <li>(a) Hitting CTRL+I or CTRL+B to enter a new row and <strong>manually entering the style data</strong> in the new row. Then hit the SAVE button at the bottom of the page.</li>
                        <li>(b) Hitting CTRL+B on the bottom row, to insert a new empty row. Then in that empty row, <strong>paste a list with new data</strong> into the table. Note that this data will need to be structured identically to the table. Then hit the SAVE button at the bottom of the page.</li>
                    </ul>
                    Deleting a style-color:
                    <ul>
                        <li>If you want to <strong>delete an existing style-color</strong>, check the box in the last column and hit the 'Delete checked' button at the bottom of the page. Also, the checkbox in the top row allows you to select all colors in te table.</li>
                        <li><strong>Take note: gone is gone!</strong>. There is no 'undo' button, once a style-color has been deleted from the system, you will have to set it up new.</li>
                        <li>If a style-color is attached to more than one style (see above) <strong>when a style-color is deleted, it will be deleted from all the styles it is associated with.</strong></li>
                        <li>If a style-color is deleted, <strong>any style-color-share entries (see below) for that color, will also be deleted.</strong></li>
                    </ul>

                </Box>                          
                </AccordionPanel>        
            </AccordionItem>    
                
            <AccordionItem>
                <Box bg="#cbe2f1" mx="2px" px="6px" borderRadius="md">
                <h2>
                    <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight="bold" color="darkslategray" >
                    Markets
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2></Box>
                <AccordionPanel pb={4}>
                     <Box>Markets are entered/updated on the <a href="/markets">markets</a> page. A market has a shortname and a longname. Both must be unique.
                    </Box>                          
                </AccordionPanel>        
            </AccordionItem> 
                
            </Accordion>              
            
            <Box w="100%" my={3} px={4} pt={1} h="40px" bg="red.200" fontSize={"20px"} fontWeight={"bold"}>2. Planning</Box>
            <Box w="100%" my={3} px={4} pt={1} h="40px" bg="red.200" fontSize={"20px"} fontWeight={"bold"}>3. Monitoring</Box>
            <Box w="100%" my={3} px={4} pt={1} h="40px" bg="red.200" fontSize={"20px"} fontWeight={"bold"}>4. Other</Box>    
            <Accordion>
                <AccordionItem>
                <Box bg="#cbe2f1" mx="2px" px="6px" borderRadius="md">
                <h2>
                    <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight="bold" color="darkslategray" >
                    The datamodel
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2></Box>
                <AccordionPanel pb={4}>
                <Box>The data is stored in a Postgres DB and accessed via API calls.</Box>
                <Image src="/images/datamodel.png" alt="Datamodel" />                      
                </AccordionPanel>        
            </AccordionItem>                 

            </Accordion>
    </>

    );
}
