// Framework and 3rd party 
import React, { useEffect, useState, useRef } from "react";
import { Spinner, Box, Button } from '@chakra-ui/react'
import { Link as ChakraLink } from "@chakra-ui/react";
import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";

import { Wrap, WrapItem, Center } from '@chakra-ui/react'
import { useParams, useLocation } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import axios from 'axios';

// Custom components
import FilterBar from "../../components/filters/FilterBar.jsx";
import Navbar from "../../components/nav/Navbar.jsx";
import TitleBar from "../../components/pageblocks/Titlebar.jsx";
import ConfirmDeleteItemsModal from "../../components/controls/ConfirmDeleteItemsModal.jsx";
import StylecolorUnitsTable from "../../components/datatables/StylecolorUnitsTable.tsx";

// Helpers
import {loadSearchParamsCRA} from "../../utils/domainSpecific.js";
import { weeksInYear } from "../../utils/utils.js";

// Handsontable imports and initialization
import 'handsontable/dist/handsontable.full.min.css';
//import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';

// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');
// For Handsontable
registerAllModules();

interface DatgridPageProps {
    urlBase: string;
    populatorEndpoint: string;
    populatorNeedsStyleCode: boolean;
    deleteEndpoint: string;
    pageTitle: string;
    noDataText: string;
    noStyleChosenText: string;
    filterChoices: Record<string, boolean>;
    filterNames: string[];
    filterCallbacks: string[];
    deleteLogicPresent: boolean;
    deleteItemsModalTitle: string;
    deleteItemsModalText: string;
    deleteItemNamesColsConcat: number[]; 
    deleteItemCodesColsConcat: number[];
    crossRefLinks: string[];
    dataWrapperHook: () => void; // Define the prop for the custom hook
    hotSettings: (datagrid: any) => void; // The settings for the Handson table
    checkboxColumnIndex: number; // Index of the column with the checkboxes
}

export default function DatagridTemplatePage(props: DatgridPageProps) {
    // Extract search parameters from URL structure and querystring
    const paramVals = loadSearchParamsCRA(useParams(), new URLSearchParams(useLocation().search));
    const [resultText, setResultText] = useState(null);
    const [toggleModalDeleteItems, setToggleModalDeleteItems] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState(null);
    const [itemNamesToDelete, setItemNamesToDelete] = useState(null);
    const [styleCode, setStyleCode] = useState(paramVals['stylecode']);
    const [filters, setFilters] = useState({ stylecode: styleCode });
    const [isUpdating, setIsUpdating] = useState(false);
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    // Handsontable plumbing
    const hotRefData = useRef(null);
    const hotRefData2 = useRef(null);

    const [triggerUpdate, setTriggerUpdate] = useState(false);
    // Custom hook to pull in the dataitems and populate the grid
    // Note: the useEffect of the custom wrapper hook
    // will be triggered on filter change
    // The grid holds the data displayed in the table
    const [gridData, setGridData, loading, setLoading] = props.dataWrapperHook(filters, triggerUpdate);

    //useWhatChanged([hotRefData.current]); // debugs the below useEffect    
    useEffect(() => {
        
        // On INITIAL LOAD ONLY
        // Check for params passed in via URL structure or querystring 
        // and add to filters if present
        if (paramVals) {
            //console.log('checked paramVals:', paramVals)
            let tempFilters = {};
            for (const key in paramVals) {
                if (paramVals[key]) {
                    tempFilters[key] = paramVals[key];
                }
            }
            filterCallback(tempFilters);
        }

        // Event listener to clear results box
        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('mousedown', mouseClicked);

        // Return with cleanup
        return () => {
            document.removeEventListener('mousedown', mouseClicked);

            // Cleanup the HOT instances
            if (hotRefData && hotRefData.hotInstance && hotRefData.hotInstance.current) {
                hotRefData.hotInstance.current.destroy();
            }

            // Cleanup the HOT instances
            if (hotRefData2 && hotRefData2.hotInstance && hotRefData2.hotInstance.current) {
                hotRefData2.hotInstance.current.destroy();
            }
        }

        // Note the filters are NOT in this dependency array. They ARE in dep array 
        // of the dataWrapper / dataSource and a change will trigger the useEffect there,
        // passing fresh data back into the dataGrid
    }, []);

    const mouseClicked = (event) => {
        setResultText(null);
    }


    // This callback function expects a set of 1 or more key-value pairs
    // structured in a Dict. It will iterate over those new values
    // and add them into / copy them over the existing filter values,
    // then update the filters state to a fresh (cloned) value of the 
    // to trigger an update of the hooks with the filters in their dependancy arrray
    const filterCallback = (filterValues) => {
        try {
            setLoading(true);
            setResultText(null);
            setGridData([]);
            
            // Clone the filters into a temp object, to force the
            // useEffect call in the hook that has these filters 
            // in the dependency array
            let tempFilters = { ...filters };
            for (let key in filterValues) {
                tempFilters[key] = filterValues[key];
            }


            // In the case of stylecodes / stylecolor, add these to the urlpath
            if (filterValues['stylecode'] === null || filterValues['stylecode'] === undefined) {
                window.history.pushState(null, '', props.urlBase);
                setStyleCode(null);
                tempFilters['colorcode'] = null;
            }
            else if (filterValues['stylecode']) {
                let url = props.urlBase + filterValues['stylecode'];
                if (filterValues['colorcode']) {
                    url += "/" + tempFilters['colorcode']
                }
                window.history.pushState(null, '', url);
                setStyleCode(filterValues['stylecode']);
            }

            // Add or update the values from the filters into
            // the current filter object
            setFilters(tempFilters);
        } catch (error) {
            console.error(error);
        }
    }

    const clearFiltersCallback = () => {
        let url = props.urlBase;
        window.history.pushState(null, '', url);
        setFilters({});
        setStyleCode(null);
        setGridData([]);
        setLoading(true);
    }
        

    // Handle a click on the Save button
    const saveClickCallback = () => {
        setResultText(null);
        setIsUpdating(true);
        // POST the data to the API
        upLoadData();
    };

    const upLoadData = async () => {
        // Retrieve the data from the table
        // It is an array of arrays
        let tableData = hotRefData.current.hotInstance.getData();
        // If the populator data requires an extra stylecode dict on row 0, inject it
        if (props.populatorNeedsStyleCode)
            tableData.unshift({ stylecode: styleCode })

        try {
            // Post the tabledata to the API
            let response =
                await axios
                    .post(props.populatorEndpoint, tableData)
                    .catch(error => { console.error(error); });
            //console.log('response status=', response.status);
            if (!response) {
                // ASSUME a null response is a 401 and redirect to the login page
                //console.log('NULL RESPONSE');
                // clear the console
                console.clear();
                setRedirectToLogin(true);
            }
            else {
                let items = "";
                for (var key in response.data) {
                    items += response.data[key] + "<br/>";
                }
                setResultText(formatResultsText(items));
                setTriggerUpdate(!triggerUpdate);
                setIsUpdating(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const deleteItems = async (requestConfirm, confirmed) => {
        if (requestConfirm) {
            // If the delete button was hit, without the modal having been triggered, trigger the modal
            setToggleModalDeleteItems(true);
            return;
        }
        else if (confirmed) {
            const url = props.deleteEndpoint;

            try {
                // DELETE the items
                const config = {
                    method: 'delete',
                    url: url,
                    data: itemsToDelete,
                };

                axios(config)
                    .then(response => {
                        try {
                            setResultText("Deleted: " + itemNamesToDelete);
                            setItemNamesToDelete(null);
                            setItemsToDelete(null);
                            setToggleModalDeleteItems(false);
                            setTriggerUpdate(!triggerUpdate);
                        }
                        catch (e) {
                            console.error(e);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        if (error.response && error.response.status === 401 && error.response.statusText === 'Unauthorized') {
                            // clear the console
                            console.clear();
                            setRedirectToLogin(true);
                        }
                    });
            } catch (error) {
                console.error(error);
            }
        }
        setToggleModalDeleteItems(false);
        
    }

    // Apply HTML formatting to results text
    const formatResultsText = (text) => {
        text = text.replace(/SUCCESS/g, '<span style="color: green; font-weight: bold">SUCCESS</span>');
        text = text.replace(/ERROR/g, '<span style="color: red; font-weight: bold">ERROR</span>');
        return text;
    }

    const deleteCheckedItems = () => {
        
        try {
            // Get the column data
            // Checkbox column index read from props 
            const columnData = hotRefData.current.hotInstance.getDataAtCol(props.checkboxColumnIndex);
            // Iterate over the column data
            const checkedRows = [];
            columnData.forEach((value, rowIndex) => {
                if (value === true) {
                    // Checkbox is checked for this row
                    checkedRows.push(rowIndex);
                }
            });
            if (checkedRows.length !== 0) {

                let itemCodes = [];
                let itemNames = [];
                // Process checked rows
                checkedRows.forEach(rowIndex => {
                    // skip the first (header) row
                    if (rowIndex > 0) {
                        // Access other columns' data for the checked row
                        const rowData = hotRefData.current.hotInstance.getDataAtRow(rowIndex);
                        // Perform actions with the rowData
                        if (!props.deleteItemCodesColsConcat) {
                            itemCodes.push(rowData[0]);
                        }
                        else {
                            let codeString = "";
                            for (let i = 0; i < props.deleteItemCodesColsConcat.length; i++)
                                codeString += rowData[props.deleteItemCodesColsConcat[i]];
                            itemCodes.push(codeString);

                        }
                        let nameString = "";
                        for (let i = 0; i < props.deleteItemNamesColsConcat.length; i++)
                            nameString += rowData[props.deleteItemNamesColsConcat[i]] + " ";
                        itemNames.push(nameString);
                    }
                }

                )
                setItemNamesToDelete(itemNames);
                setItemsToDelete(itemCodes);
                deleteItems(true, false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Check/uncheck all checkboxes in the last column
    const updateAllCheckboxes = (checked, instance) => {
        if (instance) {
            var updatedData = instance.getData();
            updatedData.map(function (row, index) {
                // Update individual checkboxes
                row[row.length - 1] = checked;
                return row;
            });
            setGridData(updatedData);
        }
    }

    // HACK ALERT: the global var 'lastkey' is decalred outside the function dataChanged. It stores 
    // the value of the last key hit and holds that, so that the next time dataChanged is invoked 
    // it can check what the penultimate key was and trigger action on a CTRL+ i or CTRL + b hit.
    var lastKey;
    const dataChanged = (e) => {
            let hot = hotRefData.current.hotInstance;
            if (lastKey === 'Control' && e.key === 'b') {
                let row = hot.getSelected()[0][0];
                hot.alter('insert_row_below', row, 1)
            } else if (lastKey === 'Control' && e.key === 'i') {
                let row = hot.getSelected()[0][0];
                hot.alter('insert_row_above', row, 1)
            }
            lastKey = e.key;
        }
    

    // See: https://handsontable.com/docs/javascript-data-grid/api/hooks/#afterchange
    // See: https://handsontable.com/docs/javascript-data-grid/events-and-hooks/#definition-for-source-argument
    // The handleAfterChange function is called when the value in a cell changes
    // That is used to trigger an update of the bottom row
    // Two params are passed to the function:
    // source: a string, that specifies what the 'trigger' of the change was
    //         relevant options are:
    //         'edit'--> "Action triggered by Handsontable after the data has been changed, e.g., after an edit or using setData* methods."
    //                 ==> This is problematic, as it does not properly allow to diustinguish between a manual and an automatic update,
    //                     and will cause infinite looping when a cell is programatically updated.
    //                     The workaround is to check whether the update was not triggered by the last row (totals row), but that is
    //                     is a HACKY SOLUTION
    //                 ==> OK, so in the 
    //         'CopyPaste.Paste'
    //          'Autofill.fill' -> Triggered when the
    //          'loadData'
    //          'updateData
    //  changes: one or more 4D arrays that list [row, col, oldvalue, newvalue] for the changes
    function handleAfterChange(changes, source) {
        
        // If the change was not for the stylecolorshares page, exit
        if (props.urlBase !== "/stylecolorshares/")
            return;        
        // If the change was not user-driven, exit
        if (source === 'edit' || source === 'Autofill.fill' || source === 'CopyPaste.paste') {
            updateTotals(changes);
        }
    }

    function updateTotals(changes) {

        if (hotRefData.current && hotRefData.current.hotInstance) {
            const hot = hotRefData.current.hotInstance;

            const changedColumns = new Set();

            // Extract the indices of the changed columns
            changes.forEach(([row, column, oldValue, newValue]) => {
                changedColumns.add(column);
            });
            
            // calculate the totals for the changed columns
            changedColumns.forEach(column => {
                // Slice the top row (headers) and last row (totals) off
                const columnData = hot.getDataAtCol(column).slice(1, -1);
                //console.log('[DATAGRIDTEMPLATEPAGE UPDATETOTALS] columnData=', columnData);
                
                const total = columnData.reduce((acc, value) => {
                    if (value === null || value === '') {
                      return acc;
                    }
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                      return acc + parsedValue;
                    }
                    return acc;
                  }, 0);
                

                // Get the index of the bottom row
                const totalRow = hot.countRows() - 1;

                //console.log('[DATAGRIDTEMPLATEPAGE UPDATETOTALS] total=', total);

                // Update the total in the bottom row
                // The last parameter passed here is the 'source' and by setting it, this
                // will prevent infinite looping of the afterChange function
                hot.setDataAtRowProp(totalRow, column, total + '%', 'TOTALSCALC');
            });
        }
    }
    const extractCrossRefLinks = (links) => {
        return links.map((value) => (
            < WrapItem key={value}>
                <Center>
                    <Button
                        as="a"
                        href={value[0] + styleCode}
                        textDecoration="none"
                        colorScheme="blue"
                        variant="outline"
                    >
                        {value[1]}
                    </Button>
                </Center>
            </WrapItem>
        ));
    };

    // Generate the filter props based on the filter names
    // The names passed in will be an array: ['season','styleCode','weekStart', ....]
    // Theis array is iterated and for each of the names, it will 
    const generateFilterPropsAndCallbacks = () => {
        const filterProps: { [key: string]: any } = {};
        const filterCallbacksFuncs: { [key: string]: any } = {};
 
        props.filterNames.forEach((name) => {
            // Assign the value to the filter prop
            filterProps[name] = filters[name];
            filterCallbacksFuncs[name + 'FilterCallback'] = filterCallback;
        });

        return [filterProps, filterCallbacksFuncs];
    };
    const filterPropsAndCallbacks = generateFilterPropsAndCallbacks();

    // If the flag was set to redirect (session expired), redirect to login page
    if (redirectToLogin)
        return <Navigate to={"/login?hitunauthorizedurl=true&next=" + window.location.pathname} />;

    return (
        <main>

            {toggleModalDeleteItems ? (<ConfirmDeleteItemsModal title={props.deleteItemsModalTitle} text={props.deleteItemsModalText} items={itemNamesToDelete} callback={deleteItems} />) : null}
            <Navbar />
            <TitleBar title={props.pageTitle} />
            <FilterBar
                filterChoices={props.filterChoices}
                clearFilters={clearFiltersCallback}
                {...filterPropsAndCallbacks[0]}
                {...filterPropsAndCallbacks[1]}
            />

            <Wrap spacing="10px" ml="15px" mt="5px" flex="1" alignItems="right" pr={5} w="95%">
                {(loading) ?
                    (!styleCode && props.noStyleChosenText) ?
                        (
                            <Box pl={"10px"} pt={"20px"} maxW={800} color="blue.500" fontWeight={"bold"} w={"100%"}>{props.noStyleChosenText}</Box>
                        )
                        :
                        (
                            <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                        )
                    :
                    ((gridData && gridData.length === 0) ?
                        <Box pl={"10px"} pt={"20px"} maxW={1200} color="blue.500" fontWeight={"bold"} w={"100%"}>{props.noDataText}</Box>
                        :
                        <>
                            <Box border="1px solid lightgray" p={2} borderRadius="md" mr={5} width={"100%"}>

                                <HotTable
                                    id={"hot_table_" + props.urlBase.replaceAll('/', '')}
                                    ref={hotRefData}
                                    settings={props.hotSettings(gridData)}
                                    beforeKeyDown={dataChanged}
                                    afterChange={handleAfterChange}
                                    licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                                />

                                {props.urlBase !== '/stylecolorshares/' &&
                                    <Box mt={5} color="blue.500" fontWeight={"bold"}>Type CTRL + b for new row below or CTRL + i for a new row above the current cell</Box>
                                }


                                <Wrap spacing="6px" pt={3} flex="1" alignItems="right" >
                                    {(styleCode && styleCode !== undefined && props.crossRefLinks) ? extractCrossRefLinks(props.crossRefLinks) : null}
                                    <WrapItem><Center><Button data-cy={"button_datagridpage_save_" + props.urlBase.replaceAll('/', '')} colorScheme="blue" variant="outline" isLoading={isUpdating} loadingText='Saving' onClick={(...args) => saveClickCallback(...args)}>Save</Button></Center></WrapItem>
                                    {props.deleteLogicPresent ? (
                                        <WrapItem><Center><Button data-cy={"button_datagridpage_delete_" + props.urlBase.replaceAll('/', '')} colorScheme="blue" variant="outline" onClick={(...args) => deleteCheckedItems(...args)}>Delete checked</Button></Center></WrapItem>
                                    ) : null}
                                </Wrap>

                            </Box>
                            <Box minW={200}  >
                                {resultText ? (
                                    <>
                                        <Box fontWeight="bold">Results:</Box>
                                        <Box fontSize={12} dangerouslySetInnerHTML={{ __html: resultText }}></Box>
                                    </>
                                ) : null}
                            </Box>                            
                            {props.urlBase === '/stylecolorshares/' &&
                                <Box border="1px solid lightgray" p={2} borderRadius="md" mr={5} width={"100%"}>
                                    <StylecolorUnitsTable ref={hotRefData2} data={gridData} styleCode={styleCode} />
                                </Box>
                            }
                        </>
                    )}

            </Wrap>
        </main>
    );
}


// Note that this function is defined outside of the body of the main 
// function DatagridTemplatePage, so that it can be exported and used
// from the DatagridPageDefs file as the event handler that is attached
// to the TDs that hold the checkboxes in the table 
export function boxClicked(event, hotInstance, checkboxColumnIndex) {
    try {
        var checkbox = event.target;
        if (checkbox && checkbox.parentNode) {
            var isChecked = checkbox.checked;
            var row = checkbox.parentNode.parentNode.rowIndex;
            var column = checkbox.parentNode.cellIndex;
            // If the top row checkbox is checked, check all 
            if (row === 0 && column === checkboxColumnIndex) {
            
                // Set the checkboxes to checked/unchecked
                if (hotInstance) {
                    var updatedData = hotInstance.getData();
                    updatedData.map(function (row, index) {
                        // Update individual checkboxes
                        row[row.length - 1] = isChecked;
                        return row;
                    });
                    // Reload the data into the table
                    hotInstance.loadData(updatedData);
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
}

