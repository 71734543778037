import axios from "axios";

// Parameters can be passed in through two methods:
// 1. The url structure: /pagename/stylecode/stylecolor
// 2. Querystring parameters: ?stylecode=123456&stylecolor=099&season=F2024&weekstart=202333&weekend=202409&market=at
// The url structure can be read from the {params['params']} passed into the page. The order here is key:
// params[0] = stylecode, params[1] = stylecolor
// The querystring parameters are accessed through the useSearchParams object, that can be iterated and contains all search parameters
export function loadSearchParamsNext(params) {
    let urlStructureParams;
    let querystringParams;

    // Yes, really, this is how the params are stored..... padam, padam, padam
    if (params && params['params'] && params['params']['params'])
        urlStructureParams = params['params']['params'];
    if (params && params['searchParams'])
        querystringParams = params['searchParams'];
    if (!urlStructureParams && !querystringParams)
        return {};

    const paramVals = {};
    if (urlStructureParams && urlStructureParams[0]) paramVals['stylecode'] = urlStructureParams[0];
    if (urlStructureParams && urlStructureParams[1]) paramVals['colorcode'] = urlStructureParams[1];

    for (const key in querystringParams) {
        // Note: if stylecode (and stylecolor) are in the URL structure, the querystring values will be ignored
        if (urlStructureParams) {
            if (!urlStructureParams[0] && key === 'stylecode')
                paramVals[key] = querystringParams[key];
            if (!urlStructureParams[1] && key === 'colorcode')
                paramVals[key] = querystringParams[key];
            if (key !== 'colorcode' && key !== 'stylecode')
                paramVals[key] = querystringParams[key];
        }
        else {
            paramVals[key] = querystringParams[key];
        }
    };
    return paramVals;
}

export function loadSearchParamsCRA(urlStructureParams, querystringParams) {
    if (!urlStructureParams && !querystringParams)
        return {};

    const paramVals = {};
    if (urlStructureParams && urlStructureParams.stylecode) paramVals['stylecode'] = urlStructureParams.stylecode;
    if (urlStructureParams && urlStructureParams.colorcode) paramVals['colorcode'] = urlStructureParams.colorcode

    for (const key of querystringParams) {
        // Note: if stylecode (and stylecolor) are in the URL structure, the querystring values will be ignored
        if (urlStructureParams) {
            if (!urlStructureParams.stylecode && key[0] === 'stylecode')
                paramVals[key[0]] = key[1];
            if (!urlStructureParams.colorcode && key[0] === 'colorcode')
                paramVals[key[0]] = key[1];
            if (key[0] !== 'stylecode' && key[0] !== 'colorcode')
                paramVals[key[0]] = key[1];
        }
        else {
            paramVals[key[0]] = key[1];
        }
    };
    return paramVals;
}


export function salesParametersFromStyleCode(url, callbackParametersFetched, callbackParametersId, callbackParameters) {
    callbackParametersFetched(false);
    let data = null;
    axios.get(url)
        .then((getResponse) => {
            data = getResponse.data;

            // Extract the salesparameters data. The below assumes the data is structred into a JSON
            /*
            {
                "id": 2,
                "style": 1,
                "display_stylename": "Addiction 14",                    
                "startweek": 202309,
                "endweek": 202346,
                "startlevel": 1000,
                "endlevel": 250,
                "decayfactor": 0.95,
                "atp_default_percentage": 15,
                "inactive_per_week": 202335
            }
            */
            // Populate the grid with data 
            if (data[0]) {
                callbackParametersId(data[0].id)
                callbackParameters(data[0]);
            }
            else {
                callbackParameters([{
                    "startweek": null,
                    "endweek": null,
                    "startlevel": null,
                    "endlevel": null,
                    "decayfactor": null,
                    "atp_default_percentage": null,
                    "inactive_per_week": null,

                }]);
            }
            callbackParametersFetched(true);

        })
        .catch((error) => {
            console.error('ERROR retrieving data');
            console.error(error);
        });
}

export function styleTitleFromStyleData(styleData) {
    try {
        if (!styleData)
            return "";
        else
            return (styleData.name + " (" + styleData.code + ")");
    }
    catch (error) {
        console.error(error);
    }
    return "";
}

export function styleNameFromStyleCode(url, callback) {

    const fetchData = () => {
        try {
            axios.get(url)
                .then((getResponse) => {
                    // Extract the data. The below assumes the data is structred into a JSON
                    /*
                    [
                        {
                            "id": 9,
                            "name": "Addiction 14",
                            "code": "110317",
                            "introseason": "S2021",
                            "family": 8,
                            "display_family": "ADDICTION GTS",
                            "gender": "M"
                        }
                    ]*/

                    if (getResponse.data) {
                        callback(getResponse.data[0].name);
                    }
                })
                .catch((error) => {
                    console.error('ERROR retrieving data');
                    console.error(error);
                    callback("");
                });
        } catch (error) {
            console.error(error);
            callback("");
        }
    }
    fetchData();
}

export function styleDataFromStyleCode(url, callback) {

    const fetchData = () => {
        try {
            axios.get(url)
                .then((getResponse) => {
                    // Extract the data. The below assumes the data is structred into a JSON
                    /*
                    [
                        {
                            "id": 9,
                            "name": "Addiction 14",
                            "code": "110317",
                            "introseason": "S2021",
                            "family": 8,
                            "display_family": "ADDICTION GTS",
                            "gender": "M"
                        }
                    ]
                    */
                    if (getResponse.data) {
                        callback(getResponse.data[0]);
                    }
                })
                .catch((error) => {
                    console.error('ERROR retrieving data');
                    console.error(error);
                    callback("");
                });
        } catch (error) {
            console.error(error);
            callback("");
        }
    }
    fetchData();
}

// Calculator functions to (re)calculate the values in the readonly cells
// A. Calculate the campaign_multiplier value for a given week. The value
//    is calculated as 1 + CP/10 * CampaignMaxAcceleration,
//    where CP = campaign pressure and CampaignMaxAcceleration is a GLOBAL
//    setting, given on a 0-100 scale, that is used to finetune the
//    'push' that sales will get from a marketing campaign.
// B. Calculate the discount_multiplier value for a given week. The value
//    is calculated as 1 + DP/10 * DiscountMaxAcceleration,
//    where DP = discount pressure and DiscountMaxAcceleration is a GLOBAL
//    setting, on a 0-100 scale, that is used to finetune the 'push' that
//    sales will get from discounting product.
//    Note thayt the discount pressure can be:
//    - positive (1 to 10): if the style itself is being discounted
//    - negative (-10 to -1): if the 'sibling style' is being discounted
//      (i.e.the) old version of the style which is being cleared from inventory
// C. The plan values are the multiplier of the base plan, the campaign multiplier,
//    the discount multiplier and the model multiplier:
//    Plan = BP x CM x DM x MM
export function calCulateMultipliersAndPlanValues(
    tableData,
    callbackSetGridData,
    startWeek,
    endWeek,
    campaignMaxAcceleration,
    discountMaxAcceleration) {

    //console.log('[calCulateMultipliersAndPlanValues] startWeek=', startWeek);
    //console.log('[calCulateMultipliersAndPlanValues] endWeek=', endWeek);
    //console.log('[calCulateMultipliersAndPlanValues] tableData=', tableData);
    //console.log('[calCulateMultipliersAndPlanValues] callbackSetGridData=', callbackSetGridData);
    //console.log('[calCulateMultipliersAndPlanValues] campaignMaxAcceleration=', campaignMaxAcceleration);
    //console.log('[calCulateMultipliersAndPlanValues] discountMaxAcceleration=', discountMaxAcceleration);

    // An array is also filled with just the values that need to be 
    // sent back to the API for processing
    let slicedData = new Array(tableData.length);
    //let slicedIndex = 1; // Start at 1, as the API expects the  
    // Copy the column headers into the sliced data array
    for (let j = 0; j < tableData.length; j++) {
        //slicedData[j].push(tableData[j][0]);      
        slicedData[j] = [];
        slicedData[j].push(tableData[j][0]);
    }
    let cp = 0;
    let dp = 0;


    // Note: start at index 1, as the first column contains a text-value for the row label
    for (var i = 1; i < (tableData[1]).length; i++) {
        // Only operate on columns that are in the 'window' of the plan,
        // between startWeek and endWeek of the salesparameters
        if (parseInt(tableData[0][i]) >= parseInt(startWeek) && parseInt(tableData[0][i]) <= parseInt(endWeek)) {
            // Get the value for the campaign pressure
            cp = tableData[3][i];
            // Calculate and update the value for the campaign multiplier
            tableData[4][i] = 1 + (cp / 10 * campaignMaxAcceleration / 100);
            // Get the value for the discount pressure
            dp = tableData[5][i];
            // Calculate and update the value for the discount multiplier
            tableData[6][i] = 1 + (dp / 10 * discountMaxAcceleration / 100);

            // Calculate the value for the planned units
            tableData[9][i] = tableData[8][i] * tableData[4][i] * tableData[6][i] * tableData[2][i];

            // Copy the values of the tableData column into the slicedData array column
            for (let j = 0; j < tableData.length; j++)
                slicedData[j].push(tableData[j][i]);
        }
    }
    callbackSetGridData(tableData);

    //console.log('[calCulateMultipliersAndPlanValues] slicedData=', slicedData);

    // The .slice() is needed to clone a new instance 
    // of the array (not the reference) as
    // the grid has not got all the multiplier values in it yet
    return slicedData.slice();
}

export function baseModelAndSalesplanForStyle(modelUrl, planUrl, callbackSetGridData, includeCPandDP = false) {
    // The gridvals object is an array of arrays, with each array 'row' holding objects pairs as its
    // 'value' elements
    const gridVals =
        [
            ["Week"],
            ["Basemodel (week %)"],
            ["Basemodel multiplier (MM)"],
            ["Campaign pressure (0 to 10)"],
            ["Campaign multiplier (CM)"],
            ["Discount pressure (-10 to 10)"],
            ["Discount multiplier (DM)"],
            ["Discount percentage (0-100)"],
            ["Baseplan (BP)"],
            ["Plan (BP x MM x CM x DM)"],
            ["Active/Inactive"],
        ];
    //console.log('[BASEMODELANDSALESPLANFORSTYLE] planUrl=', planUrl);
    //console.log('[BASEMODELANDSALESPLANFORSTYLE] modelUrl=', modelUrl);

    // Start by retrieving the basemodel data and salesplan data
    // In the below code, axios.all is used to make parallel requests to both modelUrl and planUrl.
    // The.then block is called when both requests are completed. 
    // The responses from both requests are passed as arguments to axios.spread, 
    // which allows access to the response data parts separately and the 
    // modelResponse.data and planResponse.data are processed when ythey are both available

    axios.all([axios.get(modelUrl), axios.get(planUrl)])
        .then(axios.spread((modelResponse, planResponse) => {
            const basemodelData = modelResponse.data;
            const salesplanData = planResponse.data;
            // The BMdata is leading in terms of displaying the week numbers in the table
            // The salesplan may or may not overlap with the bm data, so when iterating over the bmdata
            // checks need to be done to 'line up' the week of the salesplan to the week of the basemodel
            //console.log('[BASEMODELANDSALESPLANFORSTYLE] salesplanData=', salesplanData);
            //console.log('[BASEMODELANDSALESPLANFORSTYLE] salesplanData.length=', salesplanData.length);

            //console.log('[BASEMODELANDSALESPLANFORSTYLE] basemodelData=', basemodelData);
            //console.log('[BASEMODELANDSALESPLANFORSTYLE] basemodelData.length=', basemodelData.length);
            for (let bmIndex = 0; bmIndex < basemodelData.length; bmIndex++) {
                try {
                    // Values that come from the basemodel, these will be displayed regardless 
                    // whether there is overlap between the basemodel and the salesplan
                    gridVals[0].push(basemodelData[bmIndex].week);
                    gridVals[1].push(basemodelData[bmIndex].share);
                    gridVals[2].push(basemodelData[bmIndex].multiplier);
                    // For the Campaign Pressure and the Discount Pressure, include these if the flag was set
                    if (includeCPandDP) {
                        //console.log('[BASEMODELANDSALESPLANFORSTYLE] including base_campaign_pressure and base_discount_pressure');
                        gridVals[3].push(basemodelData[bmIndex].base_campaign_pressure);
                        gridVals[5].push(basemodelData[bmIndex].base_discount_pressure);
                    }
                    else {
                        gridVals[3].push('');
                        gridVals[5].push('');

                    }

                    // Push in empty values to the remaining elements
                    // this will get overwritten with salesplan data if the salesplan for this week is populated 
                    gridVals[4].push('');
                    gridVals[6].push('');
                    gridVals[7].push('');
                    gridVals[8].push('');
                    gridVals[9].push('');
                    gridVals[10].push('');

                    // Retrieve the week from the basemodel data
                    let bmWeek = basemodelData[bmIndex].week;

                    // Although there is some cost to this, reset spIndex to 0 each time
                    // as the bmData could be sparsely populated, missing entries for some weeks
                    // That means a full comparison of the who arrays needs doing every time
                    let spIndex = 0;
                    if (salesplanData.length > 0) {

                        let spWeek = salesplanData[spIndex].week;

                        // If the spIndex has advanced beyond the end of the dataset, that means
                        // the basemodel has no weeks in it that also are present in the salesplan
                        // In that case, no data will be displayed

                        //console.log('[BASEMODELANDSALESPLANFORSTYLE] spWeek=', spWeek);
                        //console.log('[BASEMODELANDSALESPLANFORSTYLE] bmWeek=', bmWeek);
                        
                        // Advance the spIndex until the spData's week matches that of the basemodel
                        while (spWeek !== bmWeek) {
                            spIndex++;

                            ////console.log('walking bmWeek=', bmWeek, 'spWeek=', spWeek);
                            // Break out of the loop if the end of salesplandata
                            // is hit and the week match cannot be found
                            if (spIndex >= salesplanData.length)
                                break;
                            spWeek = salesplanData[spIndex].week;
                        }
                        if (spIndex < salesplanData.length && spWeek === bmWeek) {

                            // values that come from the salesplan
                            // note that the column being populated = bmIndex + 1, because the
                            // first column in the table contains the row heaaders 
                            if (!includeCPandDP) {
                                gridVals[3][bmIndex + 1] = salesplanData[spIndex].campaign_pressure;
                                gridVals[5][bmIndex + 1] = salesplanData[spIndex].discount_pressure;
                            }
                            gridVals[4][bmIndex + 1] = salesplanData[spIndex].campaign_multiplier;
                            gridVals[6][bmIndex + 1] = salesplanData[spIndex].discount_multiplier;
                            gridVals[7][bmIndex + 1] = salesplanData[spIndex].discount_percentage
                            gridVals[8][bmIndex + 1] = salesplanData[spIndex].baseplan;
                            gridVals[9][bmIndex + 1] = salesplanData[spIndex].planned_units;
                            gridVals[10][bmIndex + 1] = salesplanData[spIndex].active_inactive;
                        }
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }
            //console.log('[BASEMODELANDSALESPLANFORSTYLE] Updating grid data with values:', gridVals);
            // Populate the grid with data 
            callbackSetGridData(gridVals);

        }))
        .catch((error) => {
            console.error('ERROR retrieving data');
            console.error(error);
        })
}

export async function buildSalesplanFromParams(
    callbackUpdateState,
    callbackResultMessage,
    populatorUrl,
    basemodelUrl,
    salesplanUrl,
    callbackDataGrid,
    includeCPandDP = false) {

    //console.log('[BASEMODELANDSALESPLANFORSTYLE BUILDSALESPLANFROMPARAMS] IN!'); 

    callbackUpdateState('loading');
    callbackResultMessage('');
    //console.log('[BUILDSALESPLANFROMPARAMS] (Re)building saleplan, populatorUrl=', populatorUrl);
    axios
        .get(populatorUrl)
        .then((getResponse) => {
            //console.log('[BASEMODELANDSALESPLANFORSTYLE BUILDSALESPLANFROMPARAMS] Updated salesparams, response=', getResponse);
            // Check the response for success / error message
            if (getResponse.data[0].indexOf('ERROR:') >= 0) {
                callbackResultMessage(getResponse.data[0]);
            }
            else {

                callbackResultMessage("Succesfully (re)generated salesplan.");
                // Refresh the grid data
                baseModelAndSalesplanForStyle(basemodelUrl, salesplanUrl, callbackDataGrid, includeCPandDP);
            }
            callbackUpdateState('success');
        })

        .catch((error) => {
            console.error(error);
            let errorText = 'Error updating salesplan for style. Error: ';
            errorText += error;
            callbackResultMessage(errorText);
        });
}

// Fill the salesparams from the form fields and PUT to API
// The JSON to PUT looks like this:
/*
    {
    "style": 1,
    "startweek": 202309,
    "endweek": 202346,
    "startlevel": 1000,
    "endlevel": 250,
    "decayfactor": 0.95,
    "atp_default_percentage": 15,
    "inactive_per_week": 202335
    }
*/
export async function saveSalesParameters(
    postUrl,
    paramData,
    callbackResultMessage,
    callbackSetParameters) {

    let result = null;

    //console.log('[SAVESALESPARAMETERS] Saving params, paramId=', paramData.id);
    //console.log('[SAVESALESPARAMETERS] Saving params, paramData=', paramData);

    try {
        if (paramData.id !== undefined) {

            postUrl += "/" + paramData.id + "/";
            callbackResultMessage();
            await axios
                .put(postUrl, paramData)
                .then((response) => {
                    callbackResultMessage('Saved sales params ' + response.data.id + ' for style');
                    result = true;
                })
                .catch(error => {
                    console.error(error);
                    let errorText = 'Error saving sales parameters for style. Error: ';
                    errorText += error;
                    callbackResultMessage(errorText);
                    result = false;
                });
        }
        else {
            callbackResultMessage();
            // Append the slash to the endpoint
            postUrl += "/";
            await axios
                .post(postUrl, paramData)
                .then((response) => {
                    // Retrieve the params id of the fresly created style and inject it into the returned data
                    const callbackData = { ...paramData, ...{ id: response.data.id } };
                    callbackSetParameters(callbackData);
                    //console.log('[SAVESALESPARAMETERS] Passed these back to the parent:', callbackData);
                    callbackResultMessage('Successfully created new sales parameters ' + response.data.id + ' for style');
                    result = true;
                })
                .catch(error => {
                    console.error(error);
                    let errorText = 'Error creating sales params for style. Error: ';
                    errorText += error;
                    callbackResultMessage(errorText);
                    result = false;
                });
        }
    } catch (error) {
        console.error(error);
        callbackResultMessage(error);
        result = false;
    }
    return result;
}
