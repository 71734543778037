import { useEffect, useState } from 'react'

// Custom hook to abstract persistent storage  
const useLocalStorage = (key, initialValue) => {

    const [value, setValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            console.error(error)
            return initialValue
        }
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);
    return [value, setValue]
}

export default useLocalStorage;