import useSWR from 'swr';

const useAuthSWR = (url) => {
    const token = sessionStorage.getItem('token');
    const headers = {
        ...(token && { Authorization: `Token ${token}` }),
    };

    const fetcher = async (url) => {
        try {
            const response = await fetch(url, { headers });
            const data = await response.json();
            // Throw an error in case of a 401 which can be handled 
            // by the downstream component onthe page (e.g. to redirect to login page)
            if (response && response.status === 401)
                throw new Error('[401] A 401 error occurred while fetching the data.');
            return data;
        } catch (error) {
            console.error('Error retrieving URL:', error);
            throw error;
        }
    };
    const { data, error } = useSWR(url, fetcher);
    return { data, error };
};
export default useAuthSWR;
