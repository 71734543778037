import React from 'react';
import axios from 'axios';
import { Box, Flex, Link } from '@chakra-ui/react';

export default function Logout() {


    const handleLogout = async () => {
        // Call the endpoint to clear the token(s) from the backend
        try {
            const response = await axios.post(process.env.REACT_APP_URL_AUTH_DELETE_TOKEN);
        }
        catch (e) {
            // If error: token was already deleted
        }

        // Delete the token from the axios headers
        delete axios.defaults.headers.common["Authorization"];
        // Remove the token from the session
        sessionStorage.setItem('token', null);
        sessionStorage.setItem('username', null);
        sessionStorage.clear();
    }

    handleLogout();

    return (
        <Flex
            height="100vh"

            alignItems="center"
            justifyContent="center"
            bg="#EBF8FF"
        >
            <Box width="400px" minH="390px" p={4} m={4} borderRadius="md" backgroundColor="#FDB813">
                <Box
                    data-cy="logintopbar"
                    width="100%"
                    textAlign="center"
                    fontFamily="Filson Pro"
                    fontWeight="bold"
                    fontSize="30"
                    color="#D02255"
                    zIndex={1000}
                    display="flex"
                    bg="#FDB813"
                    px={4}
                    py={4}

                    height={20}
                    alignItems="center"
                >
                    Logged out.

                </Box>
                <Box
                    data-cy="logintopbar"
                    width="100%"
                    textAlign="center"
                    fontFamily="Filson Pro"
                    fontWeight="bold"
                    fontSize="20"
                    color="white"
                    zIndex={1000}
                    display="flex"
                    bg="#FDB813"
                    px={4}
                    py={4}

                    height={20}
                    alignItems="center"
                >
                    See you soon again

                </Box>
                <Box px="20px" pt="150px">
                    <Link
                        href="/login">Login</Link>

                </Box>

            </Box>
        </Flex>
    );
}
