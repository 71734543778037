import { useEffect, useState, useRef } from "react";
import useDataSource from "./useDataSource";
import { getNextWeek, weeksInYear } from "../utils/utils";
import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";
// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');

const useSalesForecastTotalsCollection = (filters, triggerUpdate) => {
    const [dataOut, setDataOut] = useState([]);
    const [loading, setLoading] = useState(true);
    // NOTE: DO NOT TOUCH THE FILTERS HERE, THAT LEADS TO INFINITE LOOPS. PASSIVELY PASS THEM THROUGH
    const [dataIn1, setDataIn1] = useDataSource(process.env.REACT_APP_ENDPOINTBASEMODELS, filters, true, triggerUpdate);


    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    //useWhatChanged([dataIn1]); // debugs the below useEffect    
    useEffect(() => {
        let ignore = false;

        // - Start with a 6-digit startweek and endweek, say 202245 and  202352
        // - Populate an array, starting at the startweek, ending in the endweek, making sure the 
        //   last week of the year(s)(if the start / end span  multiple years) are correct as an ISO week, so either 52 or 53
        // - Result: fully populated array, filled with 6-digit weeknumbers, say [202245, ....., 202352]
        // - After this, take the sparsely populated dicts, that contain entries of the form {week: 202302, total: 123, week 202315, total: 786, ...} 
        //   Note that these dicts are sparse, they do not contain an entry for each week
        // - Extend the original array into a grid, by adding a new row for each dict, in which the values from the dict are placed in the column corresponding to the weeknumber 
        // - Note:
        // - The top row if the grid is a label row, these are the weeknumbers
        // - The first columns of the grid is a label for the specific dict. So the data essentially starts on row 2, column 2.
        const fetchData = () => {

            function generateGrid(startWeek, endWeek, dict) {
                //console.log('[BASEMODEL] dict=', dict)
                // Initialize grid with a label for the week
                const grid = [['Week:']];
                // Add week numbers as labels in the first row
                for (let week = startWeek; week <= endWeek; week = getNextWeek(week)) {
                    grid[0].push(week);
                }
                // Start the row with the dict label
                const row1 = ['Share'];
                const row2 = ['Multiplier'];
                const row3 = ['Base campaign pressure (0-10)'];
                const row4 = ['Base discount pressure (0-10)'];

                // Populate data for each week
                for (let week = startWeek; week <= endWeek; week = getNextWeek(week)) {

                    // Find the entry with the matching week number
                    const entry = dict.find(entry => parseInt(entry.week) === parseInt(week));
                    //console.log('[BASEMODEL] entry=', entry)

                    // Get the value and insert it or insert '' if not found
                    row1.push(entry ? entry.share : '');
                    row2.push(entry ? entry.multiplier : '');
                    row3.push(entry ? entry.base_campaign_pressure : '');
                    row4.push(entry ? entry.base_discount_pressure : '');
                }
                grid.push(row1);
                grid.push(row2);
                grid.push(row3);
                grid.push(row4);
                
                return grid;
            }
            const grid = generateGrid(filters['weekfilter_start'], filters['weekfilter_end'], dataIn1);

            // Do not return stale data 
            if (!ignore) {
                setDataOut(grid);
                setLoading(false);
            }
            return;

        }
        if (dataIn1) {
            fetchData();
        }
        // The cleanup function prevents a double request and the return of stale data
        return () => {
            ignore = true;
        };
    }, [dataIn1]);

    return [dataOut, setDataOut, loading, setLoading];
}
export default useSalesForecastTotalsCollection;