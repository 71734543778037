import { Children, useState, useRef } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Link, Image } from '@chakra-ui/react'


// Icons. See: https://react-icons.github.io/react-icons/ and https://chakra-ui.com/docs/components/icon/usage#all-icons
import { Icon } from '@chakra-ui/react'
import { RiTestTubeLine } from 'react-icons/ri'
import { MdOutlineUploadFile } from 'react-icons/md'
import { CgLogOut } from 'react-icons/cg'

import useAuth from '../../views/auth/useAuth'

const NavLink = ({ to, title, image, icon }) => {
    return (
        <Link
            href={to}
            display="flex"
            alignItems="left"
            px={4}
            py={2}
            borderRadius={5}
            color="white"
            _hover={{ bg: 'blue.500', color: 'white', textDecoration: 'none' }}
            whiteSpace="nowrap"
            minWidth="max-content"                      
        >
            {image && (<Image src={"/images/nav/" + image} alt={title} boxSize={25} mr={2} />)}
            {icon  && (<Icon as={icon} boxSize={"20px"} mr={3} />)}
            {title}
        </Link>

    );
};

const SubMenu = ({ to, title, image, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isMouseInside, setIsMouseInside] = useState(false);
    const timeoutRef = useRef(null);

    const handleMouseEnter = () => {
        setIsExpanded(true);
    };

    const handleMouseLeave = () => {
        setIsMouseInside(false);
        timeoutRef.current = setTimeout(() => {
            if (!isMouseInside) {
                setIsExpanded(false);
            }
        }, 200); // Delay before closing the submenu in milliseconds
    };

    const handleSubMenuMouseEnter = () => {
        setIsMouseInside(true);
        clearTimeout(timeoutRef.current);
    };

    const handleSubMenuMouseLeave = () => {
        setIsMouseInside(false);
        setIsExpanded(false);
    };

    return (
        <Box
            position="relative"
            whiteSpace="nowrap"
            display="flex"            
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>

            <Link
                display="flex"
                alignItems="center"
                px={4}
                py={2}
                borderRadius={5}
                bg={isExpanded ? "blue.500" : ""}
                _hover={{ bg: 'blue.500', color: 'white', textDecoration: 'none' }}
                _focus={{ outline: 'none' }}
                color="white"             
                href={to} >
                
                {image && (<Image src={"/images/nav/" + image} alt={title} boxSize={25} mr={2} />)}
                {title}
                
                <ChevronDownIcon
                        ml={5}
                        transform={isExpanded ? "rotate(180deg)" : ""}
                        transition="300ms"                        
                    />
                                       
            </Link>
            {isExpanded && (
                <>
                    <Box
                        position="absolute"
                        top="calc(100% + 0.5rem)"
                        right="0rem"
                        mt={2}
                        pt={5}
                        pb={2}
                        px={2}
                        bg="blue.400"
                        borderRadius={5}
                        shadow="lg"
                        whiteSpace="nowrap"                        
                        onMouseEnter={handleSubMenuMouseEnter}
                        onMouseLeave={handleSubMenuMouseLeave}
                    >
                        {/* The up pointing arrow on top of the submenu box */}
                        <Box position="absolute" top={0} right={10} transform="translateX(-1/2)">
                            <Box position="absolute" top={-2} width={5} height={5} bg="blue.400" transform="rotate(45deg)"/>
                        </Box>
                        {children}
                    </Box>
                </>
            )}
        </Box>
    );
};


function MobileNav({ open, setOpen }) {
    return (

        <Box
            position="absolute"
            zIndex={2000}
            top={79}
            left={0}
            height="90vh"
            width="100vw"
            bg="#FDB813"
            boxShadow="0px -4px 4px rgba(0, 0, 0, 0.2)"
            transform={open ? "translateX(0)" : "translateX(-100%)"}
            transition="transform 300ms ease-in-out"
            >

            <Box ml={4} pt={30} pl={2} pr={40}>
                <NavLink to="/styles" title="Styles" image="styles.png" />
                <NavLink to="/stylecolors" title="Style Colors" image="palette.png" />
                <NavLink to="/stylefamilies" title="Style Families" image="family.png" />

                <NavLink to="/basemodel" title="Basemodel" image="basemodels.png" />
                <NavLink to="/salesplan" title="Salesplan" image="calendar.png" />
                <NavLink to="/stylecolorshares" title="Style Color Shares" image="pie.png" />

                <NavLink to="/uploads" title="Uploads" icon={MdOutlineUploadFile} />
                <NavLink to="/forecast" title="Forecast" icon={RiTestTubeLine} />

                <NavLink to="/moat" title="Moat" image="table.png"/>      
                <NavLink to="/bulkpositions" title="Actuals" image="near_me.png" />
                <NavLink to="/markets" title="Markets" image="market.png" />
                {/*<NavLink to="/marketshares" title="Market Shares" image="pie.png" />*/}
                <NavLink to="/howto" title="How To" image="questionmark.png" />      
                <NavLink to="/logout" title="Logout" icon={CgLogOut} />
            </Box>
        </Box>
    )
}

export default function Navbar() {
    const isAuthenticated = useAuth();
    const [open, setOpen] = useState(false)
    return (
        <>
            {
                process.env.REACT_APP_ENVNAME !== 'PROD' ?
                    (    <Box
                        position="fixed"
                        zIndex={2000}
                        height={8}
                        width={85}                      
                        pb={1}
                        px={2}
                        borderRadius={2}        
                        transform="rotate(90deg) translate(26px, 38px)"
                        bg="red.600"
                        color="white"
                        fontFamily="mono"
                        fontSize={12}
                    >{process.env.REACT_APP_ENVNAME} V{process.env.REACT_APP_VERSION}</Box>) : null
            }
            <Box 
                data-cy="navbar"
                position="fixed"
                width="100%"
                zIndex={1000}
                display="flex"
                boxShadow="0px 2px 4px rgba(0, 0, 0, 0.2)"
                bg="#FDB813"
                px={4}
                py={4}
                height={20}
                alignItems="right"
            >
                <MobileNav open={open} setOpen={setOpen} />

                <Box w={400}
                    mt={1}
                    py={3}

                    display="flex"
                    alignItems="center"
                >
                    <Image src="/images/logo-tr.png" alt="Sales Manager" w="50px" p={1} mr="10px" />
                    <Link href="/"><Image src="/images/LogoText-white-tr.png" alt="Sales Manager" h="30px" /></Link>
                </Box>

                    <Box
                        w="100%"
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        >
                    
                    {/* hamburger button -three divs with background color */}
                    <Box
                        display={{ base: 'flex', md: 'none' }}
                        zIndex={1000}
                        w="12"
                        h="10"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {setOpen(!open)}}>
                        
                        <Box
                            h={2}
                            w="full"
                            bg="blue.500"
                            rounded="lg"
                            transition="all 300ms ease-in-out"
                            {...(open ? { transform: "rotate(45deg) translate(10px, 12px)" } : {})}
                        />
                                    
                        <Box
                        h={2}
                        w="100%"
                        bg="blue.500"
                        rounded="lg"
                        transition="all 300ms ease-in-out"
                        {...(open ? { w: 0 } : {})}
                        />                        

                        <Box
                        h={2}
                        w="full"
                        bg="blue.500"
                        rounded="lg"
                        transition="all 300ms ease-in-out"
                        {...(open ? { transform: "rotate(-45deg) translate(10px, -12px)" } : {})}
                        />
                        </Box>

                    <Box
                        display={{ base: 'none', md: 'flex' }}>
                        <SubMenu title="1. Setup" to="#">
                            <NavLink to="/styles" title="Styles" image="styles.png" />
                            <NavLink to="/stylecolors" title="Style Colors" image="palette.png" />
                            <NavLink to="/stylefamilies" title="Style Families" image="family.png" />                                                     
                            <NavLink to="/markets" title="Markets" image="market.png" />
                        </SubMenu>
                        <SubMenu title="2. Planning" to="#">
                            <NavLink to="/basemodel" title="Basemodel" image="basemodels.png" />                               
                            <NavLink to="/salesplan" title="Salesplan" image="calendar.png" />                            
                            {/*<NavLink to="/marketshares" title="Market Shares" image="pie.png" />*/}
                            <NavLink to="/stylecolorshares" title="Style Color Shares" image="pie.png" />
                        </SubMenu>
                        <SubMenu title="3. Running">
                            {/*<NavLink to="/bulkpositions" title="Bulk Positions" image="actuals.png" />*/}
                            <NavLink to="/uploads" title="Uploads" icon={MdOutlineUploadFile} />
                            <NavLink to="/forecast" title="Forecast" icon={RiTestTubeLine}/>
                            <NavLink to="/moat" title="Moat" image="table.png" />
                        </SubMenu>
                        <SubMenu title="Settings" to="#" image="settings.png">
                            <NavLink to="/settings" title="Settings" image="settings.png" />
                            <NavLink to="/howto" title="How To" image="questionmark.png" />   
                            <NavLink to="/logout" title="Logout" icon={CgLogOut} />
                            {isAuthenticated && <Box px="20px" bg="#EE3B6F" textAlign="center" borderRadius="md" color="white">{sessionStorage.getItem('username')}</Box>}
                        </SubMenu>
                    </Box>
                </Box>
            </Box >
            {/* HACK: This box is here to push down the content below the fixed Navbar!! */}
            <Box pt="80px" bg="red"></Box>

        </>
    )
}
