import DatagridTemplatePage from "./DatagridTemplatePage.tsx";
import { boxClicked } from "./DatagridTemplatePage.tsx";
import useStylesCollection from "../../hooks/useStylesCollection.js";
import useMarketCollection from "../../hooks/useMarketCollection.js";
import useStyleFamilyCollection from "../../hooks/useStyleFamilyCollection.js";
import useStyleColorCollection from "../../hooks/useStyleColorCollection.js";
import useStyleColorSharesCollection from "../../hooks/useStyleColorSharesCollection.js";
import { useRef } from "react";
import { checkboxRenderer, htmlRenderer, textRenderer } from 'handsontable/renderers';
import { getYearAndWeek } from "../../utils/utils.js";

export default function DatagidPageDefs({ functionName }) {
    // Render the component based on the function name
    const renderComponent = () => {
        switch (functionName) {
            case 'marketspage':
                return <Marketspage />;
            case 'stylecolorspage':
                return <Stylecolorspage />;
            case 'stylecolorsharespage':
                return <StylecolorSharespage />;
            case 'stylefamiliespage':
                return <Stylefamiliespage />;
            case 'stylepage':
                return <Stylepage />;
            default:
                return null; // Handle invalid function names or provide a default case
        }
    };

    // Render the component
    return (
        <div>
            {renderComponent()}
        </div>
    );
}

export function Marketspage() {

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        try {

            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            if (col < 2) {
                td.setAttribute("data-cy", `cell_${row}_${col}`);
            }
            if (col === 2) {
                td.setAttribute("data-cy", `datagridpage_markets_checkbox_row_${row}`);
            }

            if (row === 0 && col < 2) {
                cellProperties.readOnly = true;
            }
            // Bold text first row and col
            if (row === 0 || col === 0) {
                td.style.fontWeight = 'bold';
                td.style.color = "darkslategrey";
            }

            // Backgroundcolour first row
            if (row === 0) {
                td.style.backgroundColor = '#f4f4c1';
            }

            // Backgroundcolour first column
            if (row > 0 && col === 0)
                td.style.backgroundColor = '#cbe2f1';



            if (col < 2) textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            if (col === 2) {
                // Add the onchange event listener to the table cell
                td.addEventListener('change', event => boxClicked(event, instance, 2));
                checkboxRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const hotSettings = (data) => {
        // Handsontable settings
        return {
            data: data,
            columns:
                [
                    { data: 0, renderer: customRenderer },
                    { data: 1, renderer: customRenderer },
                    { data: 2, type: 'checkbox', renderer: customRenderer },
                ],

            rowHeaders: false,
            colHeaders: false,
            className: 'handsonTableOverride',
            height: 400,
            width: 300,
            fixedRowsTop: 1,
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: true
            },
        };
    };

    return (
        <DatagridTemplatePage
            urlBase={"/markets/"}
            populatorEndpoint={process.env.REACT_APP_POPULATORMARKETS}
            deleteEndpoint={process.env.REACT_APP_DELETERMARKETS}
            dataWrapperHook={useMarketCollection}
            pageTitle={"Markets overview"}
            filterChoices={{
                showStyleFilter: false,
                showColorFilter: false,
                showYearStartFilter: false,
                showYearEndFilter: false,
                showSeasonFilter: false,
                showMarketFilter: false
            }}
            filterNames={[]}
            deleteLogicPresent={true}
            deleteItemsModalTitle={"Delete markets?"}
            deleteItemsModalText={"Are you sure you want to delete the markets below? This cannot be undone!"}
            deleteItemNamesColsConcat={[0, 1]}
            checkboxColumnIndex={2}
            hotSettings={hotSettings}
        />

    );
}

export function Stylepage() {
    const datagridRef = useRef(null);

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        let rowCount = instance.countRows();
        try {
            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            if (col < 6) {
                td.setAttribute("data-cy", `cell_${row}_${col}`);
            }
            if (col === 6) {
                td.setAttribute("data-cy", `datagridpage_styles_checkbox_row_${row}`);
            }

            // Bold text first row and col
            if (row === 0 || col === 0) {
                td.style.fontWeight = 'bold';
                td.style.color = "darkslategrey";
            }

            // Backgroundcolour first row
            if (row === 0)
                td.style.backgroundColor = '#f4f4c1';

            // Backgroundcolour first column
            if (row > 0 && col === 0)
                td.style.backgroundColor = '#cbe2f1';

            if ((row === 0 && col < 6) || col === 5) cellProperties.readOnly = true;
            if (col < 5) textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            if (col === 5) htmlRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            if (col === 6) {
                // Add the onchange event listener to the table cell
                td.addEventListener('change', event => boxClicked(event, instance, 6));
                checkboxRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }

        } catch (error) {
            console.error(error);
        }
    };

    // Handsontable settings
    const hotSettings = (data) => {
        return {
            data: data,
            columns:
                [
                    { data: 0, renderer: customRenderer },
                    { data: 1, renderer: customRenderer },
                    { data: 2, renderer: customRenderer },
                    { data: 3, renderer: customRenderer },
                    { data: 4, renderer: customRenderer },
                    { data: 5, renderer: customRenderer },
                    { data: 6, type: 'checkbox', renderer: customRenderer },
                ],
            rowHeaders: false,
            colHeaders: false,
            className: 'handsonTableOverride',
            height: 500,
            // Note: setting fixedcolumnsleft to > 0 works fine, but will break cypress tests
            // as automatic entry data entered into col0 by cypress gets wiped on submit 
            fixedColumnsLeft: 0,
            fixedRowsTop: 1,
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: true
            },
        };
    };

    return (
        <DatagridTemplatePage
            urlBase={"/styles/"}
            populatorEndpoint={process.env.REACT_APP_POPULATORSTYLES}
            deleteEndpoint={process.env.REACT_APP_DELETERSTYLES}
            dataWrapperHook={useStylesCollection}
            pageTitle={"Styles overview"}
            filterChoices={{
                showStyleFilter: true,
                showColorFilter: false,
                showYearStartFilter: false,
                showYearEndFilter: false,
                showSeasonFilter: true,
                showMarketFilter: false
            }}
            filterNames={['season', 'stylecode']}
            deleteLogicPresent={true}
            deleteItemsModalTitle={"Delete styles?"}
            deleteItemsModalText={"Are you sure you want to delete the styles below? This cannot be undone!"}
            deleteItemNamesColsConcat={[0, 1, 3]}
            checkboxColumnIndex={6}
            hotSettings={hotSettings}
        />

    );
}

export function Stylefamiliespage() {

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        //let rowCount = hotRefStyleFamiliesData.current.hotInstance.countRows();
        try {
            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            if (col < 3) {
                td.setAttribute("data-cy", `cell_${row}_${col}`);
            }
            if (col === 3) {
                td.setAttribute("data-cy", `datagridpage_stylefamilies_checkbox_row_${row}`);
            }            
            // Bold text first row and col
            if (row === 0 || col === 0) {
                td.style.fontWeight = 'bold';
                td.style.color = "darkslategrey";
            }

            // Backgroundcolour first row
            if (row === 0)
                td.style.backgroundColor = '#f4f4c1';

            // Backgroundcolour first column
            if (row > 0 && col === 0)
                td.style.backgroundColor = '#cbe2f1';

            if ((row === 0 && col < 3) || col === 0) cellProperties.readOnly = true;
            if (col < 3) textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            if (col === 3) {
                // Add the onchange event listener to the table cell
                td.addEventListener('change', event => boxClicked(event, instance, 2));
                checkboxRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const hotSettings = (data) => {
        // Handsontable settings
        return {
            data: data,
            columns:
                [
                    { data: 0, renderer: customRenderer },
                    { data: 1, renderer: customRenderer },
                    { data: 2, renderer: customRenderer },
                    { data: 3, type: 'checkbox', renderer: customRenderer },
                ],
            rowHeaders: false,
            colHeaders: false,
            className: 'handsonTableOverride',
            height: 500,
            fixedRowsTop: 1,
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: true
            },
        };
    };

    return (
        <DatagridTemplatePage
            urlBase={"/stylefamilies/"}
            populatorEndpoint={process.env.REACT_APP_POPULATORSTYLEFAMILIES}
            deleteEndpoint={process.env.REACT_APP_DELETERSTYLEFAMILIES}
            dataWrapperHook={useStyleFamilyCollection}
            pageTitle={"Style families"}
            filterChoices={{
                showStyleFilter: false,
                showColorFilter: false,
                showYearStartFilter: false,
                showYearEndFilter: false,
                showSeasonFilter: false,
                showMarketFilter: false
            }}
            filterNames={['stylecode']}
            deleteLogicPresent={true}
            deleteItemsModalTitle={"Delete style families?"}
            deleteItemsModalText={"Are you sure you want to delete the style families below? This cannot be undone!"}
            deleteItemNamesColsConcat={[0, 1]}
            checkboxColumnIndex={3}
            hotSettings={hotSettings}
        />

    );
}

export function Stylecolorspage() {

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        //let rowCount = hotRefStylesData.current.hotInstance.countRows();
        try {

            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            if (col < 4) {
                td.setAttribute("data-cy", `cell_${row}_${col}`);
            }
            if (col === 4) {
                td.setAttribute("data-cy", `datagridpage_stylecolors_checkbox_row_${row}`);
            }

            // Bold text first row and col
            if (row === 0 || col === 0) {
                td.style.fontWeight = 'bold';
                td.style.color = "darkslategrey";
            }

            // Backgroundcolour first row
            if (row === 0)
                td.style.backgroundColor = '#f4f4c1';

            // Backgroundcolour first column
            if (row > 0 && col === 0)
                td.style.backgroundColor = '#cbe2f1';

            if (row === 0 && col < 4) {
                cellProperties.readOnly = true;
            }
            if (col === 0) td.style.fontWeight = 'bold';
            if (col < 5) textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            if (col === 4) {
                // Add the onchange event listener to the table cell
                td.addEventListener('change', event => boxClicked(event, instance, 4));
                checkboxRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const hotSettings = (data) => {
        // Handsontable settings
        return {
            data: data,
            columns:
                [
                    { data: 0, renderer: customRenderer },
                    { data: 1, renderer: customRenderer },
                    { data: 2, renderer: customRenderer },
                    { data: 3, renderer: customRenderer },
                    { data: 4, type: 'checkbox', renderer: customRenderer },
                ],
            rowHeaders: false,
            colHeaders: false,
            className: 'handsonTableOverride',
            // This is a RIDICULOUS HACK to solve for the fact HOT cannot be given a maxHeight property.
            // Set the height of the table to a multiple of the number items it contains. x31 proved 
            // to give the best result
            height: 60 + data.length * 20,
            width: 650,
            // Note: setting fixedcolumnsleft to > 0 works fine, but will break cypress tests
            // as automatic entry data entered into col0 by cypress gets wiped on submit 
            fixedRowsTop: 1,
            zIndex: 10,
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: true
            },
        };
    };

    return (
        <DatagridTemplatePage
            urlBase={"/stylecolors/"}
            populatorEndpoint={process.env.REACT_APP_POPULATORSTYLECOLORS}
            deleteEndpoint={process.env.REACT_APP_DELETERSTYLECOLORS}
            dataWrapperHook={useStyleColorCollection}
            pageTitle={"Style colors"}
            filterChoices={{
                showStyleFilter: true,
                showColorFilter: false,
                showYearStartFilter: false,
                showYearEndFilter: false,
                showSeasonFilter: false,
                showMarketFilter: false
            }}
            filterNames={['stylecode']}
            deleteLogicPresent={true}
            deleteItemsModalTitle={"Delete style colors?"}
            deleteItemsModalText={"Are you sure you want to delete the style colors below? This cannot be undone!"}
            deleteItemNamesColsConcat={[2, 3]}
            deleteItemCodesColsConcat={[0, 2]}
            checkboxColumnIndex={4}
            crossRefLinks={[["/stylecolorshares/", "Edit color shares"], ["/salesplan/", "View salesplan"], ["/forecast/", "View forecast"]]}
            hotSettings={hotSettings}
        />

    );
}


export function StylecolorSharespage() {
    const thisWeek = getYearAndWeek(new Date());
    let colIndexCurrentWeek = -1;

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        try {
            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            td.setAttribute("data-cy", `cell_${row}_${col}`);

            if (instance) {
                let rowCount = instance.countRows();

                // Set cells which have readonly data in them to non-editable
                // Row 0: Week
                // Col 0: Header for color
                // Col 1: Header for color code
                if ((col === 0) || (col === 1) || (row === 0) || (row === rowCount - 1)) {
                    cellProperties.readOnly = true;
                }

                // Align text right
                if (col > 1)
                    td.style.textAlign = "right";

                // Header rowis lightyellow, bold
                if (((col < 2) && (row > 0) && (row < rowCount - 1)) || (row === 0)) {

                    td.style.backgroundColor = "#f4f4c1";
                    td.style.fontWeight = "bold";
                    td.style.color = "darkslategray";
                }


                // Header cols are lightblue
                if ((col < 2) && (row > 0) && (row < rowCount - 1)) {

                    td.style.backgroundColor = "#cbe2f1";
                    td.style.fontWeight = "bold";
                    td.style.color = "darkslategray";
                }

                // Apply the 'neutral' formatting to the last row
                // and set it to 'text' type to prevent 'validation errors'
                // on the numbers with a % appended

                if (row === rowCount - 1) {
                    td.style.fontWeight = 'bold';
                    td.style.backgroundColor = '#f4f4c1';
                }

                if (value) {
                    // Check if the value is numeric
                    if (!isNaN(value) && col > 1 && row > 0) {
                        // Set the number of decimal places
                        value = Number(value).toFixed(1);
                    }

                    // Apply formatting to the last (sum) row:
                    // Error if not 100%
                    // Valid if 100%
                    // Neutral if 0%
                    if (row === rowCount - 1 && col > 1) {
                        if (value === '100%') {
                            td.style.color = 'white';
                            td.style.backgroundColor = '#20a027';
                        }
                        else if (value === '0%') {
                            td.style.color = 'darkslategrey';
                            td.style.backgroundColor = '#f4f4c1;';
                        }
                        else {
                            td.style.color = 'white';
                            td.style.backgroundColor = '#eb2b2b';
                        }
                        // Remove the validator from the last row, to prevent
                        // the HOT validator applying 'error color' to non-numeric cells
                        cellProperties.validator = null;
                    }
                }

                // Special formatting for the column that corresponds to this week
                if (col === colIndexCurrentWeek) {
                    td.style.borderLeft = "2px solid darkslategrey";
                    td.style.borderRight = "2px solid darkslategrey";
                    td.style.filter = "saturate(300%)";
                    td.style.fontWeight = "bold";
                    td.style.color = "black";
                    if (row === 0)
                        td.style.borderTop = "2px solid darkslategrey";
                    if (row === instance.countRows() - 1)
                        td.style.borderBottom = "2px solid darkslategrey";
                }                

                // Apply the custom rendering               
                textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }
        } catch (error) {
            console.error(error);
        }

    };

    // This approach is used, as Handsontable can apply formatting at the COL level
    // where the data is structured into ROWS. As there can be over 100 COLS of data, specifying each 
    // COL individually becomes messy, so the cols are dynamically generated from the data
    const generateColumns = (data) => {
        if (!data || data.length === 0) {
            return [];
        }
        const firstRow = data[0];

        // Loop through the cells in the first row
        // Mark the column that contains this week's data
        firstRow.forEach((cellValue, col) => {
            if (parseInt(cellValue) === thisWeek)
                colIndexCurrentWeek = col;
        });

        // Get the keys (column names) from the first row
        const keys = Object.keys(firstRow);
        // Generate the columns array dynamically
        const columns = keys.map((key) => {
            return {
                data: key,
                renderer: customRenderer,
            };
        });
        return columns;
    };

    const hotSettings = (data) => {
        // Handsontable settings
        return {
            data: data,
            columns: generateColumns(data),
            rowHeaders: false,
            colHeaders: false,
            className: 'handsonTableOverride',
            // This is a RIDICULOUS HACK to solve for the fact HOT cannot be given a maxHeight property.
            // Set the height of the table to a multiple of the number items it contains. x31 proved 
            // to give the best result
            height: 100 + data.length * 20,
            type: 'numeric',
            // Note: setting fixedcolumnsleft to > 0 works fine, but will break cypress tests
            // as automatic entry data entered into col0 by cypress gets wiped on submit 
            fixedColumnsLeft: 2,
            fillHandle: {
                autoInsertRow: false
            },
        };
    };

    return (
        <DatagridTemplatePage
            urlBase={"/stylecolorshares/"}
            populatorEndpoint={process.env.REACT_APP_POPULATORSTYLECOLORSHARES}
            populatorNeedsStyleCode={true}
            deleteEndpoint={process.env.REACT_APP_DELETERSTYLECOLORSHARES}
            dataWrapperHook={useStyleColorSharesCollection}
            noDataText={"Please add colors for this style before setting the color shares."}
            noStyleChosenText={"Please pick a style to setup the color shares."}
            pageTitle={"Stylecolor Shares"}
            filterChoices={{
                showStyleFilter: true,
                showColorFilter: false,
                showYearStartFilter: false,
                showYearEndFilter: false,
                showSeasonFilter: false,
                showMarketFilter: false
            }}
            filterNames={['stylecode', 'colorcode']}
            deleteLogicPresent={false}
            crossRefLinks={[["/stylecolors/", "Edit colors"], ["/salesplan/", "View salesplan"], ["/forecast/", "View forecast"]]}
            hotSettings={hotSettings}
        />

    );
}