import React, { useEffect } from 'react';
import StylePicker from "./StylePicker";
import WeeknumPicker from './WeeknumPicker';
import ItemPicker from './ItemPicker';
import { Wrap, WrapItem, Center, Button, Box } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { Tooltip } from "@chakra-ui/react";
import { seasonsMapper, marketsMapper, colorsMapper, lookbackavgweeksMapper, businesslineMapper } from "../../utils/dataMappers";

// Filterbar is a container for tne various filters that are uses across different pages. Filters that can be included:

// - StyleMiniSearch
// - Stylecolor, works in conjunction with StyleMiniSearch
// - Weeknumpicker --> Yearstart: to choose the global startweek
// - Weeknumpicker --> Yearend: to choose the global endweek
// - Seasonpicker: filter by season 
// - Market: to filter by market

// Callback context is required for the style filters:
// A. parentprops.styleFilterCallback --> Passed to StyleMiniSearch
// B. parentprops.styleCode --> Passed to StyleMiniSearch
// C. parentprops.styleColorCode --> Passed to StyleColor filter

export default function FilterBar(parentprops) {

    //console.log('[FILTERBAR] parentprops=', parentprops);

    // Filters to be shown are listed in an array of parentprops, structured as follows in a dict:
    // {showStyleFilter:true, showColorFilter: true, showYearStartFilter: false, showYearEndFilter: false, showMarketFilter: true}
    return (
        <Wrap spacing="5px" ml="15px" id="filterbar" data-cy="filterbar">
            {parentprops.filterChoices.showStyleFilter ? (
                <WrapItem zIndex={99}><Center><StylePicker callback={parentprops.stylecodeFilterCallback} defaultvalue={"Select style"} styleCode={parentprops.stylecode} /></Center></WrapItem>
            ) : null}
            {parentprops.filterChoices.showColorFilter ? (
                <WrapItem zIndex={98}><Center><ItemPicker callback={parentprops.colorcodeFilterCallback} filterKey="colorcode" mapperFunc={colorsMapper} defaultvalue={"Select color"} itemselected={parentprops.colorcode && parentprops.colorcode} dataEndpoint={process.env.REACT_APP_ENDPOINTSTYLECOLORS + '?stylecode=' + ((parentprops.stylecode !== null && parentprops.stylecode !== undefined && parentprops.stylecode !== "") ? parentprops.stylecode : -99)} /></Center></WrapItem>
            ) : null}
            {parentprops.filterChoices.showSeasonFilter ? (
                <WrapItem zIndex={97}><Center><ItemPicker callback={parentprops.seasonFilterCallback} filterKey="season" mapperFunc={seasonsMapper} defaultvalue={"Select season"} itemselected={parentprops.season && parentprops.season} dataEndpoint={process.env.REACT_APP_ENDPOINTSEASONS} /></Center></WrapItem>
            ) : null}
            {parentprops.filterChoices.showBusinesslineFilter ? (
                <WrapItem zIndex={96}><Center><ItemPicker callback={parentprops.businesslineFilterCallback} filterKey="businessline" mapperFunc={businesslineMapper} defaultvalue={"Businessline"} itemselected={parentprops.businessline && parentprops.businessline} dataEndpoint={process.env.REACT_APP_ENDPOINTBUSINESSLINES} /></Center></WrapItem>
            ) : null}            
            {parentprops.filterChoices.showLookbackweeksFilter ? (
                <WrapItem zIndex={95}><Center>{parentprops.lookbackweeksFieldTitle && <Box color="blue.500" mx="5px"> {parentprops.lookbackweeksFieldTitle}</Box>}<ItemPicker callback={parentprops.lookbackweeksFilterCallback} filterKey="lookbackavgweeks" mapperFunc={lookbackavgweeksMapper} defaultvalue={"Select"} itemselected={parentprops.lookbackavgweeks && parentprops.lookbackavgweeks} dataEndpoint={process.env.REACT_APP_ENDPOINTLOOKBACKWEEKS} omitAllSelector={true} /></Center></WrapItem>
            ) : null}
            {parentprops.filterChoices.showYearStartFilter ? (
                <WrapItem zIndex={94}><Center><WeeknumPicker callback={parentprops.startWeekFilterCallback} filterKey="weekfilter_start" defaultvalue={"Select startweek"} seedYear={parentprops.seedStartYear} weekNum={parentprops.weekNumStart} buttonId={"startWeekButton"} /></Center></WrapItem>
            ) : null}
            {parentprops.filterChoices.showYearEndFilter ? (
                <WrapItem zIndex={93}><Center><WeeknumPicker callback={parentprops.endWeekFilterCallback} filterKey="weekfilter_end" defaultvalue={"Select endweek"} seedYear={parentprops.seedEndYear} weekNum={parentprops.weekNumEnd} buttonId={"endWeekButton"} /></Center></WrapItem>
            ) : null}
            {parentprops.filterChoices.showMarketFilter ? (
                <WrapItem zIndex={92}><Center><ItemPicker callback={parentprops.marketFilterCallback} filterKey="market" mapperFunc={marketsMapper} defaultvalue={"Select market"} itemselected={parentprops.market && parentprops.market} dataEndpoint={process.env.REACT_APP_ENDPOINTMARKETS} /></Center></WrapItem>
            ) : null}
            {(parentprops.filterChoices.showStyleFilter || parentprops.filterChoices.showColorFilter ||
                parentprops.filterChoices.showSeasonFilter || parentprops.filterChoices.showYearStartFilter ||
                parentprops.filterChoices.showYearEndFilter || parentprops.filterChoices.showMarketFilter ||
                parentprops.filterChoices.showLookbackweeksFilter || parentprops.showBusinesslineFilter) && (
                <WrapItem data-cy="clearfilter_button">
                        <Center>
                            <Tooltip label="Clear filters" bg="blue.50" color="blue.500" border="1px solid hotpink" placement="top-end" hasArrow >
                                <Button height="38px" width="38px" _hover={{ color: "hotpink", backgroundColor: "blue.50", border: "1px solid hotpink", }} alt="Clear filters" colorScheme="blue" variant="solid" onClick={parentprops.clearFilters}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                        </Center>
                    </WrapItem>
                )}
        </Wrap>
    );
};
