import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect } from 'react';

import axios from "axios";
import Login from "./views/auth/Login";
import Logout from "./views/auth/Logout";
import Protectedroute from "./views/auth/Protectedroute";
import Salesplan from "./views/app/Salesplan";
import Basemodel from "./views/app/Basemodel";
import ToDo from "./views/app/ToDo";
import Settings from "./views/app/Settings";
import Uploads from "./views/app/Uploads";
import Forecast  from "./views/app/Forecast";
import Howto from "./views/app/Howto";
import DatagidPageDefs from "./views/app/DatagidPageDefs";
import MoatPage from "./views/app/MoatPage";
import Navbar from "./components/nav/Navbar";
import Footerbar from "./components/pageblocks/Footerbar";

import './css/app.css';

function App() {
  //console.log(window.location.href)
  // NOTE: there is still a mix of axios and SWR calls made in the application
  // Todo: migrate all to SWR
  // SWR uses the useAuthSWR hook to globally set the auth header (token) retrieved from the session storage
  // Retrieve the token from the session storage and set it as the auth token
  // in the header of all axios calls

  const token = sessionStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    // Attach an event listener to check if the user is still logged in with a valid auth token 
  }
  else {
    axios.defaults.headers.common['Authorization'] = null;
  }

  useEffect(() => {

    // Attach click event listener to the application,
    // that checks the token validity on each click
    document.addEventListener('click', handleAuthentication);

    // Set an interval to perform the token expiration 
    // check automatically every 5 minutes (300 seconds)
    const interval = setInterval(handleAuthentication, 300 * 1000);

    // Clean up the event listener and interval on component unmount
    return () => {
      document.removeEventListener('click', handleAuthentication);
      clearInterval(interval);
    };
  }, []);


  const handleAuthentication = async () => {
    console.log('authcheck');
    const path = window.location.pathname;
    //console.log(path);

    // Do a lightweight lookup to check that the user is still logged in with a valid token
    // a 401 indicates the session has expired (or the the token is not valid)

    const isRootPage = (path === '/');
    const isLoginPage = path.includes('/login');
    const isLogoutPage = path.includes('/logout');
    let authFail = true;
    if (!isLogoutPage && !isLoginPage && !isRootPage) {
      try {
        const response = await axios.get(process.env.REACT_APP_ENDPOINTSEASONS + "?d=" + new Date().getTime());
        authFail = false;
      }
      catch (e) {
        authFail = true;

      }
      console.log('auth=', !authFail);
      //console.log('tinvalid=', authFail);
      // If not authenticated and trying to access a protected route, redirect to login
      if (authFail) {
        const url = "/login?hitunauthorizedurl=true&next=" + window.location.pathname;
        window.location.href = url;
      }
    }
  };

  //const location = useLocation();
  // Hacky, but the params are always in this order: <page>/<stylecode>/<colorcode>
  const pathname = window.location.pathname;
  const stylecode = pathname.split('/')[2];
  const colorcode = pathname.split('/')[3];

  return (
    /*<Profiler id="stockmanagerApp" onRender={onRender}>*/
    <>

      <BrowserRouter>
        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

            {/* 1. Setup  */}

          <Route path="/styles" element={<Protectedroute next="/styles"><DatagidPageDefs functionName="stylepage" /></Protectedroute>} />
          <Route path="/styles/:stylecode" element={<Protectedroute next={`/styles/${stylecode}`}><DatagidPageDefs functionName="stylepage" /></Protectedroute>} />
          <Route path="/stylecolors" element={<Protectedroute next="/stylecolors"><DatagidPageDefs functionName="stylecolorspage" /></Protectedroute>} />
          <Route path="/stylecolors/:stylecode" element={<Protectedroute next={`/stylecolors/${stylecode}`}><DatagidPageDefs functionName="stylecolorspage" /></Protectedroute>} />
          <Route path="/stylecolors/:stylecode/:colorcode" element={<Protectedroute next={`/stylecolors/${stylecode}/${colorcode}`}><DatagidPageDefs functionName="stylecolorspage" /></Protectedroute>} />
          <Route path="/stylefamilies/" element={<Protectedroute next="/stylefamilies"><DatagidPageDefs functionName="stylefamiliespage" /></Protectedroute>} />
          <Route path="/markets" element={<Protectedroute next="/markets"><DatagidPageDefs functionName="marketspage" /></Protectedroute>} />
            
            {/* 2. Planning  */}
          <Route path="/basemodel" element={<Protectedroute next="/basemodel"><Basemodel /></Protectedroute>} />

          <Route path="/salesplan" element={<Protectedroute next="/salesplan"><Salesplan /></Protectedroute>} />
          <Route path="/salesplan/:stylecode" element={<Protectedroute next={`/salesplan/${stylecode}`}><Salesplan /></Protectedroute>} />
          <Route path="/salesplan/:stylecode/:colorcode" element={<Protectedroute next={`/salesplan/${stylecode}/${colorcode}`}><Salesplan /></Protectedroute>} />

          <Route path="/stylecolorshares" element={<Protectedroute next="/stylecolorshares"><DatagidPageDefs functionName="stylecolorsharespage" /></Protectedroute>} />
          <Route path="/stylecolorshares/:stylecode" element={<Protectedroute next={`/stylecolorshares/${stylecode}`}><DatagidPageDefs functionName="stylecolorsharespage" /></Protectedroute>} />
          <Route path="/stylecolorshares/:stylecode/:colorcode" element={<Protectedroute next={`/stylecolorshares/${stylecode}/${colorcode}`}><DatagidPageDefs functionName="stylecolorsharespage" /></Protectedroute>} />            

            {/* 3. Running  */}
          <Route path="/uploads" element={<Protectedroute next="/uploads"><Uploads /></Protectedroute>} />
          <Route path="/forecast" element={<Protectedroute next="/forecast"><Forecast /></Protectedroute>} />
          <Route path="/forecast/:stylecode" element={<Protectedroute next={`/forecast/${stylecode}`}><Forecast /></Protectedroute>} />
          <Route path="/forecast/:stylecode/:colorcode" element={<Protectedroute next={`/forecast/${stylecode}/${colorcode}`}><Forecast /></Protectedroute>} />
          <Route path="/moat" element={<Protectedroute next="/moat"><MoatPage /></Protectedroute>} />
          <Route path="/moat/:stylecode" element={<Protectedroute next={`/moat/${stylecode}`}><MoatPage /></Protectedroute>} />            

            {/* 4. Other */}                  
          <Route path="/howto" element={<Protectedroute next="/howto"><Howto /></Protectedroute>} />
          <Route path="/todo" element={<Protectedroute next="/todo"><ToDo /></Protectedroute>} />
          <Route path="/settings" element={<Protectedroute next="/settings"><Settings /></Protectedroute>} />   
          {/* Other routes go to Login */}
          <Route path="*" element={<Navigate to="/" replace />} />                    
          </Routes>

      </BrowserRouter>
      <Footerbar />
    </>
    /*</Profiler>*/

  );
}
export default App;
