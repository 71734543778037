import React, { useRef, useEffect } from 'react';
import { Box } from '@chakra-ui/react'
const Counter = React.forwardRef((props, ref) => {
    const counterRef = useRef(0);

    const formatCounter = (value) => {
        const seconds = Math.floor(value / 10);
        const hundredths = value % 100;
        return `${seconds}.${hundredths}`;
    };

    const updateCounter = () => {
        counterRef.current += 1;
        const formattedValue = formatCounter(counterRef.current);
        ref.current = formattedValue; // Update the ref value in the parent component
    };

    useEffect(() => {
        const interval = setInterval(() => {
            updateCounter();
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <Box fontSize="20px">
            {props.label} {formatCounter(counterRef.current)}
        </Box>
    );
});

export default Counter;
