import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Box, Spinner } from '@chakra-ui/react'
import Counter from "../components/controls/Counter";

const FetchFromMultipleEndpoints = (props) => {
    //console.log('[SETTINGS] props=', props);
    const [datasets, setDatasets] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const endPointOne = process.env.REACT_APP_ENDPOINTSTYLECOLORS + '?' + new URLSearchParams(props.filters);
        const endPointTwo = process.env.REACT_APP_ENDPOINTSALESPARAMS + '?' + new URLSearchParams(props.filters);
        const endPointThree = process.env.REACT_APP_ENDPOINTSTYLECOLORSHARES + '?' + new URLSearchParams(props.filters);

        axios.all(
            [
                axios.get(endPointOne),
                axios.get(endPointTwo),
                axios.get(endPointThree)
            ])
            .then(axios.spread((response1, response2, response3) => {
                const returnedData = {};
                returnedData['set1'] = response1.data;
                returnedData['set2'] = response2.data;
                returnedData['set3'] = response3.data;
                setDatasets(returnedData);
                setLoading(false);

                //console.log('[SETTINGS] set 1=', returnedData.set1);
                //console.log('[SETTINGS] set 2=', returnedData.set2);
                //console.log('[SETTINGS] set 3=', returnedData.set3);

            }));

    }, [props.filters]);

    const counterValueRef = useRef(0);
    const [counterValue, setCounterValue] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            setCounterValue(counterValueRef.current);
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            {!loading ?
                <Box p="5px">
                    <Box>Load complete at {counterValueRef.current}s</Box>
                    <Box fontWeight="bold" >Set 1:</Box>
                    <Box maxH="200px" overflow="auto" my="10px">{JSON.stringify(datasets.set1)}</Box>
                    <Box fontWeight="bold" >Set 2:</Box>
                    <Box maxH="200px" overflow="auto" my="10px">{JSON.stringify(datasets.set2)}</Box>
                    <Box fontWeight="bold" >Set 3:</Box>
                    <Box maxH="200px" overflow="auto" my="10px">{JSON.stringify(datasets.set3)}</Box>

                </Box>

                :
                <>
                    <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                    <Counter ref={counterValueRef} />

                </>
            }
        </>
    )
};

export default FetchFromMultipleEndpoints;