import { useState, useEffect } from 'react';
import axios from 'axios';
import useDataSource from './useDataSource';
import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";
// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');

const useStylesCollection = (filters, triggerUpdate = null) => {
    const [dataParent, setDataParent] = useState([]);
    const [dataChild, setDataChild] = useDataSource(process.env.REACT_APP_ENDPOINTSTYLES, filters, true, triggerUpdate);
    const [loading, setLoading] = useState(true);
        
    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    //useWhatChanged([dataChild, triggerUpdate]); // debugs the below useEffect    
  
    useEffect(() => {
        let dataIsFresh = true;
        
        const fetchData = async () => {
            
            try {
            // Extract the data. The below assumes the data is structred into an array of dicts:
            /* [
                    {
                        "name": "Ghost 15",
                        "code": "110393",
                        "introseason": "F2022",
                        "family": 5,
                        "display_family": "Ghost",
                        "gender": "M"
                    },
                    ...
            */
            if (dataChild) {
                let gridVals = [];
                // Fill the first element of the gridVals array with a row of column headers  
                gridVals[0] = ["Code", "Name", "Family", "Gender", "Introseason", "Salesplan"];
                    dataChild.forEach((item) => {
                        var linkHTML = '<a class="handsontableLink" href="/salesplan/' + item.code + '">Salesplan ' + item.code + '</a>';
                        // The last two columns are filled with additional helpers:
                        // Column with a link to click through to the salesplan page
                        // Column with a checkbox, to select the item 
                        gridVals.push([item.code, item.name, item.display_family, item.gender, item.display_introseason, linkHTML, false]);
                    });

                // Populate the grid with data 
                // Prevent stale data return
                if (dataIsFresh) {
                    setDataParent(gridVals);
                    setLoading(false);
                }                
 
            }
        }
        catch (error) {
            console.error(error);
        }
    };        
        fetchData();
        // Set the active flag to false to prevent stale data return
        // from race conditions
        return () => { dataIsFresh = false};        
    }, [dataChild, triggerUpdate]);
    return [dataParent, setDataParent, loading, setLoading]

};

export default useStylesCollection;