import React, { useEffect, useRef, useState } from 'react';
import { Button, UnorderedList, ListItem, Box } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
//import axios from 'axios';
import { weeksInYear } from "../../utils/utils";


export default function WeeknumPicker(parentprops) {
    const [yearSelected, setYearSelected] = useState(parentprops.weekNum && parentprops.weekNum.toString().substring(0, 4));
    const [weekSelected, setWeekSelected] = useState(parentprops.weekNum && parentprops.weekNum.toString().substring(4, 6));
    const [weekNumber, setWeekNumber] = useState(parentprops.weekNum);
    const [yearOptions, setYearOptions] = useState();
    const [weekOptions, setWeekOptions] = useState();
    const [dataFetched, setDataFetched] = useState(false);
    const [listVisible, setListVisible] = useState(false);
    const listRefYears = useRef(null);
    const listRefWeeks = useRef(null);
    const buttonRef = useRef(null);
    const listcontainerYearsRef = useRef(null);
    const listcontainerWeeksRef = useRef(null);

    useEffect(() => {
        //console.log('[WEEKNUMPICKER] Triggered');
        fetchOptions();
        setWeekNumber(parentprops.weekNum);
        setYearSelected(parentprops.weekNum && parentprops.weekNum.toString().substring(0, 4));
        setWeekSelected(parentprops.weekNum && parentprops.weekNum.toString().substring(4, 6));

        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('click', handleClickOutsideListBox);
        return () => {
            document.removeEventListener('click', handleClickOutsideListBox);
        }

    }, [parentprops.weekNum]);

    const fetchOptions = () => {
        //console.log('[WEEKNUMPICKER] Fetching options');
        //console.log('[WEEKNUMPICKER] weeknum=', parentprops.weekNum);
        //console.log('[WEEKNUMPICKER] weekNumber=', weekNumber);
        try {
            fillYears(parentprops.seedYear);
            fillWeeks(parentprops.seedYear);
            setDataFetched(true);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const fillYears = (seedYear) => {
        try {
            const yearItems = [];
            for (var i = seedYear - 5; i < seedYear + 20; i++)
                yearItems.push(i);
            setYearOptions({ items: yearItems });
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const fillWeeks = (year) => {
        try {
            const weekItems = [];
            for (var i = 1; i <= weeksInYear(year); i++)
                weekItems.push(i.toString().padStart(2, '0'));
            setWeekOptions({ items: weekItems });
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    // Hide the listbox if a click is done outside its borders
    const handleClickOutsideListBox = (event) => {
        try {
            const listYears = listRefYears.current;
            const listWeeks = listRefWeeks.current;
            const listcontainerYears = listcontainerYearsRef.current;
            const listcontainerWeeks = listcontainerWeeksRef.current;
            const button = buttonRef.current;
            if (listYears && listWeeks && button && !listYears.contains(event.target) && !listWeeks.contains(event.target)
                && !listcontainerYears.contains(event.target) && !listcontainerWeeks.contains(event.target) && !button.contains(event.target)) {
                setListVisible(false);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const yearSelectedHandler = (event) => {
        try {
            // Highlight the selected year
            const year = event.target.innerHTML;
            var weeknum = year
            if (weekSelected)
                weeknum += weekSelected;
            else
                weeknum += "01";
            setYearSelected(year);
            console.log('year=', year);
            setWeekNumber(weeknum);

            // Update the number of weeks for the year selected
            fillWeeks(year);

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const weekSelectedHandler = (event) => {
        try {
            // Highlight the selected year
            const week = event.target.innerHTML;
            const weeknum = yearSelected + week;
            setWeekSelected(week);
            setWeekNumber(weeknum);

        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const renderyears = () => {
        try {
            const { items } = yearOptions;
            if (items.length === 0) {
                return (<UnorderedList ref={listRefYears}
                    _before={{
                        content: "''",
                        zIndex: 10,
                        maxHeight: 300,
                        overflow: "auto"
                    }}
                    listStyleType="none"
                    p={1}
                ></UnorderedList>);
            }
            return (
                <UnorderedList ref={listRefYears}
                    _before={{
                        content: "''",
                        zIndex: 10,
                        maxHeight: 300,
                        overflow: "auto"
                    }}
                    listStyleType="none"

                    alignContent="center"
                    margin="0px"
                    p="0px"
                >
                    {items.map(keyval =>
                        <ListItem
                            data-cy={`weeknumpicker_year_element_${parentprops.filterKey}_${keyval}`}
                            tabIndex={-1}
                            key={keyval}
                            onClick={yearSelectedHandler}
                            bg={parseInt(yearSelected) === parseInt(keyval) ? "blue.500" : "transparent"}
                            color={parseInt(yearSelected) === parseInt(keyval) ? "white" : "black"}
                            _hover={{
                                bgColor: "blue.500",
                                color: "white"
                            }}
                            mx="3px"
                            pl="10px"
                            borderRadius="md"
                            cursor="pointer"
                        >
                            {keyval}
                        </ListItem>)}
                </UnorderedList>
            )
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const renderweeks = () => {
        try {
            const { items } = weekOptions;
            if (items.length === 0) {
                return (<UnorderedList ref={listRefWeeks}
                    _before={{
                        content: "''",
                        zIndex: 10,
                        maxHeight: 300,
                        overflow: "auto"
                    }}
                    listStyleType="none"
                    p={1}
                ></UnorderedList>);
            }
            return (
                <UnorderedList
                    ref={listRefWeeks}
                    _before={{
                        content: "''",
                        zIndex: 10,
                        maxHeight: 300,
                        overflow: "auto"
                    }}
                    listStyleType="none"
                    w="40px"
                    p={1}
                >
                    {items.map(keyval =>
                        <ListItem
                            data-cy={`weeknumpicker_week_element_${parentprops.filterKey}_${keyval}`}
                            tabIndex={-1}
                            key={keyval}
                            onClick={weekSelectedHandler}
                            bg={weekSelected === keyval ? "blue.500" : "transparent"}
                            color={weekSelected === keyval ? "white" : "black"}
                            _hover={{
                                bgColor: "blue.500",
                                color: "white"
                            }}
                            ml="-15px"
                            pl="13px"
                            borderRadius="md"
                            cursor="pointer"
                        >{keyval}</ListItem>)}
                </UnorderedList>
            )
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const boxClicked = () => {
        if (listVisible)
            setListVisible(false)
        else
           setListVisible(true)
    }


    const applyClicked = (event) => {
        try {
            // Stop the event propagation, 
            event.stopPropagation();

            // Close the panel with the year and week selectors
            setListVisible(false)

            // Callback the new value as a key-value pair,
            // as it will be consumed by in this way
            // The name of the key to callback with is passed in
            // from the parent as the 'filterKey' prop
            const dict = {};
            dict[parentprops.filterKey] = parseInt(weekNumber);
            parentprops.callback(dict);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    return (
        <Box borderRadius="5px" zIndex="20" data-cy={`weeknumfilter_${parentprops.filterKey}`}>
            <Button data-cy={`weeknumfilter_${parentprops.filterKey}_button_select`} colorScheme="blue" variant="outline" onClick={boxClicked} ref={buttonRef} tabIndex={-1} data-weeknum={weekNumber}
                height="38px" fontWeight="medium"
            >{weekNumber}&nbsp;<ChevronDownIcon transform={listVisible ? "rotate(180deg)" : ""} transition="300ms"/>
            </Button>
            {listVisible ? (
                <>
                    <Box
                        marginTop="3px"
                        paddingTop="3px"
                        width="150px"
                        border="1px solid #3182CE"
                        display="flex"
                        flexWrap="wrap"
                        backgroundColor="#EBF8FF"
                        zIndex="20"
                        borderRadius="5px"
                        position="absolute"
                    >
                        <Box
                            flexBasis="50%"
                            textAlign="center"
                            fontWeight="bold"
                            color="#2B6CB0"
                            paddingBottom="5px"
                        >
                            Year
                        </Box>
                        <Box
                            flexBasis="50%"
                            textAlign="center"
                            fontWeight="bold"
                            color="#2B6CB0"
                            paddingBottom="5px"
                        >
                            Week
                        </Box>
                        <Box ref={listcontainerYearsRef}
                            flex="0 0 52%"
                            maxHeight="250px"
                            overflow="auto"
                            borderRadius="5px"
                            backgroundColor="#EBF8FF"
                        >
                            {dataFetched ? (renderyears()) : null}
                        </Box>
                        <Box ref={listcontainerWeeksRef}
                            flex="0 0 46%"
                            maxHeight="250px"
                            overflow="auto"
                            borderRadius="5px"
                            backgroundColor="#EBF8FF"
                        >
                            {dataFetched ? (renderweeks()) : null}
                        </Box>
                        <br />
                        <Box
                            data-cy={`weeknumpicker_button_apply_${parentprops.filterKey}`}
                            id={parentprops.buttonId}
                            onClick={applyClicked}
                            flexBasis="100%"
                            borderRadius="5px"
                            backgroundColor="#3182CE"
                            color="white"
                            padding="3px"
                            margin="5px"
                            cursor="pointer"
                            textAlign="center"
                        >
                            Apply
                        </Box>
                    </Box>
                </>) : null}
        </Box>
    );
}



