// 3rd party and framework
import React, { useState } from 'react';

// Helpers
import { getYearAndWeek } from "../../utils/utils";

// Handsontable imports and initialization
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import { textRenderer } from 'handsontable/renderers';

// CSS
import '../../css/app.css';

registerAllModules();

const SalesplanTable = React.forwardRef((parentprops, ref) => {
    const [populatorUrl, setPopulatorUrl] = useState(process.env.REACT_APP_POPULATORSALESACTUALS);
    const thisWeek = getYearAndWeek(new Date());
    let colIndexCurrentWeek = -1;

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        try {

            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            td.setAttribute('data-cy', `cell_${row}_${col}`);

            // First row: bold, light=yellow
            if (row === 0) {
                td.style.backgroundColor = "#f4f4c1";
                td.style.fontWeight = "bold";
                td.style.color = "darkslategray";
            }

            // Second row for the colorcodes are lightblue
            if ((col < 1) && (row > 0)) {

                td.style.backgroundColor = "#cbe2f1";
                td.style.fontWeight = "bold";
                td.style.color = "darkslategray";
            }

            //  Secon and third row for the multipliers
            if ((row === 1) || (row === 2)) {

                td.style.backgroundColor = "#a6d3aa";
                td.style.fontStyle = "italic";
                td.style.color = "darkslategray";
            }

            // Plan row: pink background
            if (row === 9) {
                td.style.backgroundColor = "#f1bcd6";
                td.style.fontWeight = "bold";
                td.style.color = "darkslategray";
            }

            // All numbers right-aligned
            if (col > 0) {
                td.style.textAlign = "right";
            }

            // Set cells which have readonly data in them to non-eitable
            // Row 0: Week
            // Row 1,2: Basemodel
            // Row 4: Campaign pressure
            // Row 6: Discount multiplier
            // Row 9: Plan (this is calculated)            
            // Row 10: Active/Inactive
            // Column 0: the field names
            if ((row === 0) || (row === 1) || (row === 2) || (row === 4) || (row === 6) || (row === 9)  || (row === 10)  ||
                (col === 0)) {
                cellProperties.readOnly = true;
            }

            // Render numeric cell values as a 3-decimal number, for the cells 
            // containing the multipliers and the shares (rows 1, 2, 4, 6)
            if (value && (row === 1 || row === 2 || row === 4 || row === 6)) {
                // Check if the value is numeric
                if (!isNaN(value) && col > 0) {
                    // Set the number of decimal places
                    value = Number(value).toFixed(3);
                }
            }

            // Render numeric cell values as an integer value, for the cells 
            // containing the units (row 9)
            if (value && (row === 9)) {
                // Check if the value is numeric
                if (!isNaN(value) && col > 0) {
                    // Set the number of decimal places
                    value = Number(value).toFixed(0);
                }
            }

            // Special formatting for the column that corresponds to this week
            if (col === colIndexCurrentWeek) {
                td.style.borderLeft = "2px solid darkslategrey";
                td.style.borderRight = "2px solid darkslategrey";
                td.style.filter = "saturate(300%)";
                td.style.fontWeight = "bold";
                td.style.color = "black";
                if (row === 0)
                    td.style.borderTop = "2px solid darkslategrey";
                if (row === instance.countRows() - 1)
                    td.style.borderBottom = "2px solid darkslategrey";
            }
            // Apply the custom rendering
            textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

        } catch (error) {
            console.error(error);
        }
    };

    // This approach is used, as Handsontable can apply formatting at the COL level
    // where the data is structured into ROWS. As there are ~54 COLS of data, specifying each 
    // COL individually becomes messy, so the cols are dynamically generated from the data
    const generateColumns = (data) => {
        //console.log('[SALESPLANTABLE] data=', data);

        if (!data || data.length === 0) {
            return [];
        }
        // Get the first row of data
        const firstRow = data[0];

        // Get the keys (column names) from the first row
        const keys = Object.keys(firstRow);

        // Loop through the cells in the first row
        // Mark the column that contains this week's data
        firstRow.forEach((cellValue, col) => {
            if (cellValue === thisWeek)
                colIndexCurrentWeek = col;
        });        

        // Generate the columns array dynamically
        const columns = keys.map((key) => {
            return {
                data: key,
                renderer: customRenderer,
            };
        });

        return columns;
    };

    const settings = (data) => {
        return {
            data: data,
            columns: generateColumns(data),
            className: 'handsonTableOverride',
            height: 300,
            fixedColumnsLeft: 1,
            rowHeaders: false,
            colHeaders: false,
            type: 'numeric',

        }
    };

    return (<HotTable
        id="hot_table_salesplan" 
        ref={ref}
        settings={settings(parentprops.data)}
        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
    />)
});

export default SalesplanTable;