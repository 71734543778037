import { useState, useEffect } from 'react';
import useDataSource from './useDataSource';

import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";
// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');

const useStyleFamilyCollection = (filters = null, triggerUpdate = null) => {
    const [dataParent, setDataParent] = useState([]);
    const [loading, setLoading] = useState(true);    
    const [dataChild, setDataChild] = useDataSource(process.env.REACT_APP_ENDPOINTSTYLEFAMILIES, null, true, triggerUpdate);


    const fetchData = async () => {
        try {

            if (dataChild) {
                let gridVals = [];

                // Fill the first element of the gridVals array with a row of column headers  
                gridVals[0] = ["ID", "Name", "Businessline"];
                dataChild.forEach((item) => {
                    // The last column is filled with additional helpers:
                    // Column with a checkbox, to select the item 
                    gridVals.push([item.id, item.name, item.display_businessline, false]);
                });
                // Populate the grid with data 
                setDataParent(gridVals);
                setLoading(false);

            }
        }
        catch (error) {
            console.error(error);
        }
    };
    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    //useWhatChanged([dataChild, triggerUpdate]); // debugs the below useEffect        
    
    useEffect(() => {
        fetchData();
    }, [dataChild, triggerUpdate]);
    return [dataParent, setDataParent, loading, setLoading];
};

export default useStyleFamilyCollection;