import { Button, Box } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { Wrap, WrapItem, Center, Flex } from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useState } from 'react'



export default function ConfirmDeleteItemsModal(parentprops) {
    const [isOpen, setIsopen] = useState(true);
    const listItems = (items) => {
        items.map()

    }

    //const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Modal isOpen={true} data-cy="modal_confirm_deleteitems">
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>
                <Wrap spacing="2px" flexwrap="nowrap">
                    <WrapItem minW={200}><Center>{parentprops.title}</Center></WrapItem>
                    <WrapItem flex="1" alignItems="right" ><Flex justify="flex-end" width="100%"><CloseIcon onClick={() => parentprops.callback(false, false)} cursor={"pointer"}/></Flex></WrapItem>
                </Wrap>
            </ModalHeader>
            
            <ModalBody p={5} mx={6} borderRadius="md" bg="#d8f0f0" fontSize={14}>
            
                {parentprops.text}<br /><br />
                {parentprops.items ? "These items will be deleted:" : null}
                {parentprops.items ? parentprops.items.map(keyval => <Box fontWeight={'bold'} color="blue.500" key={keyval}>{keyval}</Box>): null}
            </ModalBody>
            <ModalFooter>
                <Wrap spacing="3px" flexwrap="nowrap">
                        <WrapItem><Center><Button data-cy="modal_confirm_deleteitems_button_yes" minW={100} colorScheme="red" onClick={() => parentprops.callback(false, true)}>Yes</Button></Center></WrapItem>
                        <WrapItem><Center><Button data-cy="modal_confirm_deleteitems_button_no" minW={100} colorScheme="blue" variant="outline" onClick={() => parentprops.callback(false, false)}>No</Button></Center></WrapItem>
                </Wrap>
            </ModalFooter>
            </ModalContent>
      </Modal>
    );
}
