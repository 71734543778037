import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { isEqual } from 'lodash';
import { weeksInYear } from '../utils/utils';
import useDataSource from './useDataSource';

import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";

// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');

const useStyleColorSharesCollection = (filters, triggerUpdate = null) => {

    const [dataOut, setDataOut] = useState([]);
    const [loading, setLoading] = useState(true);
    const [styleColorData, setStyleColorData] = useDataSource(process.env.REACT_APP_ENDPOINTSTYLECOLORS, filters, false, triggerUpdate);
    const [salesParamsData, setSalesParamsData] = useDataSource(process.env.REACT_APP_ENDPOINTSALESPARAMS, filters, false, triggerUpdate);
    const [seasonData, setSeasonData] = useDataSource(process.env.REACT_APP_ENDPOINTSEASONS, null, true, triggerUpdate);

    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    //useWhatChanged([filters, styleColorData, salesParamsData, seasonData, triggerUpdate]); // debugs the below useEffect    
    useEffect(() => {

        let dataIsFresh = true;

        // fetchData requires multiple passes, triggered by state changes in the vars above,
        // to read in the data from different sources. The vars above are in the dependency array
        const fetchData = async () => {
            try {
                              
                // Get the introweek and outroweek for salesplan
                let weekStart, weekEnd;
                
                // Extract the startweek and endweek from the sales params
                if (salesParamsData.length !== 0) {
                    weekStart = parseInt(salesParamsData[0].startweek);
                    weekEnd = parseInt(salesParamsData[0].endweek);
                }
                // If the sales params are not filled, get the startweek and endweek
                // from the season
                else {
                    if (styleColorData.length !== 0) {
                        // Loop through the season data and for this style's introseason
                        // read the start and endweek
                        if (seasonData.length > 0) {
                            for (const item of seasonData) {
                                if (item.name === styleColorData[0].display_introseason) {
                                    weekStart = item['startweek'];
                                    weekEnd = item['endweek'];
                                    break;
                                }
                            }
                        }
                    }
                }
                // The gridvals object is an array of arrays, with each array 'row'
                // holding objects as its elements
                // First row: headers for the columns
                // Rows 2...n:
                //   Col 1: colorname
                //   Col 2: colorcode
                //   Col 3....n: week shares for the colors
                // Last row: totals
                const gridVals = [[]];

                if (!(weekStart && weekEnd && styleColorData)) {
                    setDataOut([]);
                    setLoading(false);
                    return;
                }
                else {


                    let url = process.env.REACT_APP_ENDPOINTSTYLECOLORSHARES;
                    // Apply filters 
                    if (filters['stylecode'] || weekEnd || weekStart) {
                        const params = {};
                        if (filters['stylecode']) params.stylecode = filters['stylecode'];
                        if (weekStart) params.weekfilter_start = weekStart;
                        if (weekEnd) params.weekfilter_end = weekEnd;
                        url += '?' + new URLSearchParams(params);
                    }

                    axios.get(url)
                        .then((getResponse) => {

                            // Extract the data. The below assumes the data is structred into an array of dicts:
                            // The below also assumes:
                            // - A single year of data is retrieved in a call
                            // - Data for multiple colors can be in the set
                            /* [
                                {
                                    "display_style": "110317",
                                    "display_colorcode": "028",
                                    "display_colorname": "Blackened Pearl/Blue/Black",
                                    "share": 2.0,
                                    "week": "202301"
                                },
                                ]
                            */
                            let data = getResponse.data;
                            // Need two helper indices:
                            // dataIndex is the index that is used to loop through the retrieved dataset
                            // gridIndex is the index that is used to loop through the grid (table) and 
                            // increments for each column 
                            let rowIndex = 0

                            let colorProcessing;
                            let yearStart = parseInt(weekStart.toString().substring(0, 4));
                            let yearEnd = parseInt(weekEnd.toString().substring(0, 4));
                            let startWeek, endWeek;

                            // The weeknumbersDict will be use for a reverse lookup, to match 
                            // the data's week number against the index of the grid where each 
                            // week is placed
                            let weeknumbersdict = {};
                            let colIndex = 2; // colIndex starts at 2, as the first two cols are headers

                            // Populate the first two cols of the top row with the colorcode and name headers
                            gridVals[0].push("Color");
                            gridVals[0].push("Code");

                            // sumRow is an array that is populated while iterating the data, 
                            // to sum the values of the shares for each week
                            let sumRow = ['Totals', ''];

                            // Populate the first row with week numbers
                            for (let i = yearStart; i <= yearEnd; i++) {
                                // If we are processing the first year, then start at the week
                                // that the user chose in the filter, else start with 1 (wk1)
                                if (i === yearStart)
                                    startWeek = parseInt(weekStart.toString().substring(4, 6));
                                else
                                    startWeek = 1;
                                // If we are processing the last year, then end at the week
                                // that the user chose in the filter, else end with the number of weeks in the year
                                if (i === yearEnd)
                                    endWeek = parseInt(weekEnd.toString().substring(4, 6));
                                else
                                    endWeek = weeksInYear(i);

                                for (let j = startWeek; j <= endWeek; j++) {
                                    let weeknum = i.toString() + j.toString().padStart(2, '0');
                                    // Add this weeknumber to the gridvals, these are the column headers 
                                    gridVals[0].push(weeknum);
                                    weeknumbersdict[weeknum] = colIndex;
                                    sumRow.push(0);
                                    colIndex++;
                                }
                            }
                            // This also works to get weeknumbersdict, but is this readable??
                            //const weeknumbersdict = gridVals[0].slice(2).reduce((result, value, index) => {
                            //    result[value] = index + 2;
                            //    return result;
                            //}, {});

                            // Handle the case that for (certain) colors, no units plan has yet been defined
                            // The colors for which shares ARE present are added to the array colorsWithShares
                            let colorsWithShares = [];

                            // The weeknumbersdict dictionary now contains, in order, all the weeks to populate
                            // Loop through the dataset and for each week there is a match, push it onto the grid
                            for (let i = 0; i < data.length; i++) {

                                // Colorcode changed? Then start a new row
                                if (colorProcessing !== data[i].display_colorcode) {
                                    rowIndex++;
                                    // Push a new row onto the array
                                    gridVals.push([]);
                                    gridVals[rowIndex][0] = data[i].display_colorname;
                                    gridVals[rowIndex][1] = data[i].display_colorcode.toString().padStart(3, '0');
                                    colorsWithShares.push(gridVals[rowIndex][1]);
                                }
                                // Set colorprocessing to the new color
                                colorProcessing = data[i].display_colorcode;

                                // Find the index for the week of this colorshare
                                // and insert the color share in that column
                                let index = weeknumbersdict[data[i].week];
                                gridVals[rowIndex][index] = data[i].share;
                                //sumDict[data[i].week] += data[i].share;
                                sumRow[index] += data[i].share;
                                // Add the share to the sum dict
                            }

                            // Add in row headers for those colors that have no shares data
                            // Get all colors and names  the style -> this is the full set, potentially including
                            // colors for which no week-shares have been setup yet.
                            let colorCodes = styleColorData.map(object => object.colorcode);
                            let colorNames = styleColorData.map(object => object.colorname);
                            let counter = 0;
                            for (let i = 0; i < colorCodes.length; i++) {
                                if (!colorsWithShares.includes(colorCodes[i])) {
                                    gridVals.push([]);
                                    gridVals[counter + rowIndex + 1][0] = colorNames[i];
                                    gridVals[counter + rowIndex + 1][1] = colorCodes[i];
                                    counter++;
                                }
                            }

                            // Empty the temp arrays
                            colorCodes = [];
                            colorNames = [];

                            // When all colors are loaded, add a row for the totals
                            for (let i = 2; i < sumRow.length; i++)
                                sumRow[i] += '%';
                            gridVals.push([]);

                            // And populate this row with the sum values
                            gridVals[counter + rowIndex + 1] = sumRow;

                            // Fill null elements with 0
                            for (let i = 0; i < rowIndex + 1; i++) {
                                for (let j = 0; j < colIndex; j++) {
                                    if (gridVals[i][j] === null || gridVals[i][j] === undefined || gridVals[i][j] === '')
                                        gridVals[i][j] = '0.00';
                                }
                            }

                            // Put the data in the state which will trigger a render of the grid
                            if (dataIsFresh) {
                                setDataOut(gridVals);
                                setLoading(false);
                            }

                        })
                        .catch((error) => {
                            console.error('ERROR retrieving data');
                            console.error(error);
                        });
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        // To prevent infinite calls, check whether fetchconditions have been met, specifically if 
        // the actual values of the returned data changed not just the reference
        let changeFlag = false;        

        // If not all the data has been retrieved, don't take action
        // If no styleCode was passed, don't take action --> the stylecolor-shares are always mananged at a per-style basis
        if (!(styleColorData && seasonData && salesParamsData && filters)) {
            changeFlag = false;
        }
        else {
            changeFlag = true;
        }
        
        if (changeFlag) {
            fetchData();
            return () => { dataIsFresh = false};
        }
        else {
            // Not all conditions met, exit
            setDataOut([]);
        }

    }, [seasonData, styleColorData, salesParamsData, triggerUpdate]);

    return [dataOut, setDataOut, loading, setLoading];
};

export default useStyleColorSharesCollection;