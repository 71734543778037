// 3rd party and framework
import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
// Helpers
import { getYearAndWeek } from "../../utils/utils";

// Handsontable imports and initialization
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import { textRenderer } from 'handsontable/renderers';

// Custom hooks
import useDataSource from "../../hooks/useDataSource";

registerAllModules();

// Define the type/interface for the component props
interface StylecolorUnitsTableProps {
    data: any[];
    forwardedRef?: React.Ref<HTMLDivElement>;
}
const StylecolorUnitsTable: React.FC<StylecolorUnitsTableProps> = React.forwardRef<HTMLDivElement, StylecolorUnitsTableProps>((props, ref) => {
    const { data, styleCode } = props;
    const [filters, setFilters] = useState(null);
    const [dataTotals, setDataTotals] = useDataSource(process.env.REACT_APP_TOTALSSALESPLAN, filters, false, null);
    const [gridData, setGridData] = useState(null);
    const thisWeek = getYearAndWeek(new Date());
    let colIndexCurrentWeek = -1;

    useEffect(() => {
        if (data)
            setFilters({ stylecode: styleCode, weekfilter_start: data[0][2], weekfilter_end: data[0][data[0].length - 1] });
    }, [data]);

    useEffect(() => {
        if (data && dataTotals)
            setGridData(calculateUnitValues(data));
    }, [dataTotals]);

    const calculateUnitValues = (sharesData) => {
        const newData = [[sharesData[0][0], sharesData[0][1]]];
        const weekTotals = [-1, -1];
        // First popultate the header row
        for (let i = 2; i < sharesData[0].length; i++) {
            newData[0].push(sharesData[0][i]);
            const weekData = dataTotals.find(item => parseInt(item.week) === parseInt(sharesData[0][i]));
            const units = weekData ? parseInt(weekData.total_units) : 0;
            weekTotals.push(units);
        }
        //console.log('[STYLECOLORUNITSTABLE] weekTotals=', weekTotals);
        // Next populate the datagrid (skip last row, totals)
        for (let i = 1; i < sharesData.length; i++) {
            const newRow = [];
            for (let j = 0; j < sharesData[i].length; j++) {
                // The first two cols: push in the col headers
                if (j < 2)
                    newRow.push(sharesData[i][j]);
                else {
                    // The last row: push in the totals
                    if (i === sharesData.length - 1)
                        newRow.push(weekTotals[j]);
                    // Other rows: push in the share times the total, to get the stylecolor units
                    else {
                        if (sharesData[i][j]) {
                            let value = (sharesData[i][j] / 100) * weekTotals[j];
                            newRow.push(parseFloat(value.toString()));
                        }
                        else
                            newRow.push(0);
                    }
                }
                //console.log('[STYLECOLORUNITSTABLE] newRow=', newRow);
            }
            newData.push(newRow);
        }
        return newData;
    }

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {

        try {
            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            td.setAttribute("data-cy", `cell_${row}_${col}`);

            if (instance) {
                let rowCount = instance.countRows();

                // Set cells which have readonly data in them to non-editable
                // Row 0: Week
                // Col 0: Header for color
                // Col 1: Header for color code
                if ((col === 0) || (col === 1) || (row === 0) || (row === rowCount - 1)) {
                    cellProperties.readOnly = true;
                }

                // Align text right
                if (col > 1)
                    td.style.textAlign = "right";

                // Header rowis lightyellow, bold
                if (((col < 2) && (row > 0) && (row < rowCount - 1)) || (row === 0)) {

                    td.style.backgroundColor = "#f4f4c1";
                    td.style.fontWeight = "bold";
                    td.style.color = "darkslategray";
                }

                // Header cols are lightblue
                if ((col < 2) && (row > 0) && (row < rowCount - 1)) {

                    td.style.backgroundColor = "#cbe2f1";
                    td.style.fontWeight = "bold";
                    td.style.color = "darkslategray";
                }

                if (value) {
                    // Check if the value is numeric
                    if (!isNaN(value) && col > 1 && row > 0) {
                        // Set the number of decimal places
                        value = Number(value).toFixed(0);
                    }
                }

                // Last row: bold
                if (row === rowCount - 1) {
                    td.style.fontWeight = 'bold';
                    td.style.backgroundColor = '#f4f4c1';
                }

                // Special formatting for the column that corresponds to this week
                if (col === colIndexCurrentWeek) {
                    td.style.borderLeft = "2px solid darkslategrey";
                    td.style.borderRight = "2px solid darkslategrey";
                    td.style.filter = "saturate(300%)";
                    td.style.fontWeight = "bold";
                    td.style.color = "black";
                    if (row === 0)
                        td.style.borderTop = "2px solid darkslategrey";
                    if (row === instance.countRows() - 1)
                        td.style.borderBottom = "2px solid darkslategrey";
                }

                // Apply the custom rendering
                textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
            }
        }
        catch (error) {
            console.error(error);
        }
    }


    // This approach is used, as Handsontable can apply formatting at the COL level
    // where the data is structured into ROWS. As there are ~54 COLS of data, specifying each 
    // COL individually becomes messy, so the cols are dynamically generated from the data
    const generateColumns = (data) => {
        //console.log('[STYLECOLORUNITSTABLE] data=', data);

        if (!data || data.length === 0)
            return [];

        // Get the first row of data
        const firstRow = data[0];
        if (!firstRow)
            return [];


        // console.log('[STYLECOLORUNITSTABLE] firstRow=', firstRow);
        // Get the keys (column names) from the first row
        const keys = Object.keys(firstRow);

        //console.log('[STYLECOLORUNITSTABLE] thisWeek=', thisWeek);

        // Loop through the cells in the first row
        // Mark the column that contains this week's data
        firstRow.forEach((cellValue, col) => {
            if (parseInt(cellValue) === thisWeek)
                colIndexCurrentWeek = col;
        });
        //console.log('[STYLECOLORUNITSTABLE] this far');

        // Generate the columns array dynamically
        const columns = keys.map((key) => {
            return {
                data: key,
                renderer: customRenderer,
            };
        });

        return columns;
    };

    const settings = (data) => {
        if (!data)
            return;
        return {
            data: data,
            columns: generateColumns(data),
            className: 'handsonTableOverride',
            // This is a RIDICULOUS HACK to solve for the fact HOT cannot be given a maxHeight property.
            // Set the height of the table to a multiple of the number items it contains. x31 proved 
            // to give the best result
            height: 100 + data.length * 20,
            fixedRowsTop: 1,
            fixedColumnsLeft: 2,
            rowHeaders: false,
            colHeaders: false,
            type: 'numeric',
        }
    };

    return (
        <>
            <Box color="blue.500" m={"10px"} fontSize={20}>Stylecolor sales plan</Box>
            <HotTable id="hot_table_stylecolorshares_units" settings={settings(gridData)} licenseKey="non-commercial-and-evaluation" />
        </>
    )
});

export default StylecolorUnitsTable;