import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Input, InputGroup, InputRightElement, Children } from '@chakra-ui/react'
import { List, ListItem } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons'


export default function StylePicker(parentprops) {
    const [state, setState] = useState({ suggestions: [], text: parentprops.defaultvalue });
    const [dataUrl, setDataUrl] = useState(process.env.REACT_APP_ENDPOINTSTYLES);
    const [options, setOptions] = useState();
    const [dataFetched, setDataFetched] = useState(false);
    const [stylesDict, setStylesDict] = useState(null);
    const [listVisible, setListVisible] = useState(false);
    const [currentDisplayValue, setCurrentDisplayValue] = useState(null);
    const listRef = useRef(null);
    const inputRef = useRef(null);
    const listcontainerRef = useRef(null);

    useEffect(() => {
        
        // NOTE: this is the setting that the filter gets if it is cleared
        // The value on initial run will be 'undefined'
        if (parentprops.styleCode === null || parentprops.styleCode === undefined) {
            showValueInBox(parentprops.defaultvalue);
            setCurrentDisplayValue(null);
        }
        
        fetchOptions();
        setListVisible(false);

        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('mousedown', handleClickOutsideListBox);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideListBox);
        }

    }, [parentprops.styleCode]);

    const fetchOptions = async () => {
        try {
            const response = await axios.get(dataUrl);

            let arrStyles = [];
            let entries = {}
            let valueHit;
            response.data.forEach((item) => {
                let displayvalue = item.name + " " + item.gender + ' - ' + item.code;
                entries[displayvalue] = item.code;
                arrStyles.push(displayvalue);
                if (parentprops.styleCode === item.code)
                    valueHit = displayvalue;
            });
            setStylesDict(entries);
            setOptions({ items: arrStyles });
            setDataFetched(true);
            if (valueHit) {
                showValueInBox(valueHit);
                setCurrentDisplayValue(valueHit);
            }
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleClickOutsideListBox = (event) => {
        const input = inputRef.current;
        const list = listRef.current;
        const listcontainer = listcontainerRef.current;
        if (list && !list.contains(event.target) && !listcontainer.contains(event.target) && !input.contains(event.target)) {
            showValueInBox(currentDisplayValue);
            setListVisible(false);
            hideList();
        }

    };

    const handleInputKeydown = (event) => {
        try {
            if (event.key === 'ArrowDown') {
                // Down arrow key is pressed
                event.preventDefault(); // Prevent the default scrolling behavior
                const activeElement = document.activeElement;
                /// HACK ALERT: ToDo: Properly get reference to the first element in the LI
                const nextElement = activeElement.nextSibling.firstChild.firstChild;

                if (nextElement) {
                    nextElement.focus();
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleListKeyDown = (event) => {
        try {
            if (event.key === 'ArrowDown') {
                event.preventDefault(); // Prevent the default scrolling behavior

                const activeElement = document.activeElement;
                const nextElement = activeElement.nextSibling;
                if (nextElement) {
                    nextElement.focus(); // Move focus to the next li element
                    // Remove the 'highlighted state' class from the current element
                    // and apply it to the next element
                    //nextElement.className = 'active';
                    //activeElement.className = '';
                }
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault(); // Prevent the default scrolling behavior

                const activeElement = document.activeElement;
                const previousElement = activeElement.previousSibling;
                if (previousElement) {

                    previousElement.focus(); // Move focus to the previous li element
                    // Remove the 'highlighted state' class from the current element
                    // and apply it to the previous element
                    //previousElement.className = 'active';
                    //activeElement.className = '';
                }
            }
            if (event.key === 'Enter') {
                // Enter key is pressed
                event.preventDefault(); // Prevent the default scrolling behavior
                const activeElement = document.activeElement;

                // HACK ALERT: the value passed back to the input box is pulled from the 
                // LI innerHTML. This is later (in suggestionSelected) passed back to the parent
                // by using it a a key for the stylesDict object to retrieve the styleID 
                let keyval = activeElement.innerHTML;
                suggestionSelected(keyval);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const onTextChange = (e) => {
        const val = e.target.value;
        showList(val);
    }

    const showList = (val) => {
        try {
            let { items } = options;
            let suggestions = [];

            const regex = new RegExp(`^${val}`, `i`);
            suggestions = items.sort().filter(v => regex.test(v));

            setState(() => ({
                suggestions,
                text: val
            }));
            setListVisible(true);

        } catch (error) {
            console.error(error);
        }
    }

    const hideList = () => {
        try {
            let val = inputRef.current.value;
            let suggestions = [];

            setState(() => ({
                suggestions,
                text: val
            }));

            setListVisible(false);
            if (currentDisplayValue)
                showValueInBox(currentDisplayValue);
        } catch (error) {
            console.error(error);
        }
    }

    const showValueInBox = (value) => {

        setState(() => ({
            text: value,
            suggestions: []
        }))
        setListVisible(false);
    }

    const suggestionSelected = (value) => {
        if (value === -99) {
            showValueInBox(parentprops.defaultvalue);
            setCurrentDisplayValue(parentprops.defaultvalue);
            parentprops.callback({ stylecode: undefined });
        }
        else {
            showValueInBox(value);
            setCurrentDisplayValue(value);
            // Fetch the data for the specific style and invoke the
            // callback function of the parent, sending the stylecode
            // of the selected style back to the parent component
            parentprops.callback({ stylecode: stylesDict[value] });
        }
    }
    const renderSuggestions = () => {
        

        const { suggestions } = state;
        if (suggestions.length === 0) {
            return (null);
        }
        return (
       
            <List ref={listRef}
                data-cy="stylepicker_dropdown_list"
                onKeyDown={handleListKeyDown}
                maxH="500px"
                minWidth="360px"
                overflowY="auto"
                py="5px"
                mt="3px"
                border="1px solid #3182CE"
                borderRadius="md"
                position="absolute"
                bgColor="blue.50"
                _before={{

                    maxHeight: 300,
                    overflow: "auto"
                }}
            >
                <ListItem
                    ml="-30px"
                    mr="10px"
                    px="10px"
                    py="2px"
                    borderRadius="md"
                    cursor="pointer"
                    tabIndex={-1}
                    key={undefined}
                    color="blue.500"
                    fontWeight="bold"
                    fontStyle="Italic"
                    _hover={{
                        color: "white",
                        backgroundColor: "blue.400",
                    }}
                    onClick={(e) => suggestionSelected(-99)}
                >
                    All
                </ListItem>
                {suggestions.map((keyval) => (
                    <ListItem
                        data-cy={keyval}                        
                        ml="-30px"
                        mr="10px"
                        px="10px"
                        py="2px"
                        borderRadius="md"
                        cursor="pointer"
                        tabIndex={-1}
                        key={keyval}
                        _hover={{
                            color: "white",
                            backgroundColor: "blue.400",
                        }}
                        bg={currentDisplayValue === keyval ? "blue.400" : ""}
                        color={currentDisplayValue === keyval ? "white" : ""}
                        onClick={(e) => suggestionSelected(keyval)}
                    >
                        {keyval}
                    </ListItem>
                ))}
            </List>

        )
    }

    const boxClicked = () => {
        if (listVisible) {
            hideList();
        }
        else {
            showList('');
        }
    }

    // Handle keyboard scrolling through the list items

    const { text } = state;

    return (
        <Box borderRadius="md" zIndex={100} id="stylepicker_dropdown_wrapper">
            <InputGroup>
                <Input data-cy="stylepicker_dropdown_input"
                    ref={inputRef}
                    onChange={onTextChange}
                    onKeyDown={handleInputKeydown}
                    onClick={boxClicked}
                    placeholder={parentprops.defaultvalue}
                    value={text} type="text"
                    width="auto"
                    minWidth="360px"
                    height="38px"
                    color="blue.600"
                    border="1px solid #3182CE"
                    fontWeight="medium"
                    _hover={{
                        border: '1px solid hotpink',
                        color: 'hotpink',
                        backgroundColor: "blue.50",
                    }}
                    _placeholder={{
                        color: "blue.500",
                    }}
                
                />
                <InputRightElement pointerEvents="none" color="blue.500"><ChevronDownIcon transform={listVisible ? "rotate(180deg)" : ""} transition="300ms" /></InputRightElement>
            </InputGroup>
            <Box ref={listcontainerRef} zIndex={200}>
                {renderSuggestions()}
            </Box>
        </Box>


    );
}



