import React, { useState } from 'react';
import axios from 'axios';
import { Box, Flex, Input, Button, Text } from '@chakra-ui/react';
import { useLocation, Navigate } from 'react-router-dom';

export default function Login() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const next = queryParams.get('next') ? queryParams.get('next') : process.env.REACT_APP_DEFAULT_REDIRECT_AFTER_LOGIN;
    const hitUnauthorizedUrl = queryParams.get('hitunauthorizedurl') ? queryParams.get('hitunauthorizedurl') === 'true' : false;

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginResult, setLoginResult] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        setIsAuthenticated(false);
        try {
            // Remove any existing (stale) tokens. The axios post must be done without an auth token in the header.
            delete axios.defaults.headers.common["Authorization"];

            // Get the fresh token
            const response = await axios.post(process.env.REACT_APP_URL_AUTH_GET_TOKEN, {
                username: username,
                password: password
            });

            //console.log('token=', response.data.token);

            if (response.data && response.data.token) {
                setIsAuthenticated(true);
                setLoginResult('Login successful!');

                // Set the token in session storage
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('username', username);

                // Make sure the token gets included in the header of subsequent requests
                axios.defaults.headers.common["Authorization"] = "Token " + response.data.token;

                // Redirect is done in the return function with the <Navigate> component
                //console.log('[LOGIN] Success, redirecting to ', next);

            } else {
                delete axios.defaults.headers.common["Authorization"];
                sessionStorage.setItem('token', null);
                sessionStorage.setItem('username', null);
                setLoginResult('Login failed. Please check your credentials.');
            }
        } catch (error) {
            //console.error(error);
            delete axios.defaults.headers.common["Authorization"];
            localStorage.setItem('token', null);
            localStorage.setItem('username', null);
            setLoginResult('Login failed. Please check your credentials.');
        }
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
        setLoginResult('');
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setLoginResult('');
    };

    return (

        isAuthenticated ?
            <Navigate to={next} />
            :

            <Flex
                height="100vh"

                alignItems="center"
                justifyContent="center"
                bg="#EBF8FF"
            >

                <Box width="400px" minH="390px" p={4} m={4} borderRadius="md" backgroundColor="#FDB813">

                    <Box
                        data-cy="logintopbar"
                        width="100%"
                        textAlign="center"
                        fontFamily="Filson Pro"
                        fontWeight="bold"
                        fontSize="30"
                        color="#D02255"
                        zIndex={1000}
                        display="flex"
                        bg="#FDB813"
                        px={4}
                        py={4}

                        height={20}
                        alignItems="center"
                    >
                        Salesplanner login
                    </Box>
                    {hitUnauthorizedUrl && <Box px={4} pb={2} color="blue.500">Your <strong>session expired</strong> and you've been redirected to the login page.</Box>}
                    <Flex direction="column" mt={4} align="center">
                        <Input
                            data-cy="login-username"
                            placeholder="Username"
                            size="lg"
                            value={username}
                            onChange={handleUsernameChange}
                        />
                        <Input
                            data-cy="login-password"
                            mt={4}
                            placeholder="Password"
                            size="lg"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        {loginResult && (
                            <Text mt={4} color={isAuthenticated ? 'green.500' : 'red.500'}>
                                {loginResult}
                            </Text>
                        )}
                        <Button
                            data-cy="login-submit"
                            mt={4}
                            colorScheme="blue"
                            variant="outline"
                            size="lg"
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                    </Flex>
                </Box>
            </Flex>
    );
}
