import { useEffect, useState, useRef } from "react";
//import useDataSource from "./useDataSource";
import axios from "axios";
import { getNextWeek, weeksInYear } from "../utils/utils";
import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";
// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');

const useSalesForecastTotalsCollection = (filters) => {
    const [dataOut, setDataOut] = useState([]);
    const [loading, setLoading] = useState(true);
    const startTimestamp = useRef(null);

    if (!startTimestamp.current)
        startTimestamp.current = new Date().getTime();
    //console.log('[TIMING] came in with filters:', filters);
    //console.log('[TIMING] came in at time:', startTimestamp.current);

    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    //useWhatChanged([filters]); // debugs the below useEffect    
    useEffect(() => {
        let ignore = false;

        // - Start with a 6-digit startweek and endweek, say 202245 and  202352
        // - Populate an array, starting at the startweek, ending in the endweek, making sure the 
        //   last week of the year(s)(if the start / end span  multiple years) are correct as an ISO week, so either 52 or 53
        // - Result: fully populated array, filled with 6-digit weeknumbers, say [202245, ....., 202352]
        // - After this, take the sparsely populated dicts, that contain entries of the form {week: 202302, total: 123, week 202315, total: 786, ...} 
        //   Note that these dicts are sparse, they do not contain an entry for each week
        // - Extend the original array into a grid, by adding a new row for each dict, in which the values from the dict are placed in the column corresponding to the weeknumber 
        // - Note:
        // - The top row if the grid is a label row, these are the weeknumbers
        // - The first columns of the grid is a label for the specific dict. So the data essentially starts on row 2, column 2.
        const fetchData = () => {
            const time1 = new Date().getTime();
            setLoading(true);

            function generateGrid(startWeek, endWeek, ...dicts) {
                
                // Initialize grid with a label for the week
                const weekRow = ['Week:'];
                // Add week numbers as labels in the first row
                for (let week = parseInt(startWeek); parseInt(week) <= parseInt(endWeek); week = getNextWeek(week)) {
                    weekRow.push(week);
                }
                //console.log('[USESALESFORECASTTOTALSCOLLECTION] weekRow=', weekRow);

                const grid = [];
                grid.push(weekRow);
                //console.log('[USESALESFORECASTTOTALSCOLLECTION] pushed on weekrow, grid=', grid);



                // Populate data rows for each dict
                for (const dict of dicts) {
                    // Start the row with the dict label
                    const row = [dict.label];

                    //console.log('[USESALESFORECASTTOTALSCOLLECTION] dict=', dict);
                    //console.log('[USESALESFORECASTTOTALSCOLLECTION] startWeek=', startWeek);

                    // Populate data for each week
                    for (let week = parseInt(startWeek); week <= endWeek; week = getNextWeek(week)) {
      
                        // Find the entry with the matching week number
                        const entry = dict.entries.find(entry => entry.week === week);

                        // Get the value and insert it or insert 0 if not found
                        const value = entry ? entry.total_units : '';
                        row.push(value);
                    }
                    grid.push(row);
                }
                //console.log('[USESALESFORECASTTOTALSCOLLECTION] returning grid=', grid);
                return grid;

            }
            


            const endPointOne = process.env.REACT_APP_TOTALSFORECAST + '?' + new URLSearchParams(filters);
            const endPointTwo = process.env.REACT_APP_TOTALSSALESPLAN + '?' + new URLSearchParams(filters);
            const endPointThree = process.env.REACT_APP_TOTALSACTUALS + '?' + new URLSearchParams(filters);
            const endPointFour = process.env.REACT_APP_TOTALSBULKPOSITIONS + '?' + new URLSearchParams(filters);
            const endPointFive = process.env.REACT_APP_TOTALSBULKDROPS + '?' + new URLSearchParams(filters);

            axios.all(
                [
                    axios.get(endPointOne),
                    axios.get(endPointTwo),
                    axios.get(endPointThree),
                    axios.get(endPointFour),
                    axios.get(endPointFive)
                ])
                .then(axios.spread((response1, response2, response3, response4, response5) => {
                    const returnedData = {};
                    returnedData['set1'] = response1.data;
                    returnedData['set2'] = response2.data;
                    returnedData['set3'] = response3.data;
                    returnedData['set4'] = response4.data;
                    returnedData['set5'] = response5.data;

                    //console.log('[SETTINGS] set 1=', returnedData.set1);
                    //console.log('[SETTINGS] set 2=', returnedData.set2);
                    //console.log('[SETTINGS] set 3=', returnedData.set3);

                
                    const dicts = [
                        {
                            label: 'Plan',
                            entries: returnedData.set2
                        },
                        {
                            label: 'Actuals',
                            entries: returnedData.set3
                        },
                        {
                            label: 'Forecast',
                            entries: returnedData.set1
                        },
                        {
                            label: 'Bulk units',
                            entries: returnedData.set4
                        },
                        {
                            label: 'Bulk drops',
                            entries: returnedData.set5
                        }  
                    ];
            
                    const gridOut = generateGrid(filters['weekfilter_start'], filters['weekfilter_end'], ...dicts);

                    // Do not return stale data 
                    if (!ignore) {
                        //const time2 = new Date().getTime();
                        //console.log('[TIMING] YES! Done fetching, time2=', time2);
                        //console.log('[TIMING] milliseconds lapsed since initial call=', time2 - startTimestamp.current);                
                        //console.log('[USESALESFORECASTTOTALSCOLLECTION] setting grid=', gridOut);

                        // The .map is done to pass out a deepcopy that won't get mutated and passed by ref
                        setDataOut(gridOut.map(innerArray => [...innerArray]));
                        setLoading(false);
                        startTimestamp.current = null;
                    }
                    return;
                }));

        }
        /*
        if (dataIn1 && dataIn2 && dataIn3) {
            fetchData();
        }
        */
        
        fetchData();
        
        // The cleanup function prevents a double request and the return of stale data
        return () => {
            ignore = true;
        };
    }, [filters, filters.stylecode, filters.colorcode, filters.weekfilter_start, filters.weekfilter_end, filters.loo]);

    return [dataOut, setDataOut, loading, setLoading];
}
export default useSalesForecastTotalsCollection;