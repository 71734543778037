import React, { useState } from "react";
import TitleBar from "../../components/pageblocks/Titlebar.jsx";
import { Box, Button, Image } from "@chakra-ui/react";
import Navbar from "../../components/nav/Navbar.jsx";

export default function Uploads() {
    const [file, setFile] = useState(null);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [uploadResults, setUploadResults] = useState("");
    const [uploading, setUploading] = useState(false);

    const handleDrop = (event) => {

        event.preventDefault();
        const maxSize = 10000000;
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
        if (droppedFile) {

            const [{ size, name }] = event.dataTransfer.files;
            var err = '';
            setUploadResults("");

            // Test the file is not too large
            if (size > maxSize) {
                err = 'File too large. Max size=' + maxSize / 1000 + 'kB';
            }

            var regexPattern = null;
            var errTextWrongFileFormat = '';
            regexPattern = /^.*\.(csv|CSV|txt|TXT)$/gm;
            errTextWrongFileFormat = 'Please choose a CSV file of type .csv, .CSV, .txt ';


            // Test that the file has the correct extension and has only one single '.' in it
            // See https://regex101.com/ for explanation of the regex
            let matches = name.match(regexPattern);
            if (matches == null || matches.length !== 1) {
                err = errTextWrongFileFormat;
            }

            if (err !== '') {
                //setInvalidFile(true);
                setErrorText(err);
                //setProcesing(false);
                setButtonEnabled(false);
                return;
            }

            // No errors, all checks passed, process the file
            setErrorText('');
            //setFileName(name);
            //setInvalidFile(false);
            setButtonEnabled(true);

        }
    }

    // Apply HTML formatting to results text
    const formatResultsText = (text) => {
        //console.log(text);
        text = text.replace(/SUCCESS/g, '<span style="color: green; font-weight: bold">SUCCESS</span>');
        text = text.replace(/ERROR/g, '<span style="color: red; font-weight: bold">ERROR</span>');
        return text;
    }

    const handleFileUpload = () => {

        const formData = new FormData();
        formData.append('csvFile', file);
        setUploading(true);
        setUploadResults("");

        // Make a request to the backend API to upload the CSV file
        fetch(process.env.REACT_APP_UPLOAD_CSV_LINK, {
            method: 'POST',
            headers: {
                Authorization: `Token ${sessionStorage.getItem('token')}`,
            },            
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the backend
                //console.log(data);
                const errorList = Object.entries(data).map(([key, value]) => (
                    <li data-cy={"li_results_list_item_" + key} key={key} dangerouslySetInnerHTML={{ __html: formatResultsText(value) }}></li>
                ));

                const ulElement = <><Box fontWeight="bold">Results:</Box><Box data-cy="ul_results_list" ml="-30px" as="ul" listStyleType="none">{errorList}</Box></>;
                setUploadResults(ulElement);
                setUploading(false);
            })
            .catch((error) => {
                // Handle any errors that occurred during the upload
                console.error(error);
            });
    }

    return (
        <>
            <Navbar />
            <TitleBar title="Upload sales and bulk data" />
            <Box ml="15px" fontSize="20px">Drop a CSV file into the box below to upload sales and bulk position data for stylecolors.</Box>
            <Box
                data-cy="upload_dropbox"
                border="1px solid grey"
                m="10px"
                p="20px"
                borderRadius="md"
                className="csv-uploader"
                onDragOver={(event) => event.preventDefault()}
                onDrop={handleDrop}
            >

                {file ?

                    <Box ml={15} >
                        <Box fontWeight="bold">File to upload:</Box><Box color="blue.400" fontFamily="mono" fontSize="19px" > {file.name}</Box>
                        <Button data-cy="button_upload" isDisabled={!buttonEnabled} isLoading={uploading} loadingText='Uploading' mt="15px" colorScheme="blue" variant="outline" onClick={handleFileUpload}>Upload CSV</Button>
                        <Box color="red" fontWeight="bold" mt="10px">{errorText}</Box>
                        <Box data-cy="box_results" fontSize="13px" maxH="400px" overflow="auto">{uploadResults}</Box>
                    </Box>

                    :
                    <Box color="blue.500" mt={"5px"} ml={15} fontSize={20} fontStyle="italic">Drop a CSV file here to upload...<Image mx="30px" w="50px" borderRadius="5px" src="/images/ExcelIcon.png" alt="CSV Icon" display="inline-block" /></Box>

                }
            </Box>

            <Box m="10px" p="20px">CSV upload structure. The file has 5 columns (example below):
                <br />
                <ul>
                    <li><strong>Stylecolor</strong>: the 9-digit stylecolor code</li>
                    <li><strong>Week</strong>: the 6-digit YYYYWW code of the fiscal week</li>
                    <li><strong>Units sold</strong>: the number of units sold in the week</li>
                    <li><strong>Units on bulk</strong>: the number of units on bulk for the week</li>
                    <li><strong>Bulk drops</strong>: bulk replenishment units in a week</li>
                </ul>
                Notes:
                <ul>
                    <li>The file should be structured with exactly these 5 columns</li>
                    <li>All numbers should be exactly as in the descriptions above, i.e. 9-digits, 6-digits etc.</li>
                    <li>A continuous series of weeks is not needed. Data can be supplied e.g. for week 202309 and 202325</li>
                    <li>Not all stylecolors need to be in the file. Data can be supplied for any number of stylecolors or a single stylecolor.</li>
                    <li>If new numbers are uploaded for a given stylecolor-week combination, these will overwrite existing numbers.</li>
                    <li>If a stylecolor and week are filled in, any blank cells in that row will result in the sales actuals, bulk positions or bulk drops of that week being deleted.</li>
                    <li><strong>IMPORTANT</strong> the point above means that if future bulk drops are entered for a week-stylecolor set, if data for that week-stylecolor is entered again, the bulk_drops number should be included. If not, it will be deleted. TODO: DECIDE IF THIS IS THE DESIRED WAY FOR THE SYSTEM TO WORK.</li></ul>
            </Box>
            <Image mx="30px" minW="350px" border="1px solid grey" borderRadius="5px" src="/images/UploadExample.png" alt="Example of upload CSV format" />


        </>
    );
};

