// Framework and 3rd party
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, Button } from '@chakra-ui/react'
import { Link as ChakraLink } from "@chakra-ui/react";
import axios from 'axios';

// Helpers
import { toastOnError } from "../../utils/utils";
import { weeksInYear } from "../../utils/utils";
import { calCulateMultipliersAndPlanValues } from "../../utils/domainSpecific";
import { baseModelAndSalesplanForStyle } from "../../utils/domainSpecific";
import { styleTitleFromStyleData } from "../../utils/domainSpecific";
import { styleDataFromStyleCode } from "../../utils/domainSpecific";
import { loadSearchParamsCRA } from "../../utils/domainSpecific";

// Custom components
import Navbar from "../../components/nav/Navbar";
import DataChart from "../../components/charts/DataChart";
import FilterBar from "../../components/filters/FilterBar";
import TitleBar from "../../components/pageblocks/Titlebar";
import SalesplanTable from "../../components/datatables/SalesplanTable";
import SalesParamsForm from "../../components/forms/SalesParamsForm";

// Custom hooks
import useLocalStorage from "../../hooks/useLocalStorage";
import useDataSource from "../../hooks/useDataSource";

export default function Salesplan() {
    // Extract search parameters from URL structure and querystring
    const paramVals = loadSearchParamsCRA(useParams(), new URLSearchParams(useLocation().search));

    const [salesParameters, setSalesParameters] = useState(null);
    const [chartForceUpdateToggle, setChartForceUpdateToggle] = useState(false);

    // Can this be moved fully into filters?  
    //const [styleCode, setStyleCode] = useState(paramVals['stylecode']);

    // Custom hook used to get styleinfo
    const [stylesData, setStylesData] = useState(null);

    // The filters are stored in a custom hook, useLocalStorage, that persists the values into local storage, 
    // to maintain the values across pages 
    const [weekfilterStart, setWeekfilterStart] = useLocalStorage('weekfilterStart', (new Date().getFullYear()) + '01');
    const [weekfilterEnd, setWeekfilterEnd] = useLocalStorage('weekfilterEnd', (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));


    // Filters: dict object in which the values from filterboxes are set
    // Changing these triggers state changes that make the custom hooks re-render
    const [filters, setFilters] = useState({ weekfilter_start: weekfilterStart, weekfilter_end: weekfilterEnd, stylecode: paramVals['stylecode'] });

    // GLOBAL settings, passed to the page when it is called 
    const [windFactors, setWindfactors] = useDataSource(process.env.REACT_APP_ENDPOINTWINDFACTORS, null, true);

    // Handsontable plumbing
    const [grid, setGrid] = useState(null); // The grid holds the data displayed in the table
    const hotRef = useRef(null);

    // NOTE the items in the dependency array [] at the end of useEffect. This will cause a 
    /// reload of the page if any of these items changes value (via their set..() functions)
    useEffect(() => {
        if (filters.stylecode !== undefined && filters.stylecode !== "") {
            fetchAll(filters.stylecode, weekfilterStart, weekfilterEnd);
            styleDataFromStyleCode(process.env.REACT_APP_ENDPOINTSTYLES + '?stylecode=' + filters.stylecode, setStylesData);
        }
    }, [filters.stylecode]);

    // If the griddata changes, also give the chart a kick to update 
    useEffect(() => {
        setChartForceUpdateToggle(!chartForceUpdateToggle);
    }, [grid]);

    // Cleanup
    useEffect(() => {

        // Return with cleanup
        return () => {
            // Cleanup the HOT instances
            if (hotRef && hotRef.hotInstance && hotRef.hotInstance.current) {
                hotRef.hotInstance.current.destroy();
            }
        }
    }, []);


    const fetchAll = (stylecode, startweek, endweek) => {

        // The data populator functions are called by pasing a url to them and required callback functions
        // The data populator functions retrieve the data and pass these back via the callbacks
        baseModelAndSalesplanForStyle(
            process.env.REACT_APP_ENDPOINTBASEMODELS + '?sort=week&weekfilter_start=' + startweek + "&weekfilter_end=" + endweek,
            process.env.REACT_APP_ENDPOINTSALESPLANS + '?stylecode=' + stylecode,
            setGrid
        );
    }

    const upLoadData = async (uploadValues) => {
        try {
            // Insert info about the style into the first element of the 
            // tabledata array, for identification on the API side
            let styleInfo = [{ "styleCode": filters.stylecode }]
            uploadValues.unshift(styleInfo);

            // Post the tabledata to the API
            await axios
                .post(process.env.REACT_APP_POPULATORSALESPLANS, uploadValues)
                // Give the chart a kick to force it to update
                .then((response) => { setChartForceUpdateToggle(!chartForceUpdateToggle); })
                .catch(error => { toastOnError(error); })
        } catch (error) {
            toastOnError(error);
        }
    }

    const doSave = () => {
        //console.log('[DOSAVE] params to save=', salesParameters);
        //console.log('[DOSAVE] windFactors=', windFactors);
        // (Re)calculate the values for the campaign multipliers
        // Retrieve the data from the table (an array of arrays)
        // Campaign pressure is stored in row 2

        const tableData = hotRef.current.hotInstance.getData();
        // ToDo: test and see whether the startWeek/endWeek can't always be pulled from the 
        // tableData, eliminating the need to pass them seperately into calCulateMultipliersAndPlanValues
        let startWeek, endWeek;
        if (!salesParameters) {
            startWeek = tableData[0][1];
            endWeek = tableData[0][tableData[0].length - 1];
        }
        else {
            startWeek = salesParameters.startweek;
            endWeek = salesParameters.endweek;
        }

        let newData = calCulateMultipliersAndPlanValues(
            tableData,
            setGrid,
            startWeek,
            endWeek,
            windFactors[0].CampaignMaxAcceleration,
            windFactors[0].DiscountMaxAcceleration);

        //console.log('[DOSAVE] newData=', newData);

        // POST the data to the API
        upLoadData(newData);
    }

    // The callback function to process filter callbacks expects a set of 1 or more key-value pairs
    // structured in a Dict. It merges the new filter values with the existing ones,
    // overwriting where the existing data is stale. 
    // Setting the new filter values triggers an update of the hooks with the filters in their dependancy arrray
    const filterCallback = (newFilterValues) => {
        try {
            //console.log('[SALESPLAN FILTERCALLBACK] callback, old filters=', filters)
            // Merge the incoming filters into the previous filters 
            // values, either adding or overwriting.
            // In the process this creates a new dict, which triggers the
            // useEffect call in the hook that has these filters 
            // in the dependency array
            let tempFilters = { ...filters, ...newFilterValues };

            // The two are now merged and 'tempFilters' contains 
            // the up to date filter values; update the filters state
            // (this triggers useEffects ton retrieve data)

            //console.log('[SALESPLAN FILTERCALLBACK] callback, fresh filters=', tempFilters)

            // Additional manipulation needed for stylecodes / colorcodes as these are interdependent
            let styleTriggeredCallback = newFilterValues.hasOwnProperty('stylecode');
            if (tempFilters['stylecode'] === null || tempFilters['stylecode'] === undefined) {
                window.history.pushState(null, '', '/salesplan/');
                tempFilters['colorcode'] = null;
            }
            else if (tempFilters['stylecode']) {
                let url = '/salesplan/' + tempFilters['stylecode'];
                window.history.pushState(null, '', url);
                if (tempFilters['colorcode'] && (!styleTriggeredCallback)) {
                    url += "/" + tempFilters['colorcode']
                }
                window.history.pushState(null, '', url);
            }
            // Update the filter values
            setFilters(tempFilters);

            // Additional manipulation needed for weekfilters to persist to local storage
            // and updateing data
            if (newFilterValues.hasOwnProperty('weekfilter_start')) {
                setWeekfilterStart(parseInt(newFilterValues['weekfilter_start']));
                fetchAll(filters.stylecode, newFilterValues.weekfilter_start, filters.weekfilter_end);
            }
            if (newFilterValues.hasOwnProperty('weekfilter_end')) {
                setWeekfilterEnd(parseInt(newFilterValues.weekfilter_end));
                fetchAll(filters.stylecode, filters.weekfilter_start, newFilterValues.weekfilter_end);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const clearFiltersCallback = () => {
        window.history.pushState(null, '', '/salesplan/');
        setStylesData(null);
        setFilters({ weekfilter_start: new Date().getFullYear() + '01', weekfilter_end: (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()) });
        // These are resest and with that also persisted to localstorage
        setWeekfilterStart(new Date().getFullYear() + '01');
        setWeekfilterEnd((new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));
    }

    return (
        <main>
            <Navbar />
            <TitleBar title="Salesplan" extra={styleTitleFromStyleData(stylesData)} />
            <FilterBar
                filterChoices={{
                    showStyleFilter: true,
                    showColorFilter: false,
                    showYearStartFilter: true,
                    showYearEndFilter: true,
                    showMarketFilter: true
                }}
                stylecode={filters.stylecode}
                stylecodeFilterCallback={filterCallback}
                colorcode={filters.colorcode}
                colorcodeFilterCallback={filterCallback}
                seedStartYear={new Date().getFullYear()}
                weekNumStart={filters.weekfilter_start}
                startWeekFilterCallback={filterCallback}
                seedEndYear={new Date().getFullYear() + 1}
                weekNumEnd={filters.weekfilter_end}
                endWeekFilterCallback={filterCallback}
                market={filters.market}
                marketFilterCallback={filterCallback}
                clearFilters={clearFiltersCallback}
            />

            {(!filters.stylecode || (filters.stylecode && (filters.stylecode === undefined || filters.stylecode === ""))) ?
                <Box pl={"20px"} pt={"20px"} maxW={800} color="blue.500" fontWeight={"bold"} w={"100%"}>
                    Please select a style to build its salesplan.
                </Box>
                :
                <>
                    <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px" minH="330px">
                        <Box color="blue.500" mt={"5px"} ml={15} fontSize={20}>Salesplan parameters - base setup of the salesplan</Box>
                        <SalesParamsForm
                            styleCode={filters.stylecode}
                            stylesData={stylesData}
                            filters={filters}
                            callBackSetSalesParams={setSalesParameters}
                            setGrid={setGrid}
                            btnRecalculateId={"btnRecalculateAndSave"}
                        />
                    </Box>

                    <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px" minH="314px">
                        <Box data-cy="salesplan_table_container" px="15px" py="10px">
                        <SalesplanTable data={grid} ref={hotRef} stylesData={stylesData} />

                            {grid && grid[0].length > 1 && (
                                <>
                                <Button data-cy="salesplan_button_recalculate" mx="5px" my="20px" id="btnRecalculateAndSave" colorScheme="blue" variant="outline" onClick={doSave}>Recalculate and Save</Button>
                                    <Button
                                        data-cy="salesplan_button_link_to_styleshares"
                                        as="a"
                                        href={"/stylecolorshares/" + filters.stylecode}
                                        textDecoration="none"
                                        colorScheme="blue"
                                        variant="outline">
                                        Setup Stylecolor Shares
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>

                    <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px" minH="300px">
                        <DataChart data={grid} dataRows={[9]} yLabel="Units" title={'Unit sales plan ' + styleTitleFromStyleData(stylesData)} />
                    </Box>
                </>}
        </main>

    );
}

