// 3rd party and framework
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from 'axios';
import { Spinner, Box, Flex, Spacer, Button, cookieStorageManager, color } from '@chakra-ui/react'

// Custom components
import Navbar from "../../components/nav/Navbar";
import TitleBar from "../../components/pageblocks/Titlebar";
import FilterBar from "../../components/filters/FilterBar";
import ForecastTable from "../../components/datatables/ForecastTable";
import ForecastParamsWeekSelector from "../../components/forms/ForecastParamsWeekSelector";
import DataChart from "../../components/charts/DataChart";

// Helpers
import { loadSearchParamsCRA } from "../../utils/domainSpecific";
import { getISOWeekNum, getYearAndWeek, getWeekNumber, subtractWeeksFromWeekNum, calculateWeekDelta, weeksInYear } from '../../utils/utils';

// Custom hooks
import useSalesForecastTotalsCollection from "../../hooks/useSalesForecastTotalsCollection";
import useStyleColorSharesCollection from "../../hooks/useStyleColorSharesCollection";
import useLocalStorage from "../../hooks/useLocalStorage";
import usePlanForColor from "../../hooks/usePlanForColor";

export default function Forecast() {
    // Extract search parameters from URL structure and querystring
    const paramVals = loadSearchParamsCRA(useParams(), new URLSearchParams(useLocation().search));
    
    // The filters are stored in a custom hook, useLocalStorage, that persists the values into local storage,
    // to maintain the values across pages 
    const [weekfilterStart, setWeekfilterStart] = useLocalStorage('weekfilterStart', (new Date().getFullYear()) + '01');
    const [weekfilterEnd, setWeekfilterEnd] = useLocalStorage('weekfilterEnd', (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));

    // Filters: dict object in which the values from filterboxes are set
    // Changing these triggers state changes that make the custom hooks re-render
    // Setup the weekstart/end filters with default values
    // Set the styleCode and colorCode valuies from the url, if they were passed
    const [filters, setFilters] = useState({
        weekfilter_start: weekfilterStart,
        weekfilter_end: weekfilterEnd,
        stylecode: paramVals.stylecode,
        colorcode: paramVals.colorcode
    });
    const [inputFilters, setInputFilters] = useState({
        weekfilter_start: subtractWeeksFromWeekNum(parseInt(new Date().getFullYear()) + getISOWeekNum(new Date()), 5),
        weekfilter_end: subtractWeeksFromWeekNum(parseInt(new Date().getFullYear()) + getISOWeekNum(new Date()), 1),
        stylecode: paramVals.stylecode,
        colorcode: paramVals.colorcode,
        lookbackavgweeks: 4
    });

    // The styleData is the 'raw' data on which manipulation is done to present it in the grid / table
    const [styleData, setStyleData, dataLoading, setDataLoading] = useSalesForecastTotalsCollection(filters);
    // The grid holds the 'processed' data displayed in the table
    const [gridData, setGridData] = useState(null);
    // The dataset with stylecolorshares - applied to totals to get color-level forecast/plan
    const [styleColorShares, setStyleColorShares] = useStyleColorSharesCollection(filters);

    // The object that holds the data in the forecast boxes
    // Note these boxzes are purely used for display purposes
    const [forecastInputs, setForecastInputs] = useState({});
    // For Handsontable
    const hotRefData = useRef(null);

    // Get the salesplan at the color level
    const [planForColor, setPlanForColor, colorPlanLoading, setColorPlanLoading] = usePlanForColor(filters);

    // Message about the gap between the startfiter week and the current week
    const [messageWeekGap, setMessageWeekGap] = useState("");

    useEffect(() => {

        if (styleData && styleData.length > 0) {

            // If a color is being processed, update plan values to reflect the plan for that color
            if (filters.colorcode && planForColor) {
                injectColorPlanValues(styleData, planForColor)
            }

            // Calculate Averages sales and Plan values and ratio from the data and the # weeks to look back
            const fcInputs = getForecastInputsFromData(styleData, inputFilters.lookbackavgweeks)

            // Store these values. 
            setForecastInputs(fcInputs);

            // Based on the ratio, calculate the forecast values
            injectForecastValues(styleData, fcInputs.ratio);

            // Calculate the future bulk values from the delta between last week's
            // bulk position and this week's forecast, plus any bulk drops
            injectBulkValues(styleData);

            // Do a deep-copy of the data when sending it to the grid, so that 
            // his royal highness the chart also sees fit to update
            const dataFresh = styleData.map(innerArray => [...innerArray]);
            setGridData(dataFresh);

            // Additionally, check if the gap between this week and the week selected in the filters is sufficient to calculate the ratio
            // and from that project sales and bulk positions
            checkWeekDeltaSufficientForForecast();
        }

    }, [styleData, planForColor, inputFilters]);

    const checkWeekDeltaSufficientForForecast = () => {
        const thisWeek = getYearAndWeek(new Date());
        const filterStartWeek = filters.weekfilter_start;
        const lookbackWeeks = inputFilters.lookbackavgweeks;
        const cutoffWeekToShow = subtractWeeksFromWeekNum(thisWeek, lookbackWeeks);
        // The test here is to check that the gap between the current week and the startweek selected in the filter
        // is >= the lookback week window. Show a warning message of not
        const delta = calculateWeekDelta(filterStartWeek, thisWeek);
        if (delta < parseInt(lookbackWeeks))
            setMessageWeekGap(`Please set the startweek in the filter to at least ${lookbackWeeks} weeks before the current week, so ${cutoffWeekToShow} or earlier. 
                    Or lower the 'Weeks for lookback' to ${delta} or lower. The table below should start at least the number of weeks set as 'Weeks for lookback' prior to the current week, ${thisWeek}. 
                    This allows the sales forecast and future bulk positions to be calculated.`);
        else
            setMessageWeekGap("");
    }

    // Note that this function use the byRef passing of gridIn
    // to mutate the values in the grid 
    const injectColorPlanValues = (dataGrid, colorPlanData) => {
        if (dataGrid && dataGrid[0]) {
            for (let i = 1; i < dataGrid[0].length; i++) {
                const weekToMatch = parseInt(dataGrid[0][i]);
                dataGrid[1][i] = Math.round(colorPlanData.find(obj => obj.week === weekToMatch)?.units_for_color) || null;
            }
        }
    }

    // Note that this function use the byRef passing of gridIn
    // to mutate the values in the grid 
    const injectForecastValues = (dataGrid, ratio) => {
        const thisWeek = getYearAndWeek(new Date());
        if (dataGrid && dataGrid[0]) {
            for (let i = 1; i < dataGrid[0].length; i++) {
                if (parseInt(dataGrid[0][i]) >= thisWeek)
                    dataGrid[3][i] = Math.round(dataGrid[1][i] * ratio / 100);
            }
        }
    }

    // Note that this function use the byRef passing of gridIn
    // to mutate the values in the grid
    // Calculate the projected bulk value for all weeks in the future
    const injectBulkValues = (dataGrid) => {
        const thisWeek = getYearAndWeek(new Date());
        if (dataGrid && dataGrid[0]) {
            for (let i = 1; i < dataGrid[0].length; i++) {
                if (parseInt(dataGrid[0][i]) >= thisWeek && parseInt(dataGrid[3][i]) > 0)
                    // The operation can only be done if at least one prior week of bulk position data is 
                    // present in thr grid
                    if (i > 2) {
                        // Take the bulk vaue of the previous week, and subtract from that the forecast of the week
                        // being processed and add any bulk drops if present
                        dataGrid[4][i] = Math.round(dataGrid[4][i - 1] - dataGrid[3][i] + dataGrid[5][i]);
                    }
            }
        }
    }

    // Pull the last X weeks of sales and plan from the data and use that to calculate the ratio of sales:plan
    const getForecastInputsFromData = (dataGrid, lookbackAvgSalesWeeks) => {
        const endWeek = getYearAndWeek(new Date());
        const startWeek = subtractWeeksFromWeekNum(endWeek, lookbackAvgSalesWeeks);
        let sumSales = 0, sumPlan = 0;
        if (dataGrid && dataGrid[0]) {
            for (let i = 1; i < dataGrid[0].length; i++) {
                if (parseInt(dataGrid[0][i]) >= startWeek && parseInt(dataGrid[0][i]) < endWeek) {
                    //console.log('[FORECAST] dataGrid[1][i]:', dataGrid[1][i]);
                    sumSales += (dataGrid[2][i] && !isNaN(dataGrid[2][i])) ? parseFloat(dataGrid[2][i]) : 0;
                    sumPlan += (dataGrid[1][i] && !isNaN(dataGrid[1][i])) ? parseFloat(dataGrid[1][i]) : 0;
                }
            }
        }
        
        //console.log('[FORECAST] sumSales:', sumSales);
        //console.log('[FORECAST] sumPlan:', sumPlan);
        //console.log('[FORECAST] counter:', counter);
        //console.log('[FORECAST] lookbackAvgSalesWeeks:', lookbackAvgSalesWeeks);
        
        const avgPlan = lookbackAvgSalesWeeks > 0 ? Math.round(sumPlan / lookbackAvgSalesWeeks) : 0;
        const avgActuals = lookbackAvgSalesWeeks > 0 ? Math.round(sumSales / lookbackAvgSalesWeeks) : 0;
        const ratio = parseFloat(sumPlan === 0 ? 0 : 100 * sumSales / sumPlan).toFixed(2);
        const dict = { avgPlan: avgPlan, avgActuals: avgActuals, ratio: ratio };
        //console.log('[FORECAST] dict:', dict);
        return dict;
    }

    // This callback function expects a set of 1 or more key-value pairs
    // structured in a Dict. It will iterate over those new values
    // and add them into / copy them over the existing filter values,
    // then update the filters state to a fresh (cloned) value of the 
    // to trigger an update of the hooks with the filters in their dependancy arrray
    const filterCallback = (newFilterValues) => {

        try {
            setDataLoading(true);
            // Merge the incoming filters into the previous filters 
            // values, either adding or overwriting.
            // In the process this creates a new dict, which triggers the
            // useEffect call in the hook that has these filters 
            // in the dependency array
            let tempFilters = { ...filters, ...newFilterValues };

            // The two are now merged and 'tempFilters' contains 
            // the up to date filter values; update the filters state
            // (this triggers useEffects ton retrieve data)
            //console.log('[FILTERS] callback, fresh filters=', tempFilters);

            // Additional manipulation needed for stylecodes / colorcodes as these are interdependent
            let styleTriggeredCallback = newFilterValues.hasOwnProperty('stylecode');
            // If a style triggered the callback, remove the season and businessline filters
            if (styleTriggeredCallback)
                tempFilters = { ...tempFilters, ...{ season: undefined, businessline: undefined } };

            if (tempFilters['stylecode'] === null || tempFilters['stylecode'] === undefined) {
                window.history.pushState(null, '', '/forecast/');
                tempFilters['colorcode'] = null;
            }
            else if (tempFilters['stylecode']) {
                
                let url = '/forecast/' + tempFilters['stylecode'];
                window.history.pushState(null, '', url);
                if (tempFilters['colorcode'] && (!styleTriggeredCallback)) {
                    url += "/" + tempFilters['colorcode']
                }
                // If the callback was triggered by a stylechange, the
                // colorcode should be reset to its basevalue
                else {
                    tempFilters['colorcode'] = null;
                }
                window.history.pushState(null, '', url);
            }
            setFilters(tempFilters);

            // Additionally add the style / color codes onto the forecastInputsFilters
            const dict = { stylecode: tempFilters.stylecode, colorcode: tempFilters.colorcode };
            //console.log('[FILTERS INPUT] setting inputfilters, merged=', { ...inputFilters, ...dict });
            setInputFilters({ ...inputFilters, ...dict })
            
            // Additional manipulation needed for weekfilters to persist to local storage
            if (newFilterValues.hasOwnProperty('weekfilter_start')) {
                setWeekfilterStart(parseInt(newFilterValues['weekfilter_start']));
            }
            if (newFilterValues.hasOwnProperty('weekfilter_end')) {
                setWeekfilterEnd(parseInt(newFilterValues['weekfilter_end']));
            }       

        } catch (error) {
            console.error(error);
        }
    }

    const clearFiltersCallback = () => {
        window.history.pushState(null, '', '/forecast/');
        // Clear all filters except weeknumbers
        setFilters(
            {
                weekfilter_start: new Date().getFullYear() + '01',
                weekfilter_end: (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()),
            });
        // These are resest and with that also persisted to localstorage
        setWeekfilterStart(new Date().getFullYear() + '01');
        setWeekfilterEnd((new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));        
        setMessageWeekGap("");
    }

    return (
        <>
            <Navbar />
            <TitleBar title="Forecast" />
            <FilterBar
                filterChoices={{
                    showStyleFilter: true,
                    showColorFilter: true,
                    showBusinesslineFilter: true,
                    showSeasonFilter: true,
                    showYearStartFilter: true,
                    showYearEndFilter: true,
                    showMarketFilter: false
                }}
                stylecode={filters.stylecode}
                stylecodeFilterCallback={filterCallback}
                colorcode={filters.colorcode}
                colorcodeFilterCallback={filterCallback}
                businesslineFilterCallback={filterCallback}
                businessline={filters.businessline}
                season={filters.season}
                seasonFilterCallback={filterCallback}                
                seedStartYear={new Date().getFullYear()}
                weekNumStart={filters.weekfilter_start}
                startWeekFilterCallback={filterCallback}
                seedEndYear={new Date().getFullYear()+1}
                weekNumEnd={filters.weekfilter_end}
                endWeekFilterCallback={filterCallback}
                clearFilters={clearFiltersCallback}
            />
            <Box p={"5px"} border="1px solid lightgrey" borderRadius={"md"} m={"15px"}>
                <Box mx={-2} my={-2}>
                    <Flex flexWrap="wrap" justifyContent="flex-start" mb={2} p={2}>

                        <Box maxW="400px" ml="5px" pt="5px" px="10px">
                            <Box color="blue.500" fontWeight={"bold"} pb="5px">Weeks for lookback</Box>
                            <Box><ForecastParamsWeekSelector filters={inputFilters} callback={setInputFilters} /></Box>
                        </Box>
                        <Box w="30px"></Box>
                        <Box ml="5px" pt="5px" px="10px">
                            <Box color="blue.500" fontWeight={"bold"} pb="5px">Last weeks' sales and actuals</Box>
                            <Flex flexWrap="wrap" justifyContent="flex-start" spacing="10px">
                                <Box>
                                    <Box fontSize="sm" fontWeight={"semibold"}>Avg units/wk - actuals last {inputFilters.lookbackavgweeks} weeks</Box>
                                    <Box mt={"10px"} mr={"10px"} mb={"15px"} fontWeight={"semibold"} color="hotpink">{forecastInputs.avgActuals} units</Box>
                                </Box>
                                <Spacer w="30px"/>
                                <Box>
                                    <Box fontSize="sm" fontWeight={"semibold"}>Avg units/wk - planned last {inputFilters.lookbackavgweeks} weeks</Box>
                                    <Box mt={"10px"} mr={"10px"} mb={"15px"} fontWeight={"semibold"} color="hotpink">{forecastInputs.avgPlan} units</Box>
                                </Box>
                                <Spacer w="30px"/>
                                <Box>
                                    <Box fontSize="sm" fontWeight={"semibold"}>Sales to plan ratio last {inputFilters.lookbackavgweeks} weeks</Box>
                                    <Box data-cy="forecast_ratiobox" mt={"10px"} mr={"10px"} mb={"10px"} fontWeight={"semibold"} color={forecastInputs.ratio < 100 ? "red.400" : "green.400"}>{Math.round(forecastInputs.ratio)}%</Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Box>
            <Box p={"5px"} border="1px solid lightgrey" borderRadius={"md"} m="15px">
                {dataLoading ?
                    <>
                    <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>

                    </>
                    :
                    <>
                        <Box color="red" pb="5px" fontSize="16px">{messageWeekGap}</Box>
                        <ForecastTable data={gridData} ref={hotRefData} />
                    </>
                }
            </Box>
            <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px" minH="300px">
                {dataLoading ?
                    <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                    :
                    <DataChart data={gridData} yLabel="Units" dataRows={[1, 2, 3]} title="Unit sales, plan and forecast" />
                }
            </Box>
            <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px" minH="300px">
                {dataLoading ?
                    <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                    :
                    <DataChart data={gridData} yLabel="Units" dataRows={[4]} title="Bulk unit positions" />
                }
            </Box>   
            <Box margin="15px">
                <Button
                    data-cy="forecast_button_link_to_stylecolorshares"
                    mr="10px"
                    mt="10px"
                    as="a"
                    href={"/stylecolorshares/" + filters.stylecode}
                    textDecoration="none"
                    colorScheme="blue"
                    variant="outline">
                    Setup Stylecolor Shares
                </Button>
                <Button
                    data-cy="forecast_button_link_to_salesplan"
                    mr="10px"
                    mt="10px"
                    as="a"
                    href={"/salesplan/" + filters.stylecode}
                    textDecoration="none"
                    colorScheme="blue"
                    variant="outline">
                    Edit salesplan
                </Button>
                <Button
                    data-cy="forecast_button_link_to_stylecolors"
                    mr="10px"
                    mt="10px"
                    as="a"
                    href={"/stylecolors/" + filters.stylecode}
                    textDecoration="none"
                    colorScheme="blue"
                    variant="outline">
                    Edit stylecolors
                </Button>
            </Box>
        </>
    );
}



