import React from 'react';
import { Box } from '@chakra-ui/react'

export default function TitleBar(parentprops) {
    return (
        <>
            <Box data-cy="titlebar" p={2} mb={2} bg="gray.500" color="white" fontFamily="Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" fontSize={400} fontWeight={'bold'} >
                {parentprops.title} {parentprops.extra}
            </Box>
        </>)
};