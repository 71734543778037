import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, List, ListItem } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import useAuthSWR from '../../hooks/useAuthSWR';

export default function ItemPicker(parentprops) {
    const { data: dropDownData, error } = useAuthSWR(parentprops.dataEndpoint);
    const [dropdDownOptions, setDropdownOptions] = useState(null);

    const [itemSelected, setItemSelected] = useState(parentprops.itemselected ? parentprops.itemselected : parentprops.defaultvalue);
    const [listVisible, setListVisible] = useState(false);
    const listRef = useRef(null);
    const buttonRef = useRef(null);
    const listContainerRef = useRef(null);

    useEffect(() => {
        //console.log('[ITEMPICKER] called')
        //console.log('[ITEMPICKER] parentprops=', parentprops)

        // NOTE: this is the setting that the filter gets if it is cleared
        // The value on initial run will be 'undefined'
        if (parentprops.itemselected === null || parentprops.itemselected === undefined)
            setItemSelected(parentprops.defaultvalue)
        else
            setItemSelected(parentprops.itemselected);

        if (dropDownData)
            setDropdownOptions(parentprops.mapperFunc(dropDownData));
        
        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('mousedown', handleClickOutsideListBox);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideListBox);
        }

    }, [dropDownData, parentprops]);

    const handleClickOutsideListBox = (event) => {
        const list = listRef.current;
        const listcontainer = listContainerRef.current;
        const button = buttonRef.current;
        if (list && button && !list.contains(event.target)
            && !listcontainer.contains(event.target) && !button.contains(event.target)) {
            setListVisible(false);
        }
    };

    const itemSelectedHandler = (event) => {
        // Note that the itemkey has been added onto the <LI> as an additional
        // property as 'data-itemkey'. REACT returns this in the 'dataset' collection
        // of the target element that triggered the event
        const itemKey = event.target.dataset.itemkey;

        // Callback the new valkue as a key-value pair,
        // as it will be consumed by in this way
        // The name of the key to callback with is passed in
        // from the parent as the 'filterKey' prop
        const dict = {};
        dict[parentprops.filterKey] = itemKey;
        parentprops.callback(dict);

        setItemSelected(itemKey);
        setListVisible(false);
    }

    // Note that the itemkey should be added onto the <LI> as an additional
    // property as 'data-itemkey'. REACT returns this in the 'dataset' collection
    // of the target element that triggered the event, so it can be used independently from
    // the displayed item name.
    const renderDropdown = () => {
        if (dropdDownOptions) {
            if (Object.keys(dropdDownOptions).length === 0)
                return (
                    <List ref={listRef}>
                        <ListItem pr={6} color="blue.500" bgColor="blue.50">No items</ListItem>
                    </List>
                    );
        }
        // Extract the names from the items JSON into an array
        // This is done by calling a mapper function spoecic to the type
        // of data being processed
        return (
            <List ref={listRef}
                    _before={{
                        content: "''",
                        zIndex: 10,
                        maxHeight: 300,
                        overflow: "auto"
                    }}
                    listStyleType="none"
                    p={1}
            >
                <ListItem
                    tabIndex={-1}
                    data-itemkey={undefined}
                    onClick={itemSelectedHandler}
                    key={-99}

                    px="7px"
                    py="2px"
                    mx="2px"                            
                    fontWeight="bold"
                    fontStyle={'italic'}
                    color={'blue.500'}
                    _hover={{
                        bgColor: "blue.500",
                        color: "white"
                    }}
                    borderRadius="md"
                    cursor="pointer"
                
                    >
                    {!parentprops.omitAllSelector && "ALL"}
                    </ListItem>
                {Object.entries(dropdDownOptions).map(([key, value]) => (
                    <ListItem
                        tabIndex={-1}
                        data-itemkey={key}
                        key={key}
                        onClick={itemSelectedHandler}
                        px="7px"
                        py="2px"
                        mx="2px"                            
                    bg={itemSelected === key ? "blue.500" : "transparent"}
                    color={itemSelected === key ? "white" : "black"}
                    _hover={{
                        bgColor: "blue.500",
                        color: "white"
                    }}
                    borderRadius="md"
                    cursor="pointer"
                
                    >
                    {value}
                    </ListItem>
                ))}
            </List>
        )
    }

    const selectedItemDisplay = () => {
        //console.log('[ITEMPICKER] selectedItemDisplay. itemSelected=', itemSelected)
        if (itemSelected === parentprops.defaultvalue)
            return itemSelected;
        if (dropdDownOptions && (Object.keys(dropdDownOptions).length  > 0) && dropdDownOptions.hasOwnProperty(itemSelected))
            return (dropdDownOptions[itemSelected]);
        else 
            return parentprops.defaultvalue;
    }

    const boxClicked = () => {
        if (listVisible)
            setListVisible(false);
        else
            setListVisible(true);
    }

    return (


        <Box
            minW="85px"
            borderRadius="md"
            zIndex={20} >
            <Button onClick={boxClicked} ref={buttonRef} tabIndex={-1}
                data-cy={"itempicker_" + parentprops.filterKey + "_selectbutton"}
                width="auto"
                minW="80px"
                py="5px"
                px="10px"
                height="38px"
                borderRadius="md"
                display="inline-block"
                colorScheme="blue"
                variant="outline"
                fontWeight="medium"

                >
                {selectedItemDisplay()}&nbsp;<ChevronDownIcon transform={listVisible ? "rotate(180deg)" : ""} transition="300ms"/>
            </Button>
            {
                listVisible ? (
                    <Box ref={listContainerRef}
                        position="absolute"                         
                        zIndex={700}
                        mt="3px"
                        p="3px"
                        width="auto"
                        border="1px solid #3182CE"
                        display="flex"
                        bgColor="blue.50"
                        borderRadius="md"
                        maxH={500}
                        overflow={'auto'}
                    >
                        {(renderDropdown())}
                    </Box>
                ) : null
            }
        </Box >

    );
}



