// 3rd party and framework
import { useState } from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { Button, Box, Flex } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'
import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { useForm, Controller } from 'react-hook-form';

// helpers
import { getISOWeekNum, getWeekNumber, subtractWeeksFromWeekNum } from '../../utils/utils';

export default function ForecastParamsWeekSelector(parentprops) {
    const { handleSubmit, control, formState: { errors } } = useForm();
    //console.log('[FORECASTPARAMWEEKSELECTOR] parentprops=', parentprops);
    const onSubmit = (data) => {

        // Extract the values from the data as the filternames that will be sent to the API
        let thisWeek = parseInt(new Date().getFullYear()) + getISOWeekNum(new Date());
        let valsToAdd = {
            weekfilter_start: subtractWeeksFromWeekNum(thisWeek, parseInt(data.lookbackavgweeks)),
            weekfilter_end: subtractWeeksFromWeekNum(thisWeek, 1),
        };
        // Merge the new param values into the existing filters
        let updatedFilters = { ...parentprops.filters, ...valsToAdd, ...data };

        // Send the new values back to the parent
        parentprops.callback(updatedFilters);
        //console.log('[FORECASTPARAMWEEKSELECTOR] new filters=', updatedFilters);
    };

    return (
        <form data-cy="form_forecastparams_weekselector" onSubmit={handleSubmit(onSubmit)}>
            <FormLabel htmlFor="lookbackavgweeks" fontSize="sm">Num weeks to average sales:</FormLabel>
            <Flex flexWrap="wrap" justifyContent="flex-start" mb={"1px"} p={"2px"}>

                <Box maxW="100px" px="4px" my="5px">
                    <FormControl isInvalid={errors.lookbackavgweeks}>
                        <Controller
                            name="lookbackavgweeks"
                            control={control}
                            defaultValue={4}
                            rules={{
                                required: 'Lookback number of weeks is required',
                            }}
                            render={({ field }) => (
                                <NumberInput size="sm" {...field} max={53} min={1} maxW="80px">
                                    <NumberInputField data-cy="form_forecastparams_weekselector_field_lookbackavgweeks" placeholder="4" textAlign="right" />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper data-cy="form_forecastparams_weekselector_button_increment" />
                                        <NumberDecrementStepper data-cy="form_forecastparams_weekselector_button_decrement" />
                                    </NumberInputStepper>
                                </NumberInput>
                            )}
                        />
                        <FormErrorMessage fontWeight="bold">
                            {errors.lookbackavgweeks && errors.lookbackavgweeks.message}
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <Box px="4px" my="5px">
                    <Button data-cy="form_forecastparams_button_update" size="sm" colorScheme="blue" variant="outline" type="submit">Update</Button>
                </Box>
            </Flex>
        </form>
    );
}

