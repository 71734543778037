import { Flex, Box } from '@chakra-ui/react'

export default function Footerbar(parentprops) {
    return (
        <>
            {/* Silly hack to prevent the footer bar covering the page content */}
            <Box flex="1" paddingBottom="20px" />
            <Box
                as="footer"
                borderRadius="3px"
                bg={process.env.REACT_APP_ENVNAME === 'DEV' ? 'red.500' : '#F5F5F5'}
                position="fixed"
                bottom={0}
                left="2px"
                width="99.8%"
                height="20px"
                borderTop="1px solid #DDDDDD"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize="10px"
                color={process.env.REACT_APP_ENVNAME === 'DEV' ? 'aliceblue' : '#555555'}

                fontFamily="Filson Pro"
            >
                Salesplanner {process.env.REACT_APP_ENVNAME} V{process.env.REACT_APP_VERSION}
            </Box>
        </>
    )
};