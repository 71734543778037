// 3rd party and framework
import React from 'react';
import { Button, Box } from '@chakra-ui/react';
import axios from 'axios';
import { useState, useEffect } from "react";

// Helpers
import { toastOnError } from "../../utils/utils";
import { getYearAndWeek } from "../../utils/utils";

// Handsontable imports and initialization
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import { textRenderer } from 'handsontable/renderers';


registerAllModules();

//export default function ForecastTable(parentprops) {
const ForecastTable = React.forwardRef((parentprops, ref) => {
    const [populatorUrl, setPopulatorUrl] = useState(process.env.REACT_APP_POPULATORBASEMODEL);
    const thisWeek = getYearAndWeek(new Date());
    const [resultText, setResultText] = useState();
    const [dataProcessing, setDataProcessing] = useState(false);
    let colIndexCurrentWeek = -1;

    useEffect(() => {

        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('mousedown', mouseClicked);

        return () => {
            document.removeEventListener('mousedown', mouseClicked);
        }

    }, []);

    const mouseClicked = (event) => {
        setResultText("");
    }      

    // Custom rendering logic using the provided arguments
    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        try {
            // Insert data-cy tag (for testing purposes) into all rows
            // Add data-cy attribute to the cell
            td.setAttribute('data-cy', `cell_${row}_${col}`);

            // Readonly cells: header row, header col, multipliers row
            if (row === 0 || col === 0 || row === 2)
                cellProperties.readOnly = true;

            // 2-decimals numeric format for rows 2 and 3
            if ((row === 1 || row === 2) && col > 0) {
                if (value) {
                    // Check if the value is numeric
                    if (!isNaN(value)) {
                        // Set the number of decimal places
                        value = Number(value).toFixed(3);
                    }
                }
            }

            // All numbers: align right
            if (row > 0 && col > 0) {
                td.style.textAlign = "right";
            }

            // First row: bold, light=yellow
            if (row === 0) {
                td.style.backgroundColor = "#f4f4c1";
                td.style.fontWeight = "bold";
                td.style.color = "darkslategray";
            }

            // Header col is lightblue
            if ((col < 1) && (row > 0)) {

                td.style.backgroundColor = "#cbe2f1";
                td.style.fontWeight = "bold";
                td.style.color = "darkslategray";
            }

            // Special formatting for the column that corresponds to this week
            if (col === colIndexCurrentWeek) {
                td.style.borderLeft = "2px solid darkslategrey";
                td.style.borderRight = "2px solid darkslategrey";
                td.style.filter = "saturate(300%)";
                td.style.fontWeight = "bold";
                td.style.color = "black";
                if (row === 0)
                    td.style.borderTop = "2px solid darkslategrey";
                if (row === instance.countRows() - 1)
                    td.style.borderBottom = "2px solid darkslategrey";
            }


            // Apply the custom rendering
            textRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

        } catch (error) {
            console.error(error);
        }
    };

    // This approach is used, as Handsontable can apply formatting at the COL level
    // where the data is structured into ROWS. As there are ~54 COLS of data, specifying each 
    // COL individually becomes messy, so the cols are dynamically generated from the data
    const generateColumns = (data) => {
        if (!data || data.length === 0) {
            return [];
        }
        // Get the first row of data
        const firstRow = data[0];

        // Loop through the cells in the first row
        // Mark the column that contains this week's data
        firstRow.forEach((cellValue, col) => {
            if (cellValue === thisWeek)
                colIndexCurrentWeek = col;
        });

        // Get the keys (column names) from the first row
        const keys = Object.keys(firstRow);

        // Generate the columns array dynamically
        const columns = keys.map((key) => {
            return {
                data: key,
                renderer: customRenderer,
            };
        });

        return columns;
    };

    const settings = (data) => {
        return {
            data: data,
            columns: generateColumns(data),
            className: 'handsonTableOverride',
            height: 180,
            fixedColumnsLeft: 1,
            rowHeaders: false,
            colHeaders: false,
            type: 'numeric',
        };
    };

    // Handle a click on the Save button
    const saveClickCallback = () => {
        let tableData = ref.current.hotInstance.getData();

        // POST the data to the API
        upLoadData(tableData);
    };


    const upLoadData = async (tableData) => {

        setDataProcessing(true);

        try {
            // Post the tabledata to the API
            let response =
                await axios
                    .post(populatorUrl, tableData)
                    .then(() => {
                        setResultText(<Box color="green" fontWeight="bold">Saved Basemodel</Box>);
                        parentprops.setTriggerUpdate(!parentprops.triggerUpdate);
                        setDataProcessing(false);
                    }
                    )
                    .catch(error => { toastOnError(error); })
           // console.log(response);
        } catch (error) {
            toastOnError(error);
            setResultText(<Box color="red" fontWeight="bold">Error saving Basemodel</Box>)
        }
    }

    return (
        <><Box data-cy="basemodel_table_container">
            <HotTable
                id="hot_table_basemodel" 
                ref={ref}
                settings={settings(parentprops.data)}
                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
            />
            <Button
                data-cy="basemodeltable_button_save"
                onClick={(...args) => saveClickCallback(...args)}
                my="5px"
                colorScheme="blue"
                variant="outline"
                isLoading={dataProcessing}
                loadingText='Saving'
            >Save</Button>
            <Box>{resultText}</Box>
        </Box>
        </>
    )
});

export default ForecastTable;