import { useEffect, useState, useRef } from "react";
import axios from 'axios';
//import { isEqual } from 'lodash';
import isDeepEqual from 'fast-deep-equal/react'
import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";
// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
setUseWhatChange(process.env.NODE_ENV === 'development');


// ARGS:
// url: string, the API endpoint URL of the data
// filters: Dict, key-value pairs with the filters to apply to the url
// acceptNullFilters: boolean, flag to determine whether an empty/null list of filters should return data
// triggerUpdate: any, parameter included in the dependency array that can be used to give a kick to trigger this hook
//                this mainly after a DELETE OR ADD of a list, where the url and filters have not changed, but teh values of the 
//                underlying data have
// singleInstanceOnly: boolean, flag to set whether a single instance of data only may be returned
//                     used in cases where an instance ID has not been passed, but other filters have and the dats is intended for a view
//                     that is specific to an instance. In that case, not data should be returned to the page  
const useDataSource = (url, filters, acceptNullFilters = true, triggerUpdate = null, singleInstanceOnly = false) => {
    const [dataOut, setDataOut] = useState(null);

    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    //useWhatChanged([url, filters, triggerUpdate]); // debugs the below useEffect    

    useEffect(() => {
        let dataIsFresh = true;

        const fetchData = async () => {
            try {
                let urlCopy = url;
                // Apply filters if provided
                if (filters) urlCopy += '?' + new URLSearchParams(filters);

                //console.log('[DATASOURCE] conditions met to fetch.');
                //console.log('[DATASOURCE] url=', urlCopy);

                const response = await axios.get(urlCopy);
                if (response && response.status === 401)
                    throw new Error('[401] A 401 error occurred while fetching the data.');

                // Prevent stale data return
                if (dataIsFresh) {
                    //console.log('fresh data from URL, setting data. URL=', url)

                    if (singleInstanceOnly && response.data.length > 1)
                        setDataOut([])
                    else
                        setDataOut(response.data);
                }
            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 401 && error.response.statusText === 'Unauthorized') {
                    // clear the console
                    //console.clear();
                    throw new Error('[401] A 401 error occurred while fetching the data.');
                }

            }
        };
        // Check that null filters have not been passed
        let pass = true;

        if (!acceptNullFilters && !filters)
            pass = false;

        // Check that not all filters passed were null
        // Note: a null is ok, as long as not all filters are null
        if (!acceptNullFilters && filters) {
            let allNull = true;
            for (const key in filters)
                if (!(filters[key] === null || filters[key] === undefined || filters[key] === "")) {
                    allNull = false;
                    break;
                }
            pass = !allNull;
        }
        if (pass) {
            //console.log('PASS');
            fetchData();
            // Set the active flag to false to prevent stale data return
            return () => { dataIsFresh = false };
        }
        else {
            // console.log('No pass');
        }

        // use Effect is used here, to trigger a re-run (re-render)
        // if the value of the filters (passed in from the parent) changes
    }, [url, filters, triggerUpdate]);
    return [dataOut, setDataOut];
}
export default useDataSource;