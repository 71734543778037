import { useEffect, useState } from "react";
import axios from "axios";
//import useDataSource from "./useDataSource";
//import useOrchestrationChild from "./useOrchestrationChild";
//import { useWhatChanged, setUseWhatChange } from "@simbathesailor/use-what-changed";
// UseWhatChanged debugger. See: https://github.com/simbathesailor/use-what-changed
// Only Once in your app you can set whether to enable hooks tracking or not.
// In CRA(create-react-app) e.g. this can be done in src/index.js
// This way the tracking will only happen in devlopment mode and will not
// happen in non-devlopment mode
//setUseWhatChange(process.env.NODE_ENV === 'development');

const useOrchestrator = (filters) => {
    //console.log('[SETTINGS] props=', props);
    const [datasets, setDatasets] = useState({});
    const [loading, setLoading] = useState(true);

    //useWhatChanged([filters]); // debugs the below useEffect    
    useEffect(() => {
        let ignore = false;
        setLoading(true);
        const endPointOne = "http://localhost:8000/totals/moat?from_postgres_view=true&lookbackavgweeks=4";
        //const endPointTwo = process.env.REACT_APP_ENDPOINTSALESPARAMS + '?' + new URLSearchParams(filters);
        //const endPointThree = process.env.REACT_APP_ENDPOINTSTYLECOLORSHARES + '?' + new URLSearchParams(filters);

        axios.all(
            [
                axios.get(endPointOne),
               // axios.get(endPointTwo),
               // axios.get(endPointThree)
            ])
            .then(axios.spread((response1) => {
                const returnedData = {};
                returnedData['set1'] = response1.data;
                //returnedData['set2'] = response2.data;
                //returnedData['set3'] = response3.data;
                if (!ignore) {
                    setDatasets(returnedData);
                setLoading(false);
                }
                //console.log('[SETTINGS] set 1=', returnedData.set1);
                //console.log('[SETTINGS] set 2=', returnedData.set2);
                //console.log('[SETTINGS] set 3=', returnedData.set3);

            }))

        // The cleanup function prevents a double request and the return of stale data
        // return () => {
        //     ignore = true;
        // };

    }, [filters]);

    return [datasets, setDatasets, loading, setLoading];
}
export default useOrchestrator;