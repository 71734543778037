import { useState, useEffect } from 'react';
import useDataSource from './useDataSource';


const useMarketCollection = (filters, triggerUpdate) => {
    const [dataParent, setDataParent] = useState([]);
    const [dataChild, setDataChild] = useDataSource(process.env.REACT_APP_ENDPOINTMARKETS, null, true, triggerUpdate);
    const [loading, setLoading] = useState(true);


    const fetchData = async () => {
        try {

            if (dataChild) {
                let gridVals = [];

                // Fill the first element of the gridVals array with a row of column headers  
                gridVals[0] = ["Shortname", "Longname"];
                // Extract the data. The below assumes the data is structred into an array of dicts:
                /* [
                        {
                            "shortname": "UK",
                            "longname": "United Kingdom",
                        },
                        ...
                */
                dataChild.forEach((item) => {
                    // The last column is filled with additional helpers:
                    // Column with a checkbox, to select the item 
                    gridVals.push([item.shortname, item.longname, false]);
                });
                // Populate the grid with data 
                setDataParent(gridVals);
                setLoading(false);

            }
        }
        catch (error) {
            console.log(error);
        }
    };
    // Using the 'Use what changed' DEBUG tool, to track parameter state in the console
    // See: https://github.com/simbathesailor/use-what-changed
    // uwc-debug
    useEffect(() => {
        fetchData();
    }, [filters, dataChild, triggerUpdate]);
    return [dataParent, setDataParent, loading, setLoading]
};

export default useMarketCollection;