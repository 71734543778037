// 3rd party and framework
import React, { Fragment, useRef, useState, useEffect } from "react";
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Button } from "@chakra-ui/react";
import { Box, Spinner } from '@chakra-ui/react'
import useOrchestrator from "../../hooks/useOrchestrator";
import Navbar from "../../components/nav/Navbar";
import TitleBar from "../../components/pageblocks/Titlebar";
import FilterBar from "../../components/filters/FilterBar";
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { useForm, Controller  } from 'react-hook-form';
import { useParams, useLocation } from "react-router-dom";


// Helpers
import { loadSearchParamsCRA } from "../../utils/domainSpecific";
import { getISOWeekNum, weeksInYear } from '../../utils/utils';
import useLocalStorage from "../../hooks/useLocalStorage";
import FetchFromMultipleEndpoints from "../../utils/dataAggregators";
import Counter from "../../components/controls/Counter";


function MyForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [submittedData, setSubmittedData] = useState(null);

    const validateFormat = (value) => {
        if (!/^(20\d{4})$/.test(value)) {
            return 'Invalid format, please enter in format: YYYYWW';
        }
        const weekNumber = value.substr(4);
        if (weekNumber < 1 || weekNumber > 53) {
            return 'Invalid week number';
        }
        return true;
    };

    const onSubmit = (data) => {
        setSubmittedData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.code} p={3} mb={4}>
                <FormLabel htmlFor="code">Code</FormLabel>
                <Input type="text" id="code" {...register('code', { validate: validateFormat })} placeholder="YYYYWW" maxW="200px" textAlign={"right"} />
                <FormErrorMessage>{errors.code && errors.code.message}</FormErrorMessage>
            </FormControl>

            <Button m={3} type="submit">Submit</Button>
            {submittedData && (
                <Box p={2}>
                    <p>Submitted Data:</p>
                    <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </Box>
            )}
        </form>
    );
}

function MyForm2() {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [submittedData, setSubmittedData] = useState(null);

    const onSubmit = (data) => {
        setSubmittedData(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.lookbackWeeksField} p={3} mb={4} position="relative">
            <FormLabel htmlFor="lookbackWeeksField">Lookback number of weeks:</FormLabel>
            <Controller
                name="lookbackWeeksField"
                control={control}
                defaultValue=""
                rules={{
                required: 'Lookback number of weeks is required',
                }}
                render={({ field }) => (
                <NumberInput {...field} max={53} min={1} maxW="200px">
                    <NumberInputField placeholder="4" textAlign="right" />
                    <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                )}
            />
            <FormErrorMessage fontWeight="bold">
                {errors.lookbackWeeksField && errors.lookbackWeeksField.message}
            </FormErrorMessage>
            </FormControl>
            
            <FormControl isInvalid={errors.numberField} p={3} mb={4} position="relative">
                <FormLabel htmlFor="numberField">Weeknumber (YYYYWW):</FormLabel>
                <Controller
                    name="numberField"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Weeknumber is required',
                        pattern: {
                            value: /^(20\d{2})(0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-3])$/,
                            message: 'Please enter a valid weeknumber in the format YYYYWW',
                        },
                    }}
                    render={({ field }) => (
                        <Input {...field} placeholder={ parseInt(new Date().getFullYear()) + getISOWeekNum(new Date())} maxW="200px" textAlign={"right"}/>
                    )}
                />
                <FormErrorMessage fontWeight="bold">
                    {errors.numberField && errors.numberField.message}
                </FormErrorMessage>
            </FormControl>
            <Button m={3} type="submit">Submit</Button>
            {submittedData && (
                <Box p={2}>
                <p>Submitted Data:</p>
                <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </Box>
            )}            
        </form>
    );
}

function ReactQueryTest() {
    const queryClient = useQueryClient();
    const { isLoading, isError, data, error, refetch } = useQuery(["repo"],
        () => axios.get("http://localhost:8000/api/stylecolorshares/").then((res) => res.data),
        { staleTime: 10 * 60 * 1000, } // 10 minutes in milliseconds 
    );

    if (isLoading) return (<Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>);

    if (error) return "An error has occurred: " + error.message;

    const handleRefetch = () => {
        console.log('[SETTINGS] repo=' + queryClient.getQueryData("repo"));
        refetch();
    }

    const handleRemoveQuery = () => {
        console.log('[SETTINGS] clear cache now');
        queryClient.removeQueries("repo");
        queryClient.invalidateQueries('repo');
        console.log('[SETTINGS] repo=' + queryClient.getQueryData("repo"));
    };

    return (
        <>
            <Button m="10px" onClick={handleRefetch}>
                Refetch data
            </Button>
            <Button m="10px" onClick={handleRemoveQuery}>
                Clear cache
            </Button>
            <Box minH="200px" maxHeight="300px" overflow="auto">
                {data.map(repo => {
                    return (
                        <Fragment key={`${repo.display_style}.${repo.display_colorcode}.${repo.week}`}>
                            <ul>
                                <li>
                                    <a
                                        href={`/stylecolorshares/${repo.display_style}`}>
                                        {`${repo.display_style}.${repo.display_colorcode}.${repo.week}.$ | share=${repo.share}% | ${repo.display_colorname}`}
                                    </a>
                                </li>
                            </ul>
                        </Fragment>
                    )
                })}
            </Box>

        </>
    );
};

function FetchEachTime() {

    const [data, setData] = useState([{ display_colorname: "click button to fetch data" }]);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get("http://localhost:8000/endpoint/stylecolorshares");
            setData(response.data);
            setLoading(false);

        } catch (error) {
            return "An error has occurred: " + error.message;
        }
    };

    return (
        <>
            <Button m="10px" onClick={fetchData}>
                Refetch
            </Button>

            {loading ?
                <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                :
                <Box minH="200px" maxHeight="300px" overflow="auto">
                    {data.map(repo => {
                        return (

                            <Fragment key={`${repo.display_style}.${repo.display_colorcode}.${repo.week}`}>
                                <ul>
                                    <li>
                                        <a
                                            href={`/stylecolorshares/${repo.display_style}`}>
                                            {`${repo.display_style}.${repo.display_colorcode}.${repo.week}.$ | share=${repo.share}% | ${repo.display_colorname}`}
                                        </a>
                                    </li>
                                </ul>
                            </Fragment>)
                    })}
                </Box>
            }
        </>
    )
};


export default function Settings() {
    // Extract search parameters from URL structure and querystring
    const paramVals = loadSearchParamsCRA(useParams(), new URLSearchParams(useLocation().search));

    const counterValueRef = useRef('');
    const [counterValue, setCounterValue] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setCounterValue(counterValueRef.current);
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);


    // The filters are stored in a custom hook, useLocalStorage, that persists the values into local storage,
    // to maintain the values across pages 
    const [weekfilterStart, setWeekfilterStart] = useLocalStorage('weekfilterStart', (new Date().getFullYear()) + '01');
    const [weekfilterEnd, setWeekfilterEnd] = useLocalStorage('weekfilterEnd', (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));

    // Filters: dict object in which the values from filterboxes are set
    // Changing these triggers state changes that make the custom hooks re-render
    // Setup the weekstart/end filters with default values
    // Set the styleCode and colorCode valuies from the url, if they were passed
    const [filters, setFilters] = useState({
        weekfilter_start: weekfilterStart,
        weekfilter_end: weekfilterEnd,
        stylecode: paramVals.stylecode,
    });

    const [dataOut, setDataOut, loading, setLoading] = useOrchestrator(filters);

    // This callback function expects a set of 1 or more key-value pairs
    // structured in a Dict. It will iterate over those new values
    // and add them into / copy them over the existing filter values,
    // then update the filters state to a fresh (cloned) value of the 
    // to trigger an update of the hooks with the filters in their dependancy arrray
    const filterCallback = (newFilterValues) => {

        try {
            // Merge the incoming filters into the previous filters 
            // values, either adding or overwriting.
            // In the process this creates a new dict, which triggers the
            // useEffect call in the hook that has these filters 
            // in the dependency array
            let tempFilters = { ...filters, ...newFilterValues };

            // The two are now merged and 'tempFilters' contains 
            // the up to date filter values; update the filters state
            // (this triggers useEffects ton retrieve data)
            //console.log('[FILTERS] callback, fresh filters=', tempFilters);

            // Additional manipulation needed for stylecodes / colorcodes as these are interdependent
            if (tempFilters['stylecode'] === null || tempFilters['stylecode'] === undefined) {
                window.history.pushState(null, '', '/settings/');
                tempFilters['colorcode'] = null;
            }
            else if (tempFilters['stylecode']) {
                let url = '/forecast/' + tempFilters['stylecode'];
                window.history.pushState(null, '', url);
            }
            setFilters(tempFilters);

            // Additional manipulation needed for weekfilters to persist to local storage
            if (newFilterValues.hasOwnProperty('weekfilter_start')) {
                setWeekfilterStart(parseInt(newFilterValues['weekfilter_start']));
            }
            if (newFilterValues.hasOwnProperty('weekfilter_end')) {
                setWeekfilterEnd(parseInt(newFilterValues['weekfilter_end']));
            }

        } catch (error) {
            console.error(error);
        }
    }

    const clearFiltersCallback = () => {
        window.history.pushState(null, '', '/forecast/');
        // Clear all filters except weeknumbers
        setFilters({ weekfilter_start: new Date().getFullYear() + '01', weekfilter_end: (new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()) });
        // These are resest and with that also persisted to localstorage
        setWeekfilterStart(new Date().getFullYear() + '01');
        setWeekfilterEnd((new Date().getFullYear()) + (weeksInYear(new Date().getFullYear()).toString()));
    }


    /*
    const codeBoxRef = useRef();
    const seasonBoxRef = useRef();

    const buttonClick = (event) => {
        let filters = {
            stylecode: codeBoxRef.current.value,
            season: seasonBoxRef.current.value
            }
            setFilters(filters);
    }
    */


    return (
        <main>
            <Navbar />
            <TitleBar title="Settings" />
            <FilterBar
                filterChoices={{
                    showStyleFilter: true,
                    showColorFilter: false,
                    showYearStartFilter: true,
                    showYearEndFilter: true,
                    showMarketFilter: false
                }}
                stylecode={filters.stylecode}
                stylecodeFilterCallback={filterCallback}
                seedStartYear={new Date().getFullYear()}
                weekNumStart={filters.weekfilter_start}
                startWeekFilterCallback={filterCallback}
                seedEndYear={new Date().getFullYear() + 1}
                weekNumEnd={filters.weekfilter_end}
                endWeekFilterCallback={filterCallback}
                clearFilters={clearFiltersCallback}
            />       
            {/*
            <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px">
                <MyForm />
            </Box>
            <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px">
                <MyForm2 />
            </Box>
            <ul>
                <li>CampaignMaxPressure</li>
                <li>DiscountMaxPressure</li>
                <li>Year window: years to show -- and ++ vs current year in the yearselector</li>
            </ul>
            <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px">

                <Box color="blue.500" mt={"5px"} ml={1} fontSize={20}>
                    Test nested hooks for data retrieval
                </Box>

                <Flex flexWrap="wrap" justifyContent="space-between" mb={2}>

                    <Box maxW="400px" p={2}>
                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                            <Box>Style code:</Box>
                            <input ref={codeBoxRef} className="px-4 py-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder={filters.stylecode} />
                        </Flex>

                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                            <Box>Season code:</Box>
                            <input ref={seasonBoxRef} className="px-4 py-2 mb-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="Enter season code" />
                        </Flex>

                        <Flex>
                            <Button onClick={buttonClick}>Retrieve data</Button>
                        </Flex>
                    </Box>

                    <Box p={3}>{loading ? <>loading...</> : listItems}</Box>

                </Flex>
            </Box>
    */}
            <Box p="2px" bg="grey.100" border="1px solid lightgrey" borderRadius="md" m="15px" >
                <Box color="blue.500" mt="5px" ml={1} fontSize={20}>
                    MOAT View Test
                </Box>
                <Box>Load complete at {counterValueRef.current}s</Box>
                {loading ?
                    <>
                        <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                        <Counter ref={counterValueRef} />
                    </>

                    :
                    <Box maxH="500px" overflow="auto">{JSON.stringify(dataOut.set1)}</Box>
                }
            </Box>
            <Box p="2px" bg="grey.100" border="1px solid lightgrey" borderRadius="md" m="15px" >
                <Box color="blue.500" mt="5px" ml="1" fontSize={20}>
                    Aggregate data test: 3 sources
                </Box>
                <FetchFromMultipleEndpoints filters={filters} />
            </Box>

            <Box p="2px" bg="grey.100" border="1px solid lightgrey" borderRadius="md" m="15px" >
                <Box color="blue.500" mt="5px" ml="1" fontSize={20}>
                    React-query fetch
                </Box>
                {/*<ReactQueryTest />*/}
            </Box>
            <Box p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px" >
                <Box color="blue.500" mt={"5px"} ml={1} fontSize={20}>
                    Classic fetch
                </Box>
                <FetchEachTime />
            </Box>

        </main>
    );
}