import { toast } from "react-toastify";

export const toastOnError = error => {
    if (error.response) {
        // known error
        toast.error(JSON.stringify(error.response.data));
    } else if (error.message) {
        toast.error(JSON.stringify(error.message));
    } else {
        toast.error(JSON.stringify(error));
    }
};

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);

export const calculateAverage = (array) => {

    let sum = 0;
    for (let i = 0; i < array.length; i++) {
        sum += array[i];
    }
    return sum / array.length
}
export const substringInString = (string, substring) => {
    
    if (!string)
        return false;
    else 
        return (string.toLowerCase().indexOf(substring.toLowerCase()) >= 0);
}

// Format date into format 'Saturday, September 17, 2016'
export function prettyDateTime(rawDate) {
    //return (rawDate);
    var dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', };
    var timeOptions = { hour: '2-digit', minute: '2-digit' };
    var theDate = new Date(rawDate);
    return (theDate.toLocaleDateString("en-NL", dateOptions) + ' at ' + theDate.toLocaleTimeString("nl-NL", timeOptions));
}

// Format date into format 'Saturday, September 17, 2016'
export function shortDate(rawDate) {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var theDate = new Date(rawDate);
    return (theDate.toLocaleDateString("en-US", options)); // Saturday, September 17, 2016
}

// This script is released to the public domain and may be used, modified and
// distributed without restrictions. Attribution not necessary but appreciated.
// Source: https://weeknumber.com/how-to/javascript

// Returns the ISO week number of the date, .
// NOTE: The week is returned as string with a leading '0' if it is a single digit week
export function getISOWeekNum(dt = new Date()) {
    var date = new Date(dt.getTime());
    date.setHours(0, 0, 0, 0);

    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);

    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);

    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    let week = (1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7)).toString();

    // Add a leading 0 to the week if it is a single digit number
    //if (week.length === 1) week = '0' + week;

    return week.padStart(2,'0');
}

// Returns the four-digit year corresponding to the ISO week of the date.
export function getWeekYear(dt = new Date()) {
    var date = new Date(dt.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

export function addDays(dt, days) {
    var date = new Date(dt);
    date.setDate(date.getDate() + days);
    return date;
}

export function getFirstMonday(dt) {
    var date = new Date(dt);

    // Decrement the date until a Monday is hit
    while (date.getDay() !== 1) {
        date.setDate(date.getDate() - 1);
    }
    return date;
}

export function getYearAndWeek(d) {
    return parseInt(getWeekNumber(d)[0] + getWeekNumber(d)[1].toString().padStart(2, '0'));
}

export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
    // Return array of year and week number
    return [d.getFullYear(), weekNo];
}
    
export function weeksInYear(year) {
    var month = 11,
        day = 31,
        week;

    // Find week that 31 Dec is in. 
    // If this is the first week, reduce the date until
    // the previous week is hit.
    do {
        let d = new Date(year, month, day--);
        week = getWeekNumber(d)[1];
    } while (week === 1);
    return parseInt(week);
}

export function dateAsYYYYMMDD(dt) {
    var d = new Date(dt),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

// Takes a full 6-digit week like 202304 and returns the next week
// Considers years-ends and 52/53 week ISO years
export function getNextWeek(week) {
    let nextWeek = parseInt(week) + 1;
    let weeknum = parseInt(nextWeek.toString().substring(4, 6));
    // Check if the end of a year is hit
    if (parseInt(weeknum) >= 52 && parseInt(weeknum) > weeksInYear(week.toString().substring(0, 4))) {
        let nextYear = parseInt(week.toString().substring(0, 4)) + 1;
        nextWeek = parseInt(nextYear + '01');
    }
    return nextWeek;
}

export function subtractWeeksFromWeekNum(weekNum, numWeeks) {
    if (!(weekNum && numWeeks))
        return;
    
    try {
        const year = weekNum.toString().substring(0, 4); // Extract the year from the weekNum
        const week = weekNum.toString().substring(4, weekNum.length); // Extract the week from the weekNum

        // Create a new Date object for the given year and set the week
        const date = new Date(year, 0, parseInt(week) * 7);
  
        // Subtract the number of weeks
        date.setDate(date.getDate() - numWeeks * 7);

        // Get the new week number
        const newWeekNum = getWeekYear(date) + getISOWeekNum(date);

        return newWeekNum;
    }
    catch (error) {
        console.error(error);
    }
}

export function calculateWeekDelta(week1, week2) {
    const year1 = Math.floor(week1 / 100);
    const year2 = Math.floor(week2 / 100);
    const weekNum1 = week1 % 100;
    const weekNum2 = week2 % 100;

    const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

    let delta = (year2 - year1) * 52;

    if (weekNum1 <= weekNum2) {
        delta += weekNum2 - weekNum1;
    } else {
        delta -= weekNum1 - weekNum2;
        if (isLeapYear(year1)) {
            delta -= 1;
        }
    }
    return delta;
}

export function getCurrentSeason() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Adding 1 since getMonth() returns 0-based index

    let seasonCode;
    if (month >= 11 || month <= 5) {
        seasonCode = 'S';
    } else {
        seasonCode = 'F';
    }

    return seasonCode + year;
}

export function styleTitleFromStyleData(styleData) {
    try {
        if (!styleData) {
            return "";
        }
        // Only return if a single style has been selected
        if (styleData !== undefined && styleData.length === 1)
            return (styleData[0].name + " (" + styleData[0].code + ")");
    }
    catch (error) {
        console.error(error);
    }
    return "";
}

export function styleIdFromStyleData(styleData) {

    try {
        if (!styleData) {
            return null;
        }
        // Only return if a single style has been selected
        if (styleData !== undefined && styleData.length === 1)
            return styleData[0].id;
    }
    catch (error) {
        console.error(error);
    }
    return 'l=' + styleData.length;
}
