// Function to map the specific datastructure of the Seasons data to output that can
// be consumed by the filter dropdown box 

/*    
    IN:
    {
        "id": 2,
        "name": "F2021",
        "startweek": 202123,
        "endweek": 202147
    },
    .....

    OUT:
    { F2021: "F2021", S2023:"S2023",F2023:"F2023"}
*/
export function seasonsMapper(data) {
    if (data) {
        const namesOnly = data.map(item => item.name);
        const keyVals = namesOnly.reduce((dict, name) => {
            dict[name] = name;
            return dict;
        }, {});
        return keyVals;
    }
}


/*    
    IN:
            {"shortname": "A", "longname": "Apparel"},
            {"shortname": "F", "longname": "Footwear"},
    .....

    OUT:
    { A: "Apparel", F:"Footwear",...}
*/
export function businesslineMapper(data) {
    if (data) {
        const keyVals = data.reduce((dict, item) => {
            dict[item.shortname] = item.longname;
            return dict;
        }, {});
        return keyVals;
    }
}

/*    
    IN:
    {
        "id": 2,
        "weeknumber": 2,
        "lookbackweeks": 2,
    },
    .....

    OUT:
    { F2021: "F2021", S2023:"S2023",F2023:"F2023"}
*/
export function lookbackavgweeksMapper(data) {
    if (data) {
        const weeks = data.map(item => item.lookbackweeks);
        const keyVals = weeks.reduce((dict, lookbackweeks) => {
            dict[lookbackweeks] = lookbackweeks;
            return dict;
        }, {});
        return keyVals;
    }
}

/*    
    IN:
    {
    {
        "shortname": "AT",
        "longname": "Austria"
    },
    {
        "shortname": "CH",
        "longname": "Switzerland"
    },
    .....

    OUT:
    { AT: "Austria", CH:"Switzerland",...}
*/
export function marketsMapper(data) {
    if (data) {
        const keyVals = data.reduce((dict, item) => {
            dict[item.shortname] = item.longname;
            return dict;
        }, {});
        return keyVals;
    }
}

/*    
    IN:
    {
        "display_stylename": "Addiction 14",
        "display_stylecode": "110317",
        "display_introseason": "S2022",
        "display_gender": "M",
        "display_stylecolor": "110317028",
        "colorname": "Blackened Pearl/Blue/Black",
        "colorcode": "028"
    },
    .....

    OUT:
    { 028: "028 - Blackened Pearl/Blue/Black",...}

*/
export function colorsMapper(data) {
    if (data) {
        const keyVals = data.reduce((dict, item) => {
            dict[item.colorcode] = item.colorcode + " - " + item.colorname;
            return dict;
        }, {});
        return keyVals;
    }
    
}