import { useEffect, useState } from "react";
import useDataSource from "./useDataSource";

const usePlanForColor = (filters) => {
    const [dataOut, setDataOut] = useState([]);
    const [loading, setLoading] = useState(true);

    // NOTE: DO NOT TOUCH THE FILTERS HERE, THAT LEADS TO INFINITE LOOPS. PASSIVELY PASS THEM THROUGH
    const [dataIn1, setDataIn1] = useDataSource(process.env.REACT_APP_TOTALSSALESPLAN, filters, false);
    const [dataIn2, setDataIn2] = useDataSource(process.env.REACT_APP_ENDPOINTSTYLECOLORSHARES, filters, false);

    useEffect(() => {
        let ignore = false;
        const fetchData = () => {

            // Comnbine the plan totals from the Salestotals and the shares into a color-specific plan
            // Create a map of week to share value from Data2
            const shareMap = new Map();
            dataIn2.forEach(obj => {
                shareMap.set(parseInt(obj.week), obj.share);
            });

            // Combine the datasets and apply the share value
            const colorPlanDict = dataIn1.map(obj => {
                const share = shareMap.get(obj.week);
                if (share !== undefined) {
                    const unitsForColor = obj.total_units * (share / 100);
                    return { ...obj, share, units_for_color: unitsForColor };
                }
                // Skip the object if no matching week found
                return null;
            }).filter(Boolean); // Filter out null entries

            // Do not return stale data 
            if (!ignore) {
                setDataOut(colorPlanDict);
                setLoading(false);
            }
        }
        if (filters.colorcode === null || filters.colorcode === undefined || (filters.colorcode && filters.colorcode === "")) {
            setDataOut([]);
            setLoading(false);
        }
        else {
            if (dataIn1 && dataIn2) {
                fetchData();
            }
        }
        // The cleanup function prevents a double request and the return of stale data
        return () => {
            ignore = true;
        };
    }, [dataIn1, dataIn2]);

    return [dataOut, setDataOut, loading, setLoading];
}
export default usePlanForColor;