// 3rd party and framework
import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Flex, Spinner, Link, Checkbox, Tooltip, HStack } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons'
import { FormControl, FormLabel, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, FormErrorMessage } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';

// Custom components
import ConfirmDeleteItemsModal from "../../components/controls/ConfirmDeleteItemsModal.jsx";

// helpers
import { substringInString } from "../../utils/utils";
import { saveSalesParameters } from "../../utils/domainSpecific";
import { buildSalesplanFromParams } from '../../utils/domainSpecific';
import { getISOWeekNum, weeksInYear } from '../../utils/utils';

// Custom hooks
import useDataSource from "../../hooks/useDataSource";


export default function SalesParamsForm(parentprops) {

    const [updateState, setUpdateState] = useState(null);
    const [salesParamsResultMessage, setSalesParamsResultMessage] = useState("");
    const [noParamsSetMessage, setNoParamsSetMessage] = useState("No sales parameters have been defined for this style. Please setup the sales parameters in the boxes below.");
    const [salesParameters, setSalesParameters] = useDataSource(process.env.REACT_APP_ENDPOINTSALESPARAMS, parentprops.filters, false, null, true);
    const [salesParametersId, setSalesParametersId] = useState(null);
    const [toggleTriggerUpdatePlanModal, setToggleTriggerUpdatePlanModal] = useState(false);
    const { handleSubmit, control, formState: { errors }, setValue, reset, register, clearErrors, getValues, resolver } = useForm();
    const buttonSaveParams = useRef();

    useEffect(() => {

        // Add listerners/handling for a click outside the dropdown to close it
        document.addEventListener('mousedown', mouseClicked);

        return () => {
            document.removeEventListener('mousedown', mouseClicked);
        }

    }, []);

    const mouseClicked = (event) => {
        //console.log('[SALESPARAMSFORM] clicked, event.target=', event.target.id);
        // Do not clear the salesParamsResultMessage if one of the buttons is clicked,
        // as this re-renders the page, takeing focus off the button and forcing a second click
        const btn1 = buttonSaveParams.current;
        if ((btn1 && !btn1.contains(event.target)) && (event.target.id !== parentprops.btnRecalculateId)) {
            setSalesParamsResultMessage("");
        }
    }

    // When a new style is selected, clear the form
    useEffect(() => {

        setNoParamsSetMessage("No sales parameters have been defined for this style. Please setup the sales parameters in the boxes below.");
        // Clear the result message box
        setSalesParamsResultMessage("");
        // Clear the form
        reset();
        // Clear any error messages
        clearErrors();

    }, [parentprops.styleCode]);

    useEffect(() => {
        ////console.log('[SALESPARAMSFORM] params change triggered load, params=', salesParameters);
        // Bind data to the form
        if (salesParameters && salesParameters[0]) {
            setValue('startweek', salesParameters[0].startweek);
            setValue('endweek', salesParameters[0].endweek);
            setValue('startlevel', salesParameters[0].startlevel);
            setValue('endlevel', salesParameters[0].endlevel);
            setValue('decayfactor', salesParameters[0].decayfactor);
            setValue('atp_default_percentage', salesParameters[0].atp_default_percentage);
            setValue('inactive_per_week', salesParameters[0].inactive_per_week);
            setValue('checkboxIncludeCPandDP', true);

            // Update parent
            parentprops.callBackSetSalesParams(salesParameters[0]);
        }
        else {
            if (salesParameters && salesParameters.length === 0) {
                // If no data provided, clear the form
                setValue('startweek', parseInt(new Date().getFullYear()) + '01');
                setValue('endweek', parseInt(new Date().getFullYear()) + weeksInYear(new Date().getFullYear()).toString());
                setValue('startlevel', 100);
                setValue('endlevel', 10);
                setValue('decayfactor', 0.95);
                setValue('atp_default_percentage', 10);
                setValue('inactive_per_week', parseInt(new Date().getFullYear()) + getISOWeekNum(new Date()));
                setValue('checkboxIncludeCPandDP', true);
            }
            // Clear the result message box
            setSalesParamsResultMessage("");

            // Update parent
            parentprops.callBackSetSalesParams(null);
        }

    }, [salesParameters]);

    const onSubmit = (data) => {
        rebuildSalesplanFromParams(true, false);
    }

    // Custom validation function
    const validateStartWeek = (startweek, formData) => {
        if (startweek >= formData.endweek) {
            return 'Start week must be less than end week';
        }
        return true;
    };

    const validateEndWeek = (endweek, formData) => {
        if (formData.startweek >= endweek) {
            return 'Endweek must be greater than start week';
        }
        return true;
    };

    const validateInactiveWeek = (inactiveweek, formData) => {
        if (inactiveweek > formData.endweek && inactiveweek < formData.startweek) {
            return 'Inactive-per-week must be smaller than end week and greater than start week.';
        }
        if (inactiveweek > formData.endweek && inactiveweek > formData.startweek) {
            return 'Inactive-per-week must be smaller than end week.';
        }
        if ((inactiveweek > formData.endweek && inactiveweek < formData.startweek) || (inactiveweek < formData.endweek && inactiveweek < formData.startweek)) {
            return 'Inactive-per-week must be greater than start week.';
        }
        return true;
    };

    // Fill the salesparams from the form fields and PUT/POST to API
    const doSaveSalesParams = async (paramData) => {

        //console.log('[SALESPARAMSFORM] Saving params, data=', paramData);

        // Extract the parametersId from the salesParameters. If it does not exist, set it to undefined
        const paramId = (salesParameters && salesParameters.length > 0 && salesParameters[0].id) ? salesParameters[0].id : undefined;

        paramData = { ...paramData, ...{ id: paramId } }

        let result = await saveSalesParameters(
            process.env.REACT_APP_ENDPOINTSALESPARAMS,
            paramData,
            setSalesParamsResultMessage,
            setSalesParameters);
        return result;
    }

    const rebuildSalesplanFromParams = async (requestConfirm, confirmed) => {
        if (requestConfirm) {
            // If the delete button was hit, without the modal having been triggered, trigger the modal
            setToggleTriggerUpdatePlanModal(true);
            return;
        }
        else if (confirmed) {

            setNoParamsSetMessage("");

            // Get the data from the form
            const formData = getValues();

            // Add the styleId to the data and persist to API
            const postData = { ...formData, ...{ style: parentprops.stylesData.id } }

            //console.log('[SALESPARAMSFORM] Submitting for save, data=', postData);

            // first update the sales params
            let result = await doSaveSalesParams(postData);

            //console.log('[SALESPARAMSFORM] Result from params save=', result);


            ////console.log('[SALESPARAMSFORM] Fresh param values:', freshParams);           
            //console.log('[SALESPARAMSFORM] And postData  we were holding on to:', postData);
            //console.log('[SALESPARAMSFORM] PASS, calling buildSalesplanFromParams to rebuild plan with data:', postData);
            // If the sales params were saved succefully, continue and rebuild the salesplan
            // Can this be rewritten? Is there a need for the weekfilter start/end here?
            if (result)
                buildSalesplanFromParams(
                    setUpdateState,
                    setSalesParamsResultMessage,
                    process.env.REACT_APP_POPULATORSALESPLANS + '?stylecode=' + parentprops.styleCode + '&includecpanddp=' + formData.checkboxIncludeCPandDP,
                    process.env.REACT_APP_ENDPOINTBASEMODELS + '?sort=week&weekfilter_start=' + postData.startweek + '&weekfilter_end=' + postData.endweek,
                    process.env.REACT_APP_ENDPOINTSALESPLANS + '?stylecode=' + parentprops.styleCode,
                    parentprops.setGrid,
                    formData.checkboxIncludeCPandDP);

            // Update parent param values
            parentprops.callBackSetSalesParams(postData);
        }

        setToggleTriggerUpdatePlanModal(false);
        return;
    }

    return (
        <>
            {toggleTriggerUpdatePlanModal ?
                (
                    <ConfirmDeleteItemsModal
                        title="Update salesplan?"
                        text="Are you sure you want to update the salesplan? All manual entries will be overwritten."
                        callback={rebuildSalesplanFromParams} />
                )
                :
                null}
            <Box maxW="820px" px={4}>
                {salesParameters === null ?
                    <Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>
                    :
                    <>
                        {!(salesParameters[0] || salesParameters.length > 1) &&
                            <Box mt={"20px"} color="red.500" fontWeight={"bold"} w={"100%"}>
                                {noParamsSetMessage}
                            </Box>}
                        <Box mt={"20px"} >
                            <form data-cy="salesplan_params_form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" key={Date.now()} >
                                <Flex flexWrap="wrap" justifyContent="space-between" mb={2}>
                                    <Box maxW="410" mr="20px">
                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" pr={10} color="darkslategrey">
                                                Start week (style intro):
                                            </FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.startweek}>
                                                    <Controller
                                                        name="startweek"
                                                        control={control}
                                                        defaultValue={salesParameters && salesParameters[0] && salesParameters[0].startweek}
                                                        rules={{
                                                            required: 'Startweek is required',
                                                            pattern: {
                                                                value: /^(20\d{2})(0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-3])$/,
                                                                message: 'Please enter a valid weeknumber in the format YYYYWW',
                                                            },
                                                            validate: validateStartWeek,
                                                        }}
                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px" >
                                                                <NumberInputField data-cy="salesplan_params_form_field_startweek" {...register('startweek')} pr="30px" placeholder={parseInt(new Date().getFullYear()) + getISOWeekNum(new Date())} textAlign="right" />
                                                                <NumberInputStepper><NumberIncrementStepper /><NumberDecrementStepper /></NumberInputStepper>
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.startweek && errors.startweek.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>

                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" color="darkslategrey">End week (style offline):</FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.endweek}>
                                                    <Controller
                                                        name="endweek"
                                                        control={control}
                                                        defaultValue={salesParameters && salesParameters[0] && salesParameters[0].endweek}
                                                        rules={{
                                                            required: 'Endweek is required',
                                                            pattern: {
                                                                value: /^(20\d{2})(0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-3])$/,
                                                                message: 'Please enter a valid weeknumber in the format YYYYWW',
                                                            },
                                                            validate: validateEndWeek,
                                                        }}
                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px">
                                                                <NumberInputField data-cy="salesplan_params_form_field_endweek" {...register('endweek')} pr="30px" placeholder={parseInt(new Date().getFullYear()) + getISOWeekNum(new Date())} textAlign="right" />
                                                                <NumberInputStepper><NumberIncrementStepper /><NumberDecrementStepper /></NumberInputStepper>
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.endweek && errors.endweek.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>

                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" color="darkslategrey">Sales start level (units/week):</FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.startlevel}>
                                                    <Controller
                                                        name="startlevel"
                                                        control={control}
                                                        defaultValue={salesParameters[0] && salesParameters[0].startlevel}
                                                        rules={{
                                                            required: 'Sales start level is required',
                                                        }}
                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px" min={0} max={100000}>
                                                                <NumberInputField data-cy="salesplan_params_form_field_startlevel_units" pr="30px" placeholder="100" textAlign="right" />
                                                                <NumberInputStepper><NumberIncrementStepper /><NumberDecrementStepper /></NumberInputStepper>
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.startlevel && errors.startlevel.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>

                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" color="darkslategrey">Sales end level (units/week):</FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.endlevel}>
                                                    <Controller
                                                        name="endlevel"
                                                        control={control}
                                                        defaultValue={salesParameters[0] && salesParameters[0].endlevel}
                                                        rules={{
                                                            required: 'Sales end level is required',
                                                        }}
                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px">
                                                                <NumberInputField data-cy="salesplan_params_form_field_endlevel_units" pr="30px" placeholder="10" textAlign="right" />
                                                                <NumberInputStepper><NumberIncrementStepper /><NumberDecrementStepper /></NumberInputStepper>
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.endlevel && errors.endlevel.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>
                                    </Box>

                                    <Box maxW="400px" minW="305px" justifyContent="flex-end">
                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" color="darkslategrey">Decay factor:</FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.decayfactor}>
                                                    <Controller
                                                        name="decayfactor"
                                                        control={control}
                                                        defaultValue={salesParameters[0] && salesParameters[0].decayfactor}
                                                        rules={{
                                                            required: 'Decay factor is required',
                                                        }}
                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px" min={0} max={1}>
                                                                <NumberInputField placeholder="0.95" textAlign="right" pr="10px" />
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.decayfactor && errors.decayfactor.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>

                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" color="darkslategrey">Go to inactive in week:</FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.inactive_per_week}>
                                                    <Controller
                                                        name="inactive_per_week"
                                                        control={control}
                                                        defaultValue={salesParameters[0] && salesParameters[0].inactive_per_week}
                                                        rules={{
                                                            required: 'Inactive per week is required',
                                                            pattern: {
                                                                value: /^(20\d{2})(0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-3])$/,
                                                                message: 'Please enter a valid weeknumber in the format YYYYWW',
                                                            },
                                                            validate: validateInactiveWeek,
                                                        }}

                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px">
                                                                <NumberInputField pr="30px" placeholder={parseInt(new Date().getFullYear()) + getISOWeekNum(new Date())} textAlign="right" />
                                                                <NumberInputStepper><NumberIncrementStepper /><NumberDecrementStepper /></NumberInputStepper>
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.inactive_per_week && errors.inactive_per_week.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>

                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <FormLabel mr="5px" color="darkslategrey">Default ATP percentage (%):</FormLabel>
                                            <Box maxW="110px">
                                                <FormControl isInvalid={errors.atp_default_percentage}>
                                                    <Controller
                                                        name="atp_default_percentage"
                                                        control={control}
                                                        defaultValue={salesParameters[0] && salesParameters[0].atp_default_percentage}
                                                        rules={{
                                                            required: 'Default ATP percentage is required',
                                                        }}
                                                        render={({ field }) => (
                                                            <NumberInput size="md" {...field} width="110px">
                                                                <NumberInputField pr="30px" placeholder="10" textAlign="right" />
                                                                <NumberInputStepper><NumberIncrementStepper /><NumberDecrementStepper /></NumberInputStepper>
                                                            </NumberInput>
                                                        )}
                                                    />
                                                    <FormErrorMessage fontWeight="bold">
                                                        {errors.atp_default_percentage && errors.atp_default_percentage.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                        </Flex>

                                        <Flex flexWrap="wrap" justifyContent="space-between" mb={2} alignItems="baseline">
                                            <Box maxW="340px" verticalAlign={"top"}>
                                                <FormControl>
                                                    <FormLabel>
                                                        <Controller
                                                            name="checkboxIncludeCPandDP"
                                                            control={control}
                                                            defaultValue={true}
                                                            render={({ field }) => (
                                                                <HStack spacing="2px">
                                                                    <Checkbox id="checkboxIncludeCPandDP"
                                                                        isChecked={field.value}
                                                                        onChange={(e) => setValue('checkboxIncludeCPandDP', e.target.checked)}>
                                                                        Include Campaign Pressure and Discount Pressure from Baseplan
                                                                    </Checkbox>
                                                                    <Tooltip ml="-15px" bg="blue.50" color="blue.500" border="1px solid hotpink" borderRadius="md" fontSize="md" fontWeight="regular" placement="top-end" hasArrow label="In the Basemodel setup, Campaign Pressure and Discount Pressure per week are defined. Check this box, to copy the values from the Baseplan into this Salesplan as defaults. In this Salesplan, You'll be able to modify the values if needed.">
                                                                        <QuestionIcon color="blue.400" mt="5px" />
                                                                    </Tooltip>
                                                                </HStack>
                                                            )}
                                                        />
                                                    </FormLabel>
                                                </FormControl>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>

                                <Box my={4}>
                                    <Button data-cy="salesparamsform_button_submit" ref={buttonSaveParams} colorScheme="blue" variant="outline" type="submit">
                                        Rebuild salesplan from parameters
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </>
                }

            </Box>
            <Box minH={"30px"}>
                <Box ml={"20px"} mb={"5px"} fontWeight={"bold"} color={substringInString(salesParamsResultMessage, "error") ? "red.500" : "green.500"}>{salesParamsResultMessage}</Box>
                <Box ml={"20px"} mb={"5px"} fontWeight={"bold"} color="red.500">{errors && errors.startweek && errors.startweek.message}</Box>
            </Box>
        </>

    );
}
