// 3rd party and framework
import React, { useState, useEffect } from 'react';
import { Spinner, Box } from '@chakra-ui/react'
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale } from 'chart.js/auto';

ChartJS.register(LinearScale);


/*  This chart takes a datagrid with an x-axis label and max 5
    y-axis piecesof data:
    ['X-AXIS-DATA-LABEL', 2,3,6,3,7,9]
    ['Y-AXIS-DATA-LABEL 1', 2,3,6,3,7,9]
    ['Y-AXIS-DATA-LABEL 2', 2,3,6,3,7,9]
    ['Y-AXIS-DATA-LABEL 3', 2,3,6,3,7,9]
    ['Y-AXIS-DATA-LABEL 4', 2,3,6,3,7,9]
    ['Y-AXIS-DATA-LABEL 5', 2,3,6,3,7,9]
    
    Called with props:
    data: the grid of data, in the structures above 
    dataRows: an array of indexes for the rows in the dataset that will be plotted
    yLabel: label for the verical axis
    title: title for the chart
*/
export default function DataChart(parentprops) {
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (parentprops.data && parentprops.data.length > 0) {
            //console.log('[DATACHART] parentprops.data=', parentprops.data);
            populateChart(parentprops.data.map(innerArray => [...innerArray]), parentprops.dataRows);
        }
    }, [parentprops.data]);

    const generateColors = (numRows) => {
        const colors = [];

        const baseColors = [
            'rgba(121, 134, 214, 1)',   // Soft blue
            'rgba(183, 214, 189, 1)',   // Soft green
            'rgba(237, 186, 214, 1)',   // Soft pink
            'rgba(251, 191, 150, 1)',   // Soft orange
            'rgba(196, 173, 214, 1)'    // Soft purple
        ];

        const numBaseColors = baseColors.length;

        for (let i = 0; i < numRows; i++) {
            const baseColorIndex = i % numBaseColors;
            colors.push(baseColors[baseColorIndex]);
        }

        return colors;
    }

    const populateChart = (data, valueRows) => {
        ////console.log('[DATACHART] data=', data);

        const numRows = data.length - 1;
        const colors = generateColors(numRows);
        const chartLines = [];
        for (let j = 0; j < valueRows.length; j++) {
            for (let i = 0; i < numRows; i++) {
                if ((i + 1) === valueRows[j]) {
                    // Assuming data starts on row 2, col 2 
                    const values = data[i + 1].splice(1);
                    const lineConfig = {
                        label: data[i + 1][0],
                        data: [...values],
                        backgroundColor: [colors[i]],
                        borderColor: [colors[i]],
                        borderWidth: 3,
                        hoverBackgroundColor: 'rgba(200, 200, 200, 1)',
                        hoverBorderColor: 'rgba(200, 200, 200, 1)',
                    };
                    chartLines.push(lineConfig);
                }
            }
        }
        const chartData = {
            labels: [...data[0].splice(1)],
            datasets: chartLines
        };

        const chartOptions = {
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: data[0][0],
                        font: {
                            size: 14,
                        },
                    },
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: parentprops.yLabel,
                        font: {
                            size: 14,
                        },
                    },
                    beginAtZero: true,
                },
            },
        };
        setChartData(chartData);
        setChartOptions(chartOptions);
        setLoading(false);
    }

    return (
        <>
            <Box data-cy="datachart_titlebox" color="blue.500" m={"20px"} fontSize={20} >{parentprops.title}</Box>
            <Box data-cy="datachart" p={"2px"} bg="grey.100" border="1px solid lightgrey" borderRadius={"md"} m="15px">
                {loading ?
                    (<Box w={"100%"}><Spinner mt={50} mb={50} ml={"30vw"} mr={"30vw"} thickness='5px' speed='0.99s' emptyColor='gray.200' color='blue.500' size='xl' /></Box>)
                    :
                    (<Line data={chartData} options={chartOptions} height={200} width={1000} />)
                }
            </Box>
        </>);
}


